import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fileService from "./fileService";
import support_response from "../../support/support_response";

export const fileUpload = createAsyncThunk(
  "files/upload/:model_id",
  async (args, thunkAPI) => {
    if (args.actions !== undefined) {
      await args.actions();
    }
    try {
      return await fileService.fileUpload(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const fileDelete = createAsyncThunk(
  "files/:model_id/delete",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }

      return await fileService.fileDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const fileUpdate = createAsyncThunk(
  "files/:model_id/update",
  async (args, thunkAPI) => {
    try {
      return await fileService.fileUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const fileShow = createAsyncThunk(
  "files/:model_id/show",
  async (args, thunkAPI) => {
    try {
      return await fileService.fileShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const fileUpdateParams = createAsyncThunk(
  "files:/update-params",
  async (args, thunkAPI) => {
    try {
      return await fileService.fileUpdateParams(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  file: {},
  data: {},
  model: {},
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    updateParamsStore: (state, action) => {
      const { data, isSave } = action.payload;
      state.file = data;
      state.dirty = isSave;
    },
  },
  extraReducers: {
    [fileUpload.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [fileUpload.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.model = payload.data.model;
      // state.farm = payload.data.farm;
      state.loading = false;
      state.isSuccess = true;
    },
    [fileUpload.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },

    [fileDelete.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [fileDelete.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.model = payload.data.model;
      // state.farm      = payload.data.farm;
      state.loading = false;
      state.isSuccess = true;
    },
    [fileDelete.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },

    [fileUpdate.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
      state.updating = true;
    },
    [fileUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.file = payload.data.file;
      state.loading = false;
      state.isSuccess = true;
      state.updating = false;
    },
    [fileUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
      state.updating = false;
    },

    [fileShow.pending]: (state, { payload }) => {
      state.data = {};
      state.file = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [fileShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.file = payload.data.file;
      state.loading = false;
      state.isSuccess = true;
    },
    [fileShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [fileUpdateParams.pending]: (state, { payload }) => {
      state.data = {};
      state.file = {};
      state.updateParams = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [fileUpdateParams.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.file = payload.data.file;
      state.loading = false;
      state.isSuccess = true;
      // state.updateParams.data = payload.data;
      state.dirty = false;
    },
    [fileUpdateParams.rejected]: (state, { payload }) => {
      state.data = payload;
      // state.updateParams.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateParamsStore } = fileSlice.actions;

export default fileSlice;
