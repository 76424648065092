import React from "react";

const CommonButtonPrimary = ({
  label,
  onclick,
  id,
  type,
  disabled,
  classname,
  style,
}) => {
  id = `btn-${
    id?.toLowerCase().replaceAll(" ", "-") ||
    label.toLowerCase().replaceAll(" ", "-")
  }`;
  return (
    <button
      disabled={disabled ? disabled : false}
      style={style}
      type={type || "button"}
      className={`btn btn-primary btn-block p-0 ${
        classname !== undefined ? classname : ""
      }`}
      onClick={onclick}
      id={id}
    >
      {label}
    </button>
  );
};
export default CommonButtonPrimary;
