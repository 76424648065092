import api from "../../support/support_api";
import storage from "../../support/support_storage";
const completeMark = async (params) => {
  const response = await api.axiosPost(
    `/completes/mark/${params.model_id}/machine/${parseInt(params.machine_id)}`,
    params
  );

  return response.data;
};

const completeCheck = async (params) => {
  let config = await storage.getConfig({ showError: false });

  const response = await api.axiosPost(
    `/completes/check/${params.machine_id}`,
    params,
    config
  );

  return response.data;
};

const completeService = {
  completeMark,
  completeCheck,
};

export default completeService;
