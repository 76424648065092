import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import service from "./iotService";
import support_response from "../../support/support_response";

export const iotCustomers = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotCustomers(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const iotUserMachines = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotUserMachines(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const iotMachineShow = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotMachineShow(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const iotUpdateMachineCurrentOperation = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotUpdateMachineCurrentOperation(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const iotUpdateMachineCurrentSetting = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotUpdateMachineCurrentSetting(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)
export const iotUpdateMachineCurrentSettingIot = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotUpdateMachineCurrentSettingIot(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const iotMachineGraphShow = createAsyncThunk(
    "iot/index",
    async (args, thunkAPI) => {
        try {
            return await service.iotMachineGraphShow(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

const initialState = {
    data: {
    },
    users:{
    },
    user:{
    },
    last_update:null,
    tab:'total-machine',
    machine:{
    },
    machines:{
        data:[
        ]
    },
    graph:{
        details: [
        ]
    },
    order: {
    },
    contact: {},
    loading: false,
    isSuccess: false,
    updating: false,
}

export const iotSlice = createSlice({
    name: 'iot',
    initialState,
    reducers: {


        updateOperationIotMachine(state, action) {
            // console.log("action ", action);
            // // note: deep nested update works in reactjs toolki!!! it takes me several hours to figure out this!
            // const {machineIndex, machine} = action.payload;
            //
            // state.machines.data[machineIndex].machine.operation.op
            // state.machines.data[machineIndex].machine.operation.machine_name
            // state.machines.data[machineIndex].machine.operation.process_name
            // state.machines.data[machineIndex].machine.operation.process_number



        },



    },
    extraReducers: {
        [iotMachineShow.pending]: (state, {payload}) => {
            // state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [iotMachineShow.fulfilled]: (state, {payload}) => {
            state.data = payload;
            state.updating = false;

            if(payload?.data?.users) {
                state.users = payload.data.users;
            }

            if(payload?.data?.user) {
                state.user = payload.data.user;
            }

            if(payload?.data?.machine) {
                state.machine = payload.data.machine;
            }

            if(payload?.data?.last_update) {
                state.last_update = payload.data.last_update;
            }

            if(payload?.data?.machines) {
                state.machines = payload.data.machines;
            }

            if(payload?.data?.graph) {
                state.graph = payload.data.graph;
            }
            if(payload?.data?.tab) {
                state.tab = payload.data.tab;
            }

            state.loading   = false;
            state.isSuccess = true;
        },
        [iotMachineShow.rejected]: (state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }

})

export const {
    //

} = iotSlice.actions

export default iotSlice;