import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import authService from "./registerService";
import secureLocalStorage from "react-secure-storage";
export const registerCustomer = createAsyncThunk(
  "auth/users",
  async (getItem, thunkAPI) => {
    try {
      return await authService.registerCustomer(getItem);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// export  const  registerDetail = createAsyncThunk(
//     "auth/users/register-detail",
//     async (user, thunkAPI) => {
//         try {
//             return await registerService.registerDetail(user);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
//
// export const registerType = createAsyncThunk(
//     "users/register-type",
//     async (roles, thunkAPI) => {
//
//         try {
//             return await registerService.registerType(roles);
//         } catch (error) {
//
//             console.log(error);
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
//
// export const registerCompany = createAsyncThunk(
//     "users/register-company",
//     async (company, thunkAPI) => {
//         try {
//             return await registerService.registerCompany(company);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )
const initialState = {
  data: {},
  loading: false,
  isSuccess: false,
  isRegistered: false,
  user: {},
  // test:'aaa',
  authenticated: false,
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    //
  },
  extraReducers: {
    [registerCustomer.pending]: (state, {}) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [registerCustomer.fulfilled]: (state, { payload }) => {
      state.isRegistered = true;
      const auth = {
        data: payload.data,
      };
      secureLocalStorage.setItem("user", JSON.stringify(auth));
      state.data = payload.data.user;
      state.loading = false;
      state.isSuccess = true;
    },
    [registerCustomer.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export default registerSlice;
