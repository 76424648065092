import { BrowserRouter } from "react-router-dom";
import Snackbar from "components/ui/snackbar/Snackbar";
import AppRoutes from "routes/AppRoutes";
import AlertContainer from "components/ui/AlertContainer";
import LoadingIndicator from "components/spinner/LoadingIndicator";
import axios from "axios";
import support_storage from "support/support_storage";
import { useDispatch, useSelector } from "react-redux";
import { showAlertToast } from "features/alert/toastSlice";

function App() {
  const reduxMachine = useSelector((state) => state.machine);
  const reduxFile = useSelector((state) => state.file);
  const dispatch = useDispatch();
  axios.interceptors.response.use(
    (response) => {
      // const show =
      //   response.status === 200 &&
      //   response.config.method !== 'get' &&
      //   response.config.method !== 'post';

      // if (show === true) {
      //   dispatch(
      //     showAlertToast({
      //       status: 'success',
      //       message: response.data.message,
      //     })
      //   );
      // }
      return response;
    },
    (error) => {
      if (error) {
        if (error?.response?.status === 401) {
          support_storage.setUserClear();
          window.location = "/";
        } else if (error?.response?.status === 403) {
          //  window.location = "/forbidden";
        } else if (error?.response?.status === 422) {
          // BAD ERROR REQUEST
          if (error.config.showError === false) {
            // do nothing
          } else {
            dispatch(
              showAlertToast({
                status: "error",
                message: error.response.data.message,
              })
            );
          }
        } else if (error?.response?.status === 500) {
          // dispatch(
          //   showAlertToast({
          //     status: "error",
          //     message: "Internal Server error", //error.response
          //     hideLoadingIndicator: true,
          //   })
          // );
        } else {
          // dispatch(
          //   showAlertToast({
          //     status: "error",
          //     message: "Something went wrong",
          //     hideLoadingIndicator: true,
          //   })
          // );
        }
      }

      throw error;
    }
  );
  return (
    <div>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
      <Snackbar />
      <AlertContainer />
      {(reduxMachine.loading || reduxFile.loading) && <LoadingIndicator />}
    </div>
  );
}

export default App;
