import api from "../../support/support_api";

const contactStore = async (params) => {
  const response = await api.axiosPost("/contacts", params);

  return response.data;
};

const contactSupplier = async (params) => {
  const response = await api.axiosPost("/contacts/supplier", params);

  return response.data;
};


const catalogService = {
  contactStore,
  contactSupplier,
};

export default catalogService;
