import { store } from "app/store";
import React, { useState, useCallback } from "react";
import CommonSpinner from "./CommonSpinner";
const LoadingIndicator = () => {
  const [showLoading, setShowLoading] = useState(false);
  // const handleStoreChange = useCallback(() => {
  // const states = store.getState();

  // const keys = Object.keys(store.getState());
  // let show = false;
  // let forceHide = false;

  //   keys?.forEach((key) => {
  //     if (states.hasOwnProperty(key)) {
  //       if (states[key]?.loading === true) {
  //         show = true;
  //       }
  //       if (states[key]?.hideLoadingIndicator === true) {
  //         forceHide = true;
  //       }
  //     }
  //   });
  //   if (forceHide) {
  //     setShowLoading(true);
  //   } else {
  //     setShowLoading(show);
  //   }
  // }, [store.getState()]);

  // store.subscribe(handleStoreChange);
  return <CommonSpinner />;
  // return <>{showLoading && <CommonSpinner />}</>;
};

export default LoadingIndicator;
