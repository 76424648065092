import { useEffect, useRef, useState, lazy } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { routesPaths } from "constants/routes.constants";
import { get_role_name } from "../support/support_url";
import HomePage from "pages/home/HomePage";
import BlankPage from "pages/BlankPage";
import DashboardContainer from "pages/dashboard/DashboardContainer";
import WebsiteContainer from "pages/WebsiteContainer";
import Leasing from "pages/leasing/Leasing";
import FormPreviewWrapper from "pages/preview/FormPreviewWrapper";
import LoginPage from "pages/auth/LoginPage";
import CustomerLoginPage from "pages/auth/CustomerLoginPage";
import NewUserRegistration from "pages/auth/NewUserRegistration";
import SupplierLogin from "pages/auth/SupplierLogin";
import ForgotPasswordSupplier from "pages/auth/ForgotPasswordSupplier";
import ForgotPasswordCustomer from "pages/auth/ForgotPasswordCustomer";
import NoAccess from "pages/account/NoAccess";

const MachineIndex = lazy(() => import("components/machine/MachineIndex"));
const DashboardMain = lazy(() => import("pages/dashboard/DashboardMain"));
const RoleChange = lazy(() => import("components/role/RoleChange"));
const MachineCreate = lazy(() => import("components/machine/MachineCreate"));
const MachineEdit = lazy(() => import("components/machine/MachineEdit"));

const MachineDetails = lazy(() =>
  import("pages/machine-details/MachineDetails")
);

const ShoppingCartOverlay = lazy(() =>
  import("pages/shopping-cart/ShoppingCartOverlay")
);
const RegisterOverlay = lazy(() => import("pages/register/RegisterOverlay"));

const ContainerEvaluationProcess = lazy(() =>
  import("pages/containers/ContainerEvaluationProcess")
);
const ContainerEvaluationCheck = lazy(() =>
  import("pages/containers/ContainerEvaluationCheck")
);
const ContainerRefurbishment = lazy(() =>
  import("pages/containers/ContainerRefurbishment")
);
const ContainerAssignment = lazy(() =>
  import("pages/containers/ContainerAssignment")
);
const ContainerCommissionerMain = lazy(() =>
  import("pages/containers/ContainerCommissionerMain")
);
const SupplierLandingPage = lazy(() =>
  import("components/landing/SupplierLandingPage")
);
const ContactUs = lazy(() => import("components/website/landing/ContactUs"));
const HowItWorksPage = lazy(() =>
  import("components/website/landing/HowItWorksPage")
);
const ProductDetails = lazy(() =>
  import("pages/browse/catalogue/ProductDetails")
);
const Enquiries = lazy(() => import("pages/enquiries/Enquiries"));
const ContainerAddToCatalogue = lazy(() =>
  import("pages/containers/ContainerAddToCatalogue")
);
const ContainerUserProfile = lazy(() =>
  import("pages/containers/ContainerUserProfile")
);
const ContainerPreDeliveryInspection = lazy(() =>
  import("pages/containers/ContainerPreDeliveryInspection")
);
const ContainerHandover = lazy(() =>
  import("pages/containers/ContainerHandover")
);
const ContainerMachineCommission = lazy(() =>
  import("pages/containers/ContainerMachineCommission")
);
const ContainerJobOrderBooking = lazy(() =>
  import("pages/containers/ContainerJobOrderBooking")
);
// const LeasingDetails = lazy(() => import("pages/leasing/LeasingDetails"));

const LeasingMachineDetails = lazy(() =>
  import("pages/leasing/LeasingMachineDetails")
);

const LeasingDetail = lazy(() => import("pages/leasing/LeasingDetail"));

const LeasingReview = lazy(() => import("pages/leasing/LeasingReview"));
const LeasingCheckout = lazy(() => import("pages/leasing/LeasingCheckout"));
const NotificationsOverlay = lazy(() =>
  import("pages/notification/NotificationsOverlay")
);
const TasksOverlay = lazy(() => import("pages/tasks-screen/TasksOverlay"));

//Admin
const AdminRoles = lazy(() => import("../pages/admin/roles/AdminRoles"));
const AdminRolesManage = lazy(() =>
  import("../pages/admin/roles/AdminRolesManage")
);
const AdminUsers = lazy(() => import("pages/admin/users/AdminUsers"));
const AdminUsersManage = lazy(() =>
  import("pages/admin/users/AdminUsersManage")
);
const AdminPermissions = lazy(() =>
  import("pages/admin/permissions/AdminPermissions")
);
const AdminPermissionsManage = lazy(() =>
  import("pages/admin/permissions/AdminPermissionsManage")
);
const AdminModules = lazy(() => import("pages/admin/modules/AdminModules"));
const AdminBookingParameters = lazy(() =>
  import("pages/admin/modules/bookingParameters/AdminBookingParameters")
);
const AdminNotifications = lazy(() =>
  import("pages/admin/notifications/AdminNotifications")
);
const AdminSubscriptionList = lazy(() =>
  import("pages/admin/modules/subscription/AdminSubscriptionList")
);
const AdminAddonsManage = lazy(() =>
  import("pages/admin/modules/subscription/AdminAddonsManage")
);
const AdminSubscriptionManage = lazy(() =>
  import("pages/admin/modules/subscription/AdminSubscriptionManage")
);
const AdminPromotionsList = lazy(() =>
  import("pages/admin/modules/promotions/AdminPromotionsList")
);
const AdminPromotionsManage = lazy(() =>
  import("pages/admin/modules/promotions/AdminPromotionsManage")
);
const AdminForms = lazy(() => import("pages/admin/forms/AdminForms"));
const AdminFormsManage = lazy(() =>
  import("pages/admin/forms/AdminFormsManage")
);
//SuperAdmin
const SuperAdminOrganizationList = lazy(() =>
  import("pages/super-admin/organizations/SuperAdminOrganizationList")
);
const SuperAdminOrganizationManage = lazy(() =>
  import("pages/super-admin/organizations/SuperAdminOrganizationManage")
);
const SuperAdminSecurity = lazy(() =>
  import("pages/super-admin/security/SuperAdminSecurity")
);
const SuperAdminWebsite = lazy(() =>
  import("pages/super-admin/website/SuperAdminWebsite")
);

const IotDashboard = lazy(() => import("pages/iot-dashboard/IotDashboard"));
const MachinesOverview = lazy(() =>
  import("pages/iot-dashboard/MachinesOverview")
);
const IotMachineChart = lazy(() =>
  import("pages/iot-dashboard/IotMachineChart")
);
const IotMachineSettings = lazy(() =>
  import("pages/iot-dashboard/IotMachineSettings")
);
const BrowseLayout = lazy(() => import("pages/browse/BrowseLayout"));
const ContainerHealthSetup = lazy(() =>
  import("pages/containers/ContainerHealthSetup")
);

const ActivityListPreview = lazy(() =>
  import("components/forms/activity-list/ActivityListPreview")
);
const BallscrewSupportPreview = lazy(() =>
  import("components/forms/ballscrew-support/BallscrewSupportPreview")
);
const IssueFinderGuidePreview = lazy(() =>
  import("components/forms/issue-finder/IssueFinderGuidePreview")
);
const ToolListPreview = lazy(() =>
  import("components/forms/tool-list/ToolListPreview")
);
const VisualCheckPreview = lazy(() =>
  import("components/forms/visual-check/VisualCheckPreview")
);
const StaticAccuracyCheckSheetPreview = lazy(() =>
  import("components/forms/static-accuracy/StaticAccuracyCheckSheetPreview")
);
const DailyActivityPreview = lazy(() =>
  import("components/forms/daily-activity/DailyActivityPreview")
);
const CustomerSurveyPreview = lazy(() =>
  import("components/forms/customer-survey/CustomerSurveyPreview")
);
const TrainingAttendanceListPreview = lazy(() =>
  import("components/forms/machine-training/TrainingAttendanceListPreview")
);
const MachineHealthSetuPreview = lazy(() =>
  import("components/forms/machine-health-setup/MachineHealthSetuPreview")
);
const PdiChecklistPreview = lazy(() =>
  import("components/forms/pdi/PdiChecklistPreview")
);
const UpgradeActivityListPreview = lazy(() =>
  import("components/forms/activity-list/UpgradeActivityListPreview")
);
const ChecklistIPreview = lazy(() =>
  import("components/forms/checklist-1/ChecklistIPreview")
);
const ChecklistIIPreview = lazy(() =>
  import("components/forms/checklist-2/ChecklistIIPreview")
);
const CostBenefitAnalysisPreview = lazy(() =>
  import("components/forms/cost-benefit/CostBenefitAnalysisPreview")
);
const AddAfterPhotoPreview = lazy(() =>
  import("components/forms/add-after-photo/AddAfterPhotoPreview")
);
const SendEnquiry = lazy(() => import("pages/shopping-cart/SendEnquiry"));
const ContactSendEnquiry = lazy(() =>
  import("components/website/landing/ContactSendEnquiry")
);

const NotificationSetting = lazy(() =>
  import("pages/notification/NotificationSetting")
);

const SubscriptionManagment = lazy(() =>
  import("pages/subscription-management/SubscriptionManagement")
);

const AppRoutes = () => {
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();
  const prevPath = useRef();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  useEffect(() => {
    prevPath.current = currentPath;
  }, [currentPath]);

  return (
    <>
      <Routes>
        {/* Admin routes*/}
        <Route exact path="admin" element={<DashboardContainer />}>
          <Route
            exact
            path=""
            element={<Navigate to="/admin/users" replace />}
          />

          <Route path="users">
            <Route path="" element={<AdminUsers />} />
            <Route path="add" element={<AdminUsersManage add={true} />} />
            <Route path="edit/:id" element={<AdminUsersManage add={false} />} />
          </Route>

          <Route exact path="roles">
            <Route path="" element={<AdminRoles />} />
            <Route path="add" element={<AdminRolesManage add={true} />} />
            <Route path="edit/:id" element={<AdminRolesManage add={false} />} />
          </Route>
          <Route exact path="permissions">
            <Route path="" element={<AdminPermissions />} />
            <Route path="add" element={<AdminPermissionsManage add={true} />} />
            <Route
              path="edit/:id"
              element={<AdminPermissionsManage add={false} />}
            />
          </Route>
          <Route path="notifications" element={<AdminNotifications />} />

          <Route exact path="modules">
            <Route path="" element={<AdminModules />} />
            <Route path="booking" element={<AdminBookingParameters />} />
            <Route exact path="subscription">
              <Route path="" element={<AdminSubscriptionList />} />
              <Route
                path="add"
                element={<AdminSubscriptionManage add={true} />}
              />
              <Route
                path="edit/:id"
                element={<AdminSubscriptionManage add={false} />}
              />
            </Route>
            <Route exact path="promotion">
              <Route path="" element={<AdminPromotionsList />} />
              <Route
                path="add"
                element={<AdminPromotionsManage add={true} />}
              />
              <Route
                path="edit/:id"
                element={<AdminPromotionsManage add={false} />}
              />
            </Route>
            <Route
              path="addon/edit/:id"
              element={<AdminAddonsManage add={false} />}
            />
            <Route
              path="addon/add"
              element={<AdminAddonsManage add={true} />}
            />
          </Route>
          <Route exact path="forms">
            <Route path="" element={<AdminForms />} />
            <Route path="add" element={<AdminFormsManage add={true} />} />
            <Route path="edit/:id" element={<AdminFormsManage add={false} />} />
          </Route>
        </Route>
        {/* SuperAdmin routes*/}
        <Route exact path="superadmin" element={<DashboardContainer />}>
          <Route
            exact
            path=""
            element={<Navigate to="/superadmin/users" replace />}
          />

          <Route path="users">
            <Route path="" element={<AdminUsers />} />
            <Route path="add" element={<AdminUsersManage add={true} />} />
            <Route path="edit/:id" element={<AdminUsersManage add={false} />} />
          </Route>
          <Route path="organizations">
            <Route path="" element={<SuperAdminOrganizationList />} />
            <Route
              path="add"
              element={<SuperAdminOrganizationManage add={true} />}
            />
            <Route
              path="edit/:id"
              element={<SuperAdminOrganizationManage add={false} />}
            />
          </Route>
          <Route path="security" element={<SuperAdminSecurity />} />
          <Route path="website" element={<SuperAdminWebsite />} />
        </Route>
        <Route path="*" element={<WebsiteContainer />} />

        <Route path="/" element={<WebsiteContainer />}>
          <Route exact path="*" element={<BlankPage />} />
          <Route exact path="/" element={<HomePage />} />
          <Route path="/forbidden" element={<NoAccess />} />
          <Route path="/email/:action/:id/:hash" element={<HomePage />} />
          <Route exact path="/suppliers" element={<SupplierLandingPage />} />
          <Route exact path="/how-it-works" element={<HowItWorksPage />} />
          <Route
            exact
            path="/contact-us"
            element={<ContactUs prevPath={prevPath.current} />}
          />
          <Route
            exact
            path="/contact-us/send-enquiry"
            element={<ContactSendEnquiry />}
          />
          <Route exact path="/login-selection" element={<LoginPage />} />

          {/*<Route path="/products" element={<ProductsCatalogue />} />*/}
          <Route path="/products" element={<BrowseLayout />} />
          <Route
            path="/products/machines/:machineId/products-detail"
            element={<ProductDetails />}
          />
          <Route
            path="/products/machines/:machineId/leasing-details"
            element={<Leasing />}
          />

          <Route path="/shopping-cart" element={<ShoppingCartOverlay />} />
          <Route path="/shopping-cart/send-enquiry" element={<SendEnquiry />} />
          <Route
            path="/customer/shopping-cart"
            element={<ShoppingCartOverlay />}
          />

          <Route path={``} element={<Leasing />}>
            <Route
              path={`enquiries/:orderId/leasing/leasing-machine-details`}
              element={<LeasingMachineDetails />}
            />
            {/*/!*<Route path="leasing-application" element = {<LeasingDetail/>}/>*!/*/}
            <Route
              path={`enquiries/:orderId/leasing/leasing-review`}
              element={<LeasingReview />}
            />
            <Route
              path={`enquiries/:orderId/leasing/leasing-checkout`}
              element={<LeasingCheckout />}
            />
            <Route
              path={`enquiries/:orderId/leasing/leasing-details`}
              element={<LeasingDetail />}
            />
          </Route>
        </Route>
        <Route exact path={get_role_name()} element={<DashboardContainer />}>
          <Route path="" element={<DashboardMain />} />

          <Route
            path={`${get_role_name()}/roles/change`}
            element={<RoleChange />}
          />
          <Route
            //path={`${get_role_name()}/settings/:tabName/:id`}
            path={`${get_role_name()}/settings/users/:id/:tabName`}
            element={<ContainerUserProfile />}
          />
          <Route
            path={`${get_role_name()}/notifications/settings/:id`}
            element={<NotificationSetting />}
          />
          <Route
            path={`${get_role_name()}/enquiries/*`}
            element={<Enquiries />}
          />
          <Route
            path={`${get_role_name()}/notifications`}
            element={<NotificationsOverlay />}
          />
          <Route path={`${get_role_name()}/tasks`} element={<TasksOverlay />} />
          <Route
            path={`${get_role_name()}/iot-dashboard`}
            element={<IotDashboard />}
          />
          <Route
            path={`${get_role_name()}/machines-overview/user/:customerId`}
            element={<MachinesOverview />}
          />
          <Route
            path={`${get_role_name()}/machines-overview/user/:customerId/machine-chart/:machineId`}
            element={<IotMachineChart />}
          />
          <Route
            path={`${get_role_name()}/machines-overview/user/:customerId/machine-settings/:machineId`}
            element={<IotMachineSettings />}
          />
          <Route
            exact
            path={`${get_role_name()}/machines/*`}
            element={<MachineIndex />}
          />
          <Route
            exact
            path={`${get_role_name()}/machines/:selectedTabItem`}
            element={<MachineIndex />}
          />
          <Route
            path={`${get_role_name()}/iot-dashboard`}
            element={<IotDashboard />}
          />
          <Route
            path={`${get_role_name()}/machines-overview/user/:customerId`}
            element={<MachinesOverview />}
          />
          <Route
            path={`${get_role_name()}/finance/:customerId/*`}
            element={<SubscriptionManagment />}
          />
          <Route
            path={`${get_role_name()}/machines-overview/user/:customerId/machine-chart/:machineId`}
            element={<IotMachineChart />}
          />
          <Route
            path={`${get_role_name()}/machines-overview/user/:customerId/machine-settings/:machineId`}
            element={<IotMachineSettings />}
          />
          <Route
            exact
            path={get_role_name() + routesPaths.MACHINE_INDEX + "/*"}
            element={<MachineIndex />}
          />
          <Route
            exact
            path={get_role_name() + routesPaths.MACHINE_CREATE}
            element={<MachineCreate />}
          />

          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/edit`}
            element={<MachineEdit />}
          />

          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/assignments/:id/*`}
            element={<ContainerAssignment />}
          />

          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/evaluation/*`}
            element={<ContainerEvaluationProcess />}
          />
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/evaluation-overview/*`}
            element={<ContainerEvaluationCheck />}
          />
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/refurbishment/*`}
            element={<ContainerRefurbishment />}
          />
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/commissioning/*`}
            element={<ContainerCommissionerMain />}
          />
          {/* http://localhost:3000/operator/machine/0/product-provenance/0/add-machine-to-catalogue/add-machine-to-catalogue */}
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/add-machine-to-catalogue/*`}
            element={<ContainerAddToCatalogue />}
          />
          {/* http://localhost:3000/operator/machine/0/pre-delivery-inspection */}
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/pre-delivery-inspection`}
            element={<ContainerPreDeliveryInspection />}
          />
          {/* http://localhost:3000/operator/machine/0/hand-over */}
          <Route
            path={`${get_role_name()}/machines/:machineId/:hand-over/:id/*`}
            element={<ContainerHandover />}
          />
          {/* http://localhost:3000/operator/machine/0/static-accuracies/0/machine-commission/main-forms */}
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/machine-commission/*`}
            element={<ContainerMachineCommission />}
          />
          {/* http://localhost:3000/operator/machine/0/job-order-booking/0/ */}
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/assignments/:id/customer-booking`}
            element={<ContainerJobOrderBooking />}
          />
          {/*<Route exact path={`${get_role_name()}/machines/:machineId/job-order-booking/:id/*`} element={<ContainerJobOrderBooking />} />*/}
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/assignments/:id/*`}
            element={<ContainerAssignment />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:selectedTabItem/:id/evaluation/*`}
            element={<ContainerEvaluationProcess />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:selectedTabItem/:id/evaluation-overview/*`}
            element={<ContainerEvaluationCheck />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:selectedTabItem/:id/refurbishment/*`}
            element={<ContainerRefurbishment />}
          />

          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:selectedTabItem/:id/commissioning/*`}
            element={<ContainerCommissionerMain />}
          />

          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:selectedTabItem/:id/add-machine-to-catalogue/*`}
            element={<ContainerAddToCatalogue />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:pre-delivery-inspection`}
            element={<ContainerPreDeliveryInspection />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:hand-over`}
            element={<ContainerHandover />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/:selectedTabItem/:id/machine-commission/*`}
            element={<ContainerMachineCommission />}
          />
          <Route
            exact
            path={`${get_role_name()}/machine/:machineId/job-order-booking/:id/*`}
            element={<ContainerJobOrderBooking />}
          />
          {/* http://localhost:3000/refurbisher/machines/0/machine-health-setup/0/health-setup */}
          <Route
            exact
            path={`${get_role_name()}/machines/:machineId/:selectedTabItem/:id/:health-setup`}
            element={<ContainerHealthSetup />}
          />
          <Route
            path={`${get_role_name()}/website/:selectedTabItem`}
            element={<SuperAdminWebsite />}
          />
        </Route>
        <Route exact path="/login-selection" element={<LoginPage />} />
        <Route exact path="/login-customer" element={<CustomerLoginPage />} />
        <Route exact path="/login-supplier" element={<SupplierLogin />} />
        <Route exact path="/register" element={<NewUserRegistration />} />

        <Route
          path="/reset-password-customer/*"
          element={<ForgotPasswordCustomer />}
        />
        <Route
          path="/reset-password-customer/:username"
          element={<ForgotPasswordCustomer />}
        />
        <Route
          path="/reset-password-supplier/*"
          element={<ForgotPasswordSupplier />}
        />
        <Route
          path="/reset-password-supplier/:username"
          element={<ForgotPasswordSupplier />}
        />
        <Route
          path="password/supplier/:action"
          element={<ForgotPasswordSupplier />}
        />
        <Route
          path="password/customer/:action"
          element={<ForgotPasswordCustomer />}
        />
        {/* <Route
          path="/reset-password-supplier/:username"
          element={<ForgotPasswordSupplier />}
        /> */}

        <Route exact path="/machine-details/">
          <Route exact path=":machine_id" element={<MachineDetails />} />
        </Route>
        <Route exact path="register" element={<RegisterOverlay />} />
        <Route
          path="preview/machine"
          //   /:machineId/:process/:form
          //   path={`${get_role_name()}/machine/:machineId/job-order-booking/:id/*`}
          element={<FormPreviewWrapper />}
        >
          <Route
            path=":machineId/:process/activity-list/:id"
            element={<ActivityListPreview />}
          />
          <Route
            path=":machineId/:process/ballscrew-support-bearing/:id"
            element={<BallscrewSupportPreview />}
          />
          <Route
            path=":machineId/:process/issue-finder-guide/:id"
            element={<IssueFinderGuidePreview />}
          />
          <Route
            path=":machineId/:process/tool-list/:id"
            element={<ToolListPreview />}
          />
          <Route
            path=":machineId/:process/visual-check-sheet/:id"
            element={<VisualCheckPreview />}
          />
          <Route
            path=":machineId/:process/upgrade-visual-check-sheet/:id"
            element={<VisualCheckPreview />}
          />
          <Route
            path=":machineId/:process/static-accuracies/:id"
            element={<StaticAccuracyCheckSheetPreview />}
          />
          <Route
            path=":machineId/:process/upgrade-static-accuracy/:id"
            element={<StaticAccuracyCheckSheetPreview />}
          />

          <Route
            path=":machineId/:process/daily-activities-report/:id"
            element={<DailyActivityPreview />}
          />
          <Route
            path=":machineId/:process/customer-site-survey/:id"
            element={<CustomerSurveyPreview />}
          />
          <Route
            path=":machineId/:process/checklist-i/:id"
            element={<ChecklistIPreview />}
          />
          <Route
            path=":machineId/:process/checklist-ii/:id"
            element={<ChecklistIIPreview />}
          />
          <Route
            path=":machineId/:process/upgrade-activity-list/:id"
            element={<UpgradeActivityListPreview />}
          />
          <Route
            path=":machineId/:process/health-setup/:id"
            element={<MachineHealthSetuPreview />}
          />

          <Route
            path=":machineId/:process/training-attendance-list/:id"
            element={<TrainingAttendanceListPreview />}
          />
          <Route
            path=":machineId/:process/pre-delivery-inspection/:id"
            element={<PdiChecklistPreview />}
          />
          <Route
            path=":machineId/:process/upgrade-activity-list/:id"
            element={<UpgradeActivityListPreview />}
          />
          <Route
            path=":machineId/:process/add-after-photo/:id"
            element={<AddAfterPhotoPreview />}
          />
          <Route
            path=":machineId/:process/cost-benefit-analyis/:id"
            element={<CostBenefitAnalysisPreview />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
