import { React, useEffect } from "react";
import {
  Link,
  //useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Icon from "@mdi/react";
import {
  mdiAccountMultiple, //users
  mdiDomain, // organization,
  mdiSecurity, //security,
  mdiMonitorDashboard, //website
  mdiNaturePeople, // change role
  mdiLogoutVariant, //logout
} from "@mdi/js";

import CommonSidebar from "components/commons/SideBar/CommonSidebar";
import { getCurrentUserInfo } from "../../../../features/auth/authSlice";

import { logout } from "../../../../features/auth/logoutSlice";
import { routesPaths } from "constants/routes.constants";
import { get_role_name } from "../../../../support/support_url";
import { showAlertToast } from "features/alert/toastSlice";

const SuperAdminMenu = () => {
  const dispatch = useDispatch();
  //const navigate = useNavigate();
  const location = useLocation();
  const roleName = get_role_name();

  const links = [
    { title: "Users", path: `${roleName}/users` },
    {
      title: "Organizations",
      path: `${roleName}/organizations`,
    },
    { title: "Security", path: `${roleName}/security` },
    {
      title: "Website",
      path: `${roleName}/website/website-content-manager`,
    },
    {
      icon: mdiNaturePeople,
      title: "Change Role",
      path: `${roleName}${routesPaths.ROLE_CHANGE}`,
      separator: true,
    },
  ];

  const logoutHandler = (event) => {
    dispatch(logout()).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        dispatch(
          showAlertToast({
            status: "error",
            message: response.payload,
          })
        );
      } else {
        window.location = "/login-supplier";
      }
    });

    // event.preventDefault();
  };

  const { currentUser, currentRole } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCurrentUserInfo());
  }, []);

  return (
    <CommonSidebar>
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-3">
        <div className="symbol symbol-50px">
          <img src={require("assets/img/user/Ellipse 1.png")} alt="ellipse" />
        </div>

        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          <div className="d-flex">
            <div className="flex-grow-1 me-2">
              <span className="fs-6 fw-bold user-name">{currentUser.name}</span>
              <br />
              <span className="user-role">{currentRole.name_display}</span>
              <br />
              <small>{currentUser?.organization?.name_limit}</small>
            </div>
          </div>
        </div>
      </div>

      <div className="ec-navigation" data-simplebar>
        <ul className="nav sidebar-inner" id="sidebar-menu">
          {links.map((link, index) => {
            return (
              <li
                className={location.pathname === link.path ? `active` : ""}
                key={`nav-menu-${index}`}
              >
                {link.separator ? <hr className={"border"} /> : null}
                <Link
                  id={`link-${link.title}`}
                  className="sidenav-item-link"
                  to={link.path}
                >
                  {link.icon ? (
                    <Icon
                      path={link.icon}
                      size={1}
                      color={
                        location.pathname === link.path ? `#fff` : `#C2CFE0`
                      }
                    />
                  ) : (
                    <div
                      className={`side-menu-icon ${link.title.toLowerCase()}-icon${
                        location.pathname === link.path ? "-s" : ""
                      }`}
                    />
                  )}

                  <span className="nav-text">{link.title}</span>
                </Link>
              </li>
            );
          })}

          <li style={{ marginLeft: "25px", marginTop: "10px" }}>
            <button
              id="logout-btn"
              className="sidenav-item-link btn-logout"
              onClick={() => logoutHandler()}
            >
              <div className="side-menu-icon logout-icon-s" />
              <span className="nav-text">Log Out</span>
            </button>
          </li>
        </ul>
      </div>
    </CommonSidebar>
  );
};

export default SuperAdminMenu;
