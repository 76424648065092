import api from "../../support/support_api";

const checkUpdate = async (params) => {
  // let id = 0;
  //
  // if(params.check_id) {
  //     id =
  // }
  //
  // if(params.check_id) {
  //
  // }

  const response = await api.axiosPatch(`/checks/${params.id}`, params);

  return response.data;
};

const checkService = {
  checkUpdate,
};

export default checkService;
