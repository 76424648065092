const resources = {
    en: {
        translation: {
            // 'home': 'Home',
            // 'next': 'Next',
            // 'or': 'or',
            // 'farmersProduce': 'Farmers Produce',
            // 'marketDemand': 'Market Demand',
            // 'login': 'Login',
            // 'register': 'Register',
            // 'myAccount': 'My Account',
            // 'registration.accountRegistration': 'Account Registration',
            // 'registration.registerUsingEmail': 'Register using email',
            // 'registration.usingGoogle': 'Register using Google',
            // 'registration.usingFacebook': 'Register using Facebook',
            // 'registration.passwordMustBe': 'Must be at least 6 characters in length or more',
            // 'login.signIn': 'Sign In',
            // 'login.rememberMe': 'Remember Me',
            // 'login.forgotPassword': 'Forgot Password',
            // 'login.signInWithGoogle': 'Sign in with Google',
            // 'login.signInWithFacebook': 'Sign in with Facebook',
        }
    },
    my: {
        translation: {
            // 'home': 'Home',
            // 'next': 'Next',
            // 'or': 'or',
            // 'farmersProduce': 'Farmers Produce',
            // 'marketDemand': 'Market Demand',
            // 'login': 'Login',
            // 'register': 'Register',
            // 'myAccount': 'My Account',
            // 'registration.accountRegistration': 'Account Registration',
            // 'registration.registerUsingEmail': 'Register using email',
            // 'registration.usingGoogle': 'Register using Google',
            // 'registration.usingFacebook': 'Register using Facebook',
            // 'registration.passwordMustBe': 'Must be at least 6 characters in length or more',
            // 'login.signIn': 'Sign In',
            // 'login.rememberMe': 'Remember Me',
            // 'login.forgotPassword': 'Forgot Password',
            // 'login.signInWithGoogle': 'Sign in with Google',
            // 'login.signInWithFacebook': 'Sign in with Facebook',
        }
    }
};

export default resources
