import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "",
  title: "",
  message: "",
  show: false,
  hideLoadingIndicator: false,
};

export const toastSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlertToast: (state, action) => {
      state = initialState;
      let message = action.payload.message;
      if (action.payload.message?.message) {
        message = action.payload.message?.message;
      }

      return {
        ...state,
        message: message,
        status: action.payload.status,
        hideLoadingIndicator: action.payload?.hideLoadingIndicator || false,
        show: true,
      };
    },
    hideAlertToast: (state, action) => {
      state = initialState;
      return {
        ...state,
        message: "",
        status: "",
        show: false,
        hideLoadingIndicator: false,
      };
    },
  },
});

export const { showAlertToast, hideAlertToast } = toastSlice.actions;
export default toastSlice;
