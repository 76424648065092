import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import support_response from "../../support/support_response";
import planService from "./planService";

export const subscriptionPlanIndex = createAsyncThunk(
  "plans/index",
  async (args, thunkAPI) => {
    try {
      return await planService.subscriptionPlanIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const subscriptionPlanUpdateParams = createAsyncThunk(
  "plans/index",
  async (args, thunkAPI) => {
    try {
      return await planService.subscriptionPlanUpdateParams(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  plans: {
    data: [],
  },
  plan: {},

  errors: {},

  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const planSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    reduxSetErrors(state, action) {
      state.errors = action.payload.errors;
    },
  },
  extraReducers: {
    [subscriptionPlanIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [subscriptionPlanIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.plan) {
        state.plans = payload.data.plan;
      }

      if (payload.data?.plans) {
        state.plans = payload.data.plans;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [subscriptionPlanIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [subscriptionPlanUpdateParams.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.dirty = true;
    },
    [subscriptionPlanUpdateParams.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.plan) {
        state.plans = payload.data.plan;
      }

      if (payload.data?.plans) {
        state.plans = payload.data.plans;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [subscriptionPlanUpdateParams.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
  },
});

export const { reduxSetErrors } = planSlice.actions;

export default planSlice;
