import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import support_response from "../../../support/support_response";
import machineService from "./costBenefitService";

export const costBenefitUpdate = createAsyncThunk(
  "costBenefitShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.costBenefitUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const costBenefitShow = createAsyncThunk(
  "costBenefitShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.costBenefitShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const costBenefitUpdatePurchase = createAsyncThunk(
  "costBenefitShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.costBenefitUpdatePurchase(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  cost_benefit: {},
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const costBenefitAnalysis = createSlice({
  name: "costBenefitAnalysis",
  initialState,
  reducers: {
    updateCostBenefit(state, action) {
      const { key, value } = action.payload;
      state.cost_benefit[key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [costBenefitUpdate.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [costBenefitUpdate.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.cost_benefit = payload.data.cost_benefit;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [costBenefitUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [costBenefitShow.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.cost_benefit = {};
    },
    [costBenefitShow.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.cost_benefit = payload.data.cost_benefit;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [costBenefitShow.rejected]: (state, { payload }) => {
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateCostBenefit } = costBenefitAnalysis.actions;

export default costBenefitAnalysis;
