import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import support_response from "../../../support/support_response";
import addonService from './addonService';


export const adminAddonIndex = createAsyncThunk(
    "admin/addons/index",
    async (args, thunkAPI) => {
        try {
            return await addonService.adminAddonIndex(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminAddonStore = createAsyncThunk(
    "admin/addons/index",
    async (args, thunkAPI) => {
        try {
            return await addonService.adminAddonStore(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminAddonShow = createAsyncThunk(
    "admin/addons/index",
    async (args, thunkAPI) => {
        try {
            return await addonService.adminAddonShow(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminAddonUpdate = createAsyncThunk(
    "admin/addons/index",
    async (args, thunkAPI) => {
        try {
            return await addonService.adminAddonUpdate(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminAddonDelete = createAsyncThunk(
    "admin/addons/index",
    async (args, thunkAPI) => {
        try {
            return await addonService.adminAddonDelete(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)


const initialState = {
    data: {},
    addons: {
        data: []
    },
    addon: {},


    errors: {},

    loading: false,
    isSuccess: false,
    updating: false,
}

export const adminAddonSlice = createSlice({
    name: 'adminAddon',
    initialState,
    reducers: {

    },
    extraReducers: {
        [adminAddonIndex.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [adminAddonIndex.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;

            if(payload.data?.addons) {
                state.addons = payload.data.addons;
            }

            if(payload.data?.addon) {
                state.addon = payload.data.addon;
            }

            state.loading = false;
            state.isSuccess = true;
        },
        [adminAddonIndex.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const {
    //
} = adminAddonSlice.actions

export default adminAddonSlice;
