import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import secureLocalStorage from "react-secure-storage";
export const getAuth = createAsyncThunk(
  "auth/users",
  async (getItem, thunkAPI) => {
    try {
      return await authService.auth(getItem);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authUpdate = createAsyncThunk(
  "auth/users/update",
  async (user, thunkAPI) => {
    try {
      return await authService.authUpdate(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authUpdateCompany = createAsyncThunk(
  "auth/users/company/update",
  async (company, thunkAPI) => {
    try {
      return await authService.authUpdateCompany(company);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authUpdateCompanyPhoto = createAsyncThunk(
  "auth/users/company/update-photo",
  async (company, thunkAPI) => {
    try {
      return await authService.authUpdateCompanyPhoto(company);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authUpdatePhoto = createAsyncThunk(
  "auth/users/update-photo",
  async (params, thunkAPI) => {
    try {
      return await authService.authUpdatePhoto(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authRoleActiveChange = createAsyncThunk(
  "auth/users/update",
  async (params, thunkAPI) => {
    try {
      return await authService.authRoleActiveChange(params);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  // isLoggedIn:true,
  data: {},
  loading: false,
  isSuccess: false,
  currentUser: {},
  isLoggedIn: null,
  currentCompany: {},
  currentRoles: [],
  currentPermissions: [],
  currentRole: {},
  currentRoleActive: {},
  _rememberMe: {
    username: "",
    password: "",
  },
  token: "",
  updating: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // isLoggedIn: (state) => {
    //
    //     let user = JSON.parse(secureLocalStorage.getItem("user"));
    //
    //     // console.log(" get information from auth slice ", user);
    //     if(user === null) {
    //
    //         return false;
    //         //console.log(" user is null");
    //         // state.token = '';
    //         // state.currentUser = {};
    //         // state.currentCompany = {};
    //         // state.currentRoles = [];
    //         // state.isLoggedIn = false;
    //     }
    //     else {
    //         // console.log(" user is not null");
    //
    //         // state.token = user.data.token;
    //         // state.currentUser = user.data.user;
    //         // state.currentCompany = user.data.company;
    //         // state.currentRoles = user.data.roles;
    //         // state.isLoggedIn = true;
    //     }
    //
    //
    //     // let user = JSON.parse(secureLocalStorage.getItem("user"));
    //     //
    //     // user.data.user = newUser.payload;
    //     //
    //     // state.currentUser = user.data.user;
    //     //
    //     // state.token = user.data.token;
    //
    //
    //     // secureLocalStorage.setItem("user", JSON.stringify(user));
    // },
    setCurrentUser: (state, newUser) => {
      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.user = newUser.payload;

      state.currentUser = user.data.user;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },
    setCurrentCompany: (state, newCompany) => {
      // state.currentCompany = newCompany.payload;

      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.company = newCompany.payload;

      state.currentCompany = user.data.company;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },
    setCurrentPhoto: (state, newCompany) => {
      // state.currentCompany = newCompany.payload;

      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.user.photo = newCompany.payload;

      state.currentCompany = user.data.user;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },
    getCurrentUserInfo: (state) => {
      let user = JSON.parse(secureLocalStorage.getItem("user"));
      if (user === null) {
        state.token = "";
        state.currentUser = {};
        state.currentCompany = {};
        state.currentRoles = [];
        state.currentPermissions = user?.data?.user?.role_permissions;
        state.currentRole = {};
        state.currentRoleActive = {};
        state.email_verified_at = null;
        state.isLoggedIn = false;
      } else {
        state.token = user.data.token;
        state.currentUser = user.data.user;
        // state.currentCompany = user.data.company;
        state.currentRoles = user.data.user.roles;
        state.currentPermissions = user.data?.user?.role_permissions;
        state.currentRole = user.data.user.role_active;
        state.currentRoleActive = user.data.user.role_active;
        state.email_verified_at = user.data.user.email_verified_at;
        state.isLoggedIn = true;
      }
    },

    setAuthRememberMe: (state, credential) => {
      secureLocalStorage.setItem("_rememberMe", JSON.stringify(credential));
    },

    getAuthRememberMe: (state) => {
      let _rememberMe = JSON.parse(secureLocalStorage.getItem("_rememberMe"));

      if (_rememberMe) {
        state._rememberMe = _rememberMe.payload;
      }
    },
  },
  extraReducers: {
    [getAuth.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [getAuth.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [getAuth.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [authUpdate.pending]: (state, { payload }) => {
      state.updating = true;
    },
    [authUpdate.fulfilled]: (state, { payload }) => {
      state.updating = false;

      state.currentUser = payload.data.user;
      state.currentUser = payload.data.user;

      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.user = payload.data.user;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },

    [authUpdate.rejected]: (state, { payload }) => {
      state.updating = false;
    },

    [authUpdateCompany.pending]: (state, { payload }) => {
      state.updating = true;
    },
    [authUpdateCompany.fulfilled]: (state, { payload }) => {
      state.updating = false;

      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.company = payload.data.company;

      state.currentCompany = payload.data.company;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },
    [authUpdateCompany.rejected]: (state, { payload }) => {
      state.updating = false;
    },

    [authUpdatePhoto.pending]: (state, { payload }) => {
      state.updating = true;
    },
    [authUpdatePhoto.fulfilled]: (state, { payload }) => {
      state.updating = false;

      state.currentUser = payload.data.user;

      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.user = payload.data.user;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },
    [authUpdatePhoto.rejected]: (state, { payload }) => {
      state.updating = false;
    },

    [authUpdateCompanyPhoto.pending]: (state, { payload }) => {
      state.updating = true;
    },
    [authUpdateCompanyPhoto.fulfilled]: (state, { payload }) => {
      state.updating = false;

      let user = JSON.parse(secureLocalStorage.getItem("user"));

      user.data.company = payload.data.company;

      state.currentCompany = payload.data.company;

      secureLocalStorage.setItem("user", JSON.stringify(user));
    },
    [authUpdateCompanyPhoto.rejected]: (state, { payload }) => {
      state.updating = false;
    },
  },
});

export const {
  getCurrentUserInfo,
  setCurrentUser,
  setCurrentCompany,
  setCurrentPhoto,
  setAuthRememberMe,
  getAuthRememberMe,
} = authSlice.actions;

export default authSlice;
