import api from "../../support/support_api";

const notificationIndex = async (params) => {
    const response  = await  api.axiosGet('/notifications', params);

    return response.data;
}
const notificationStared = async (params) => {
    const response  = await  api.axiosPatch(`/notifications/${params.notification_id}/stared`, params);

    return response.data;
}
const notificationDestroy = async (params) => {
    const response  = await  api.axiosDelete(`/notifications/${params.notification_id}`, params);

    return response.data;
}
const notificationDestroyAllByReceiver = async (params) => {
    const response  = await  api.axiosDelete(`/notifications/destroy-all-by-receiver`, params);

    return response.data;
}
const notificationStore = async (params) => {
    const response  = await  api.axiosPost(`/notifications`, params);

    return response.data;
}
const catalogService = {
    notificationIndex,
    notificationStared,
    notificationDestroy,
    notificationDestroyAllByReceiver,
    notificationStore,
}

export default catalogService;