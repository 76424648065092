import axios from "axios";
import storage from "./support_storage";
// let paramDevice= `?device=web`;
// let paramDevice= `?device=mobile`;
let paramDevice = `?device=web&file_blob=no`;

const axiosPost = async (URL, data, p_config) => {
  let config;
  if (p_config) {
    config = p_config;
  } else {
    config = await storage.getConfig();
  }
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_API}${URL}${paramDevice}`,
    data,
    config
  );
};

const axiosPatch = async (URL, data) => {
  let config = await storage.getConfig();

  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_API}${URL}${paramDevice}`,
    data,
    config
  );
};

const axiosGet = async (URL, params, enableParam = true) => {
  let config = await storage.getConfig();
  let newUrl = "";

  // let query = window.location.search.substring(1);
  // let qs = parse_query_string(query);
  //
  // return qs;
  // window.location.search = ?name=sai

  // intended for machine passport only
  const queryParameters = new URLSearchParams(window.location.search);
  const source = queryParameters.get("source");
  const machineProcess = queryParameters.get("process");
  const form = queryParameters.get("form");
  const id = queryParameters.get("id");

  if (source) {
    params.source = source;
  }
  if (machineProcess) {
    params.process = machineProcess;
  }
  if (form) {
    params.form = form;
  }

  if (params) {
    params.file_blob = "no";
    params.device = "web";
  }

  if(id) {
    params.id = id;
  }

  //console.log(" params ", params)

  // intended for machine passport only

  // ?sources=rooms-passport

  // if(enableParam) {
  newUrl = `${process.env.REACT_APP_BACKEND_API}${URL}?${new URLSearchParams(
    params
  ).toString()}`;
  // }
  // else {
  //      newUrl = `${process.env.REACT_APP_BACKEND_API}${URL}`;
  // }

  return await axios.get(newUrl, config);
};

const axiosShow = async (URL, params) => {
  let config = await storage.getConfig();

  if (params) {
    params.file_blob = "no";
    params.device = "web";
  }

  let newUrl = `${
    process.env.REACT_APP_BACKEND_API
  }${URL}?${new URLSearchParams(params).toString()}`;

  return await axios.get(newUrl, config);
};

const axiosPut = async (URL, data) => {
  let config = await storage.getConfig();

  return await axios.put(
    `${process.env.REACT_APP_BACKEND_API}${URL}${paramDevice}`,
    data,
    config
  );
};

const axiosDelete = async (URL) => {
  let config = await storage.getConfig();

  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_API}${URL}${paramDevice}`,
    config
  );
};

const support_api = {
  axiosPost,
  axiosPatch,
  axiosGet,
  axiosShow,
  axiosPut,
  axiosDelete,
};

export default support_api;
