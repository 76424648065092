import api from "../../support/support_api";

const activityUpgradeShow = async (params) => {
    const response = await api.axiosGet(`/activity-upgrades/${params.activity_upgrade_id}`, params);

    return response.data;
}

const activityUpgradeUpdateDetails = async (params) => {
    const response = await api.axiosPatch(`/activity-upgrades/${params.activity_upgrade_id}/update-details`, params);

    return response.data;
}

const activityUpgradeService = {
    activityUpgradeShow,
    activityUpgradeUpdateDetails,
}

export default activityUpgradeService;