import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "axios";
// import { AxiosResponse } from "axios";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
// import {greet} from "support/api";

import support_response from "../../support/support_response";

import machineService from "./deliveryInspectionService";

// details
export const deliveryInspectionShow = createAsyncThunk(
  "deliveryInspectionShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.deliveryInspectionShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const deliveryInspectionAddDetail = createAsyncThunk(
  "deliveryInspectionAddDetail",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.deliveryInspectionAddDetail(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const deliveryInspectionUpdateDetail = createAsyncThunk(
  "deliveryInspectionUpdateDetail",
  async (args, thunkAPI) => {
    try {
      return await machineService.deliveryInspectionUpdateDetail(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const deliveryInspectionAddDetailDetail = createAsyncThunk(
  "deliveryInspectionAddDetailDetail",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.deliveryInspectionAddDetailDetail(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const deliveryInspectionUpdateDetailDetail = createAsyncThunk(
  "deliveryInspectionUpdateDetailDetail",
  async (args, thunkAPI) => {
    try {
      return await machineService.deliveryInspectionUpdateDetailDetail(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const deliveryInspectionUpdateParams = createAsyncThunk(
  "deliveryInspectionUpdateParams",
  async (args, thunkAPI) => {
    try {
      return await machineService.deliveryInspectionUpdateParams(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},

  delivery_inspections: {
    data: [],
  },

  delivery_inspection: {},

  errors: {},

  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const deliveryInspectionSlice = createSlice({
  name: "deliveryInspection",
  initialState,
  reducers: {
    updateDetailDeliveryInspection(state, action) {
      const { categoryIndex, detailIndex, key, value } = action.payload;
      state.delivery_inspection.categories[categoryIndex].details[detailIndex][
        key
      ] = value;
      state.dirty = true;
    },

    updateDetailDetailDeliveryInspection(state, action) {
      const { key, value, categoryIndex, detailIndex, detailSubIndex } =
        action.payload;
      state.delivery_inspection.categories[categoryIndex].details[
        detailIndex
      ].details[detailSubIndex][key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [deliveryInspectionShow.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [deliveryInspectionShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.delivery_inspection) {
        state.delivery_inspection = {};

        state.delivery_inspection = payload.data.delivery_inspection;
      }

      if (payload.data?.delivery_inspections) {
        state.delivery_inspections = {
          data: [],
        };

        state.delivery_inspections = payload.data.delivery_inspections;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [deliveryInspectionShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [deliveryInspectionUpdateParams.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [deliveryInspectionUpdateParams.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.delivery_inspection) {
        state.delivery_inspection = {};

        state.delivery_inspection = payload.data.delivery_inspection;
      }

      if (payload.data?.delivery_inspections) {
        state.delivery_inspections = {
          data: [],
        };

        state.delivery_inspections = payload.data.delivery_inspections;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [deliveryInspectionUpdateParams.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  updateDetailDeliveryInspection,
  updateDetailDetailDeliveryInspection,
} = deliveryInspectionSlice.actions;

export default deliveryInspectionSlice;
