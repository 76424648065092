// TODO - This might need to be refactor because the id's aren't dynamic
// https://www.albertgao.xyz/2019/09/07/reuse-react-router-routes-constants-with-hooks-when-navigating/
const staticPaths = {
  // MACHINE_INDEX : 'machines',

  // OPERATOR
  MACHINE_INDEX: "/machines/new",
  MACHINE_INDEX_NEW: "/machines/new",
  MACHINE_INDEX_INPROGRESS: "/machines/in-progress",
  MACHINE_INDEX_ACTIVE: "/machines/active",
  MACHINE_INDEX_DECLINE: "/machines/decline",
  MACHINE_INDEX_NOTIFICATION: "/machines/notification",
  MACHINE_CREATE: "/machines/create",
  MACHINE_CREATE_COMPANY: "/machines/create/company",
  MACHINE_CREATE_DETAILS: "/machines/create/details",
  // MACHINE_EDIT: '/machines/:machineId/edit',

  // LINE MANAGER
  LINE_MANAGER: "/line-manager",
  LINE_MANAGER_MACHINE_INDEX: "/line-manager/machines",
  LINE_MANAGER_MACHINE_CREATE: "/line-manager/machines/create",
  LINE_MANAGER_MACHINE_CREATE_COMPANY: "/line-manager/machines/create/company",
  LINE_MANAGER_MACHINE_CREATE_DETAILS: "/line-manager/machines/create/details",

  // EVALUATOR
  EVALUATOR: "/evaluator",
  EVALUATOR_MACHINE_INDEX: "/evaluator/machines",
  EVALUATOR_MACHINE_CREATE: "/evaluator/machines/create",
  EVALUATOR_MACHINE_CREATE_COMPANY: "/evaluator/machines/create/company",
  EVALUATOR_MACHINE_CREATE_DETAILS: "/evaluator/machines/create/details",

  // Website
  WEBSITE: "/",
  WEBSITE_LOGIN_SECTION: "/login-selection",
  WEBSITE_LOGIN_CUSTOMER: "/login-customer",
  WEBSITE_LOGIN_SUPPLIER: "/login-supplier",

  // Dashboard
  DASHBOARD: "/dashboard",
  DASHBOARD_ASSETS: "/dashboard",
  DASHBOARD_ASSETS_NEW: "/dashboard/assets/new",
  DASHBOARD_ASSETS_IN_PROGRESS: "/dashboard/assets/in-progress",
  DASHBOARD_ASSETS_ACTIVE: "/dashboard/assets/active",

  // Roles
  ROLE_CHANGE: "/roles/change",

  // Machine Create
  MACHINE_EDIT: "/dashboard/machines/:machineId/edit",
  MACHINE_EDIT_COMPANY: "/dashboard/machines/:machineId/edit/company",
  MACHINE_EDIT_DETAILS: "/dashboard/machines/:machineId/edit/company",

  // Machine and assignements
  MACHINE_ASSIGNE_EMPLOYEE: "/dashboard/machines/:machineId/assign/employee",
  MACHINE_ASSIGNE_REURBISHER:
    "/dashboard/machines/:machineId/assign/refurbisher",
  MACHINE_ASSIGNE_ACTIVITIES_AND_TOOLS:
    "/dashboard/machines/:machineId/assign/activities-and-tools",

  // Machine and process (note: they will point to different URL but should be using the same forms)
  MACHINE_PROCESS: "/dashboard/machines/:machineId/process",
  MACHINE_PROCESS_EVALUATION:
    "/dashboard/machines/:machineId/process/evaluation",
  // Machine and forms
  MACHINE_FORMS_TOOL_LIST:
    "/dashboard/machines/:machineId/process/evaluation/form/tool-list",
  MACHINE_FORMS_VISUAL_CHECK_CHEET:
    "/dashboard/machines/:machineId/process/evaluation/form/visual-check-sheet",
  MACHINE_FORMS_STATIC_ACCURACY_CHECK_SHEET:
    "/dashboard/machines/:machineId/process/evaluation/form/static-accuracies",
  MACHINE_FORMS_DAILY_ACTIVITY_REPORT:
    "/dashboard/machines/:machineId/process/evaluation/form/daily-activities",
  MACHINE_FORMS_BALLSCREW_SUPPORT_BEARING_CLEARANCE:
    "/dashboard/machines/:machineId/process/evaluation/form/ballscrew-support",
  MACHINE_FORMS_ISSUE_FINDER_GUIDE:
    "/dashboard/machines/:machineId/process/evaluation/issue-finders",
  MACHINE_FORMS_ACTIVITY_LIST:
    "/dashboard/machines/:machineId/process/evaluation/form/activity-list",
  MACHINE_FORMS_COST_BENEFIT_ANALYIS:
    "/dashboard/machines/:machineId/process/evaluation/form/cost-benefit-analysis",

  MACHINE_PROCESS_EVALAUTION_CHECK:
    "/dashboard/machines/:machineId/process/evaluation-check",

  MACHINE_PROCESS_EVALUATION_OVERVIEW:
    "/dashboard/machines/:machineId/process/evaluation-overview",
  MACHINE_PROCESS_EVALIATION_REFURBSIHEMENT:
    "/dashboard/machines/:machineId/process/refurbishment",
  MACHINE_PROCESS_PUBLISH_TO_CATALOG:
    "/dashboard/machines/:machineId/process/publish-to-catalogue",

  FORMS_CONTAINER: "/dashboard/forms",
  SETTINGS_USER: "/settings/users",
  FINANCE: "/finance",
};

const dynamicPaths = {};

export const routesPaths = {
  ...staticPaths,
  ...dynamicPaths,
};
