import React, { useRef, useState } from "react";
import { useEffect } from "react";

import "./BellNotification.css";
import Icon from "@mdi/react";
import {
  mdiStarOutline,
  mdiCheck,
  mdiTrashCanOutline,
  mdiTune,
  mdiAlertCircleOutline,
} from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/index";
import {
  notificationDestroy,
  notificationDestroyAllByReceiver,
  notificationStared,
  notification_pop,
} from "../../features/notification/notificationSlice";
import { get_role_name } from "../../support/support_url";
import { showAlert } from "features/alert/alertSlice";
import { imagePaths } from "constants/image";

const BellNotification = ({ closeNotification }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxNotification = useSelector((state) => state.notification);
  const { currentUser } = useSelector((state) => state.auth);
  const clear = () => {
    dispatch(
      showAlert({
        status: "info",
        title: "Clear Notification",
        message: (
          <p className="fw-600">
            Are you sure you want to clear this notification?
          </p>
        ),
        icon: imagePaths.INFO,
        primaryButtonFunction: () => {
          dispatch(notificationDestroyAllByReceiver()).then(function (
            response
          ) {
            if (response.error && response.error.message === "Rejected") {
              //
            } else {
              loadModel();
            }
          });
        },
        primaryButtonText: "Yes",
        secondaryButtonText: "No",
      })
    );
  };

  const destroy = (notification) => {
    dispatch(
      showAlert({
        status: "info",
        title: "Delete Notification",
        message: (
          <p className="fw-600">
            Are you sure you want to delete this notification?
          </p>
        ),
        icon: imagePaths.INFO,
        primaryButtonFunction: () => {
          dispatch(
            notificationDestroy({
              notification_id: notification.id,
            })
          ).then(function (response) {
            if (response.error && response.error.message === "Rejected") {
              //
            } else {
              loadModel();
              //   closeNotification();
            }
          });
        },
        primaryButtonText: "Yes",
        secondaryButtonText: "No",
      })
    );
  };

  const stared = (notification) => {
    dispatch(
      notificationStared({
        notification_id: notification.id,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        //
      } else {
        loadModel();
      }
    });
  };

  const loadModel = () => {
    dispatch(notification_pop({ keyword: "", limit: 3, page: 1 }));
  };
  const handleClickOutside = (event) => {
    if (event.target.className === "notification-overlay") {
      closeNotification();
    }
  };

  useEffect(() => {
    loadModel();
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const getNotifIcon = (notif) => {
    if (notif.task_type_id === 1) {
      return require("assets/img/icons/tasks.png");
    } else if (notif.task_type_id === 2) {
      return require("assets/img/icons/tick.png");
    } else if (notif.task_type_id === 3) {
      return require("assets/img/icons/enquiry.png");
    } else if (notif.task_type_id === 4) {
      return require("assets/img/icons/alert.png");
    }
  };

  return (
    <div
      className="notification-overlay"
      data-overlay
      //   onClick={closeNotification}
    >
      <div className="notification-window">
        <div className="bell-notification-header">
          <h6>Notifications</h6>
          <div>
            <span
              id="span-close"
              onClick={() => {
                navigate(
                  get_role_name() + `/notifications/settings/${currentUser.id}`
                );
                closeNotification();
              }}
            >
              <Icon path={mdiTune} size={0.9} />
              Settings
            </span>

            <span
              id="span-clear-all"
              onClick={() => {
                clear();
                // closeNotification();
              }}
            >
              <Icon path={mdiTrashCanOutline} size={0.9} />
              Clear All
            </span>
          </div>
        </div>

        {reduxNotification.notification_details?.data?.map(
          (notificationDetail, index) => {
            if (index < 3) {
              return (
                <div className="notification-content" key={index}>
                  <div
                    // className="m-2"
                    id={`div-machine-notif-${index}`}
                    onClick={() => {
                      navigate(
                        `${get_role_name()}/machines/notification?id=${
                          notificationDetail?.parent?.id
                        }`
                      );
                      closeNotification();
                    }}
                  >
                    <span>
                      <img
                        src={getNotifIcon(notificationDetail?.parent)}
                        alt="notification-type-icon"
                      />
                    </span>
                  </div>
                  <div
                    className="notification-info"
                    id={`div-close-${index}`}
                    onClick={() => {
                      if (
                        notificationDetail?.parent?.notificationable_type !==
                        "order"
                      ) {
                        navigate(
                          `${get_role_name()}/machines/notification?id=${
                            notificationDetail?.parent?.id
                          }`
                        );
                      } else {
                        navigate(
                          `${get_role_name()}/enquiries/notification?id=${
                            notificationDetail?.parent?.id
                          }`
                        );
                      }
                      closeNotification();
                    }}
                  >
                    <h6>{notificationDetail?.parent?.title}</h6>
                    <p>{notificationDetail?.parent?.description}</p>
                    <p className={"mt-1"}>
                      {" "}
                      <em>
                        {notificationDetail?.created_ago} at{" "}
                        {notificationDetail?.created_time}{" "}
                      </em>
                    </p>
                  </div>
                  <div className="notification-control">
                    <button
                      id={`div-starred-${index}`}
                      onClick={() => {
                        stared(notificationDetail?.parent);
                      }}
                    >
                      <Icon
                        path={mdiStarOutline}
                        size={0.9}
                        className={
                          notificationDetail?.stared === 1 ? "stared" : ""
                        }
                      />
                    </button>
                    <span
                      id={`span-delete-${index}`}
                      onClick={() => {
                        destroy(notificationDetail?.parent);
                      }}
                    >
                      <Icon path={mdiTrashCanOutline} size={0.9} />
                    </span>
                  </div>
                </div>
              );
            } else {
              return false;
            }
          }
        )}

        {reduxNotification.notification_details?.data?.length === 0 && (
          <div className={"text-center p-5"}>No Notifications</div>
        )}

        {reduxNotification.notification_details?.data?.length > 0 && (
          <button
            className="mb-2 mt-2"
            onClick={() => {
              navigate(`${get_role_name()}/notifications`);

              closeNotification(); //todo - fix the close of overlay here
            }}
          >
            View All
          </button>
        )}
      </div>
    </div>
  );
};

export default BellNotification;
