import React, { useState, useEffect, Suspense } from "react";
import ProgressBar from "./ProgressBar";
import { Outlet, useParams } from "react-router-dom";
import { orderLeasingCheckout } from "../../features/order/orderSlice";
import { useDispatch } from "react-redux/es/index";
import { Spinner } from "react-bootstrap";

const Leasing = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [step, setStep] = useState();
  const [successModal, setSuccessModal] = useState(false);

  const steps = {
    1: "leasing-machine-details",
    2: "leasing-details",
    3: "leasing-review",
    4: "leasing-checkout",
  };
  const finishCheckout = () => {
    dispatch(
      orderLeasingCheckout({
        order_id: params.orderId,
      })
    );

    setSuccessModal(true);
  };

  useEffect(() => {
    setStep(1);
  }, []);

  return (
    <div className="container">
      <ProgressBar />
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </div>
  );
};

export default Leasing;
