import "./CustomerLogin.css";
import darkLogoEaas from "assets/images/logo/dark-logo-eaas.png";
import { useDispatch, useSelector } from "react-redux/es/index";
import support_device from "../../support/support_device";
import React, { useState } from "react";
import { showAlertToast } from "features/alert/toastSlice";
import { Col, Row } from "react-bootstrap";
import { registerCustomer } from "../../features/auth/registerSlice";
import { hideAlert, showAlert } from "../../features/alert/alertSlice";
import { imagePaths } from "../../constants/image";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../components/spinner/LoadingIndicator";
import Icon from "@mdi/react";
import { mdiInformation, mdiStarOutline } from "@mdi/js";
import Tooltip from "../../components/ui/tooltip/Tooltip";

const NewUserRegistration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const reduxRegister = useSelector((state) => state.register);
  const signup = () => {
    dispatch(
      registerCustomer({
        name: contactName,
        email: email,
        phone_number: phoneNumber,
        delivery_address: deliveryAddress,
        password: password,
        password_confirmation: passwordConfirmation,
        device_id: support_device.getBrowserId(),
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        dispatch(
          showAlert({
            message: response.payload,
            title: "Process Incomplete",
            icon: imagePaths.ERROR,
            status: "error",
            primaryButtonText: "Ok",
          })
        );
      } else {
        dispatch(
          showAlert({
            status: "success",
            title: "User Profile Created.",
            message: <p className="fw-600">User profile has been created.</p>,
            icon: imagePaths.SUCCESS,
            primaryButtonFunction: () => {
              dispatch(hideAlert());
              navigate("/");
            },
            primaryButtonText: "Continue",
            hideCloseButton: true,
          })
        );
      }
    });
  };

  return (
    <div className="background-customer mobile-height">
      {/* {reduxRegister.loading && <LoadingIndicator />} */}
      <div className="ec-header-login">
        <div className="header-logo mt-2 ml-5">
          <a href="/" id="a-logo">
            <img
              src={darkLogoEaas}
              alt="Site Logo"
              style={{
                width: "143px",
                height: "40px",
              }}
            />
            <img
              className="dark-logo"
              src={darkLogoEaas}
              alt="Site Logo"
              style={{ display: "none", width: "143px", height: "45px" }}
            />
          </a>
        </div>
      </div>
      {/* content */}
      <div className="ec-page-content section-space-pt">
        <div className="container">
          <Row>
            <Col className="col-md-6 mt-2">
              <div className="section-title" style={{ color: "#000" }}>
                <h2 className="ec-title">Create an account.</h2>
                <p className="sub-title reg-text">
                  This portal is for{" "}
                  <label className="text-orange m-0">EAASE</label> customers to
                  <br />
                  register and manage their machines on the <br />
                  platform.
                </p>
              </div>
            </Col>
            <Col className="col-md-6">
              <div className="ec-login-wrapper">
                <div className="ec-login-container reg-padding-top">
                  <div className="ec-login-form">
                    <form
                      className=""
                      onSubmit={(e) => {
                        e.preventDefault();
                        signup();
                      }}
                    >
                      <div className="">
                        <label>Contact Name</label>
                        <input
                          value={contactName}
                          id="input-txt-contact-name"
                          type="text"
                          onChange={({ target }) => {
                            setContactName(target.value);
                          }}
                        />
                      </div>

                      <div className="">
                        <label>Email</label>
                        <input
                          value={email}
                          id="input-txt-email"
                          type="email"
                          onChange={({ target }) => {
                            setEmail(target.value);
                          }}
                        />
                      </div>

                      <div className="">
                        <label>Phone Number</label>
                        <input
                          value={phoneNumber}
                          id="input-txt-phone-number"
                          type="tel"
                          onChange={({ target }) => {
                            setPhoneNumber(target.value);
                          }}
                        />
                      </div>

                      <div className="">
                        <label>Delivery Address</label>
                        <input
                          value={deliveryAddress}
                          type="text"
                          id="input-txt-delivery-address"
                          onChange={({ target }) => {
                            setDeliveryAddress(target.value);
                          }}
                        />
                      </div>

                      <div className="d-flex gap-3">
                        <div className="reg-password-width pr-2">
                          {showTooltip ? (
                            <Tooltip
                              translateX={"24px"}
                              translateY={"27px"}
                              textMain={
                                "The password must be at least six symbols " +
                                " long and include at least one capital letter," +
                                "one small letter, one symbol and one number and " +
                                "must not have 3 consecutive increasing or decreasing numbers."
                              }
                              width={"500px"}
                            />
                          ) : null}
                          <label>Password </label> {"  "}
                          <span
                            style={{
                              width: "30px",
                              height: "20px",
                              display: "inline-block",
                            }}
                            onMouseOver={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                          >
                            <Icon
                              path={mdiInformation}
                              size={0.8}
                              color="white"
                              className="ml-2 mb-1"
                            />
                          </span>
                          <input
                            value={password}
                            type="password"
                            id="input-txt-password"
                            onChange={({ target }) => {
                              setPassword(target.value);
                            }}
                          />
                        </div>
                        <div className="reg-password-width pl-2">
                          <label>Confirm Password</label>
                          <input
                            value={passwordConfirmation}
                            type="password"
                            id="input-txt-confirm-password"
                            onChange={({ target }) => {
                              setPasswordConfirmation(target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="form_button">
                        <button>Sign Up</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default NewUserRegistration;
