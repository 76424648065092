/**
 * GET|HEAD        api/admin/roles ........................................................................................................................................................................................... roles.index › Api\Admin\RoleController@index
 * GET|HEAD        api/admin/roles/{role} ...................................................................................................................................................................................... roles.show › Api\Admin\RoleController@show
 * PUT|PATCH       api/admin/roles/{role} .................................................................................................................................................................................. roles.update › Api\Admin\RoleController@update
 * PATCH           api/admin/roles/{role}/update-permissions ................................................................................................................................................................... Api\Admin\RoleController@updatePermissions
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";

import service from "./roleService";

export const adminRoleIndex = createAsyncThunk(
  "adminRoleIndex",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminRoleStore = createAsyncThunk(
  "admin/roles/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminRoleShow = createAsyncThunk(
  "admin/roles/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminRoleUpdate = createAsyncThunk(
  "admin/roles/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminRoleDelete = createAsyncThunk(
  "admin/roles/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminRoleUpdatePassword = createAsyncThunk(
  "admin/roles/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleUpdatePassword(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminRoleUpdatePermissions = createAsyncThunk(
  "admin/roles/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminRoleUpdatePermissions(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  roles: [],
  loading: false,
  isSuccess: false,
  updating: false,
};

export const adminRoleSlice = createSlice({
  name: "adminRole",
  initialState,
  reducers: {},
  extraReducers: {
    [adminRoleIndex.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [adminRoleIndex.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.roles = payload.data.roles;
      state.loading = false;
      state.isSuccess = true;
    },
    [adminRoleIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = adminRoleSlice.actions;

export default adminRoleSlice;
