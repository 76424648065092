import React, { useState } from "react";

const SnackbarContext = React.createContext({
    msg: "",
    isDisplayed: false,
    displayMsg: (msg, status) => {},
    onClose: () => {},
    status: ""
});

let timer;

export const SnackBarContextProvider = (props) => {
    const [msg, setMsg] = useState("");
    const [isDisplayed, setIsDisplayed] = useState(false);
    const [status, setStatus] = useState("");

    const displayHandler = (msg, status) => {
        setMsg(msg);
        setIsDisplayed(true);
        setStatus(status)
        timer = setTimeout(() => {
            closeHandler();
        }, 5000);
    };


    const closeHandler = () => {
        clearTimeout(timer);
        setIsDisplayed(false);
    };

    return (
        <SnackbarContext.Provider
            value={{
                msg,
                status,
                isDisplayed,
                displayMsg: displayHandler,
                onClose: closeHandler,
            }}
        >
            {props.children}
        </SnackbarContext.Provider>
    );
};

export default SnackbarContext;