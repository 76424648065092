import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";

import service from "./notificationService";

export const notificationIndex = createAsyncThunk(
  "notifications/index",
  async (args, thunkAPI) => {
    try {
      return await service.notificationIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const notification_pop = createAsyncThunk(
  "notifications/pop",
  async (args, thunkAPI) => {
    try {
      return await service.notificationIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const notificationStared = createAsyncThunk(
  "notifications/index",
  async (args, thunkAPI) => {
    try {
      return await service.notificationStared(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const notificationDestroy = createAsyncThunk(
  "notifications/index",
  async (args, thunkAPI) => {
    try {
      return await service.notificationDestroy(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const notificationDestroyAllByReceiver = createAsyncThunk(
  "notifications/index",
  async (args, thunkAPI) => {
    try {
      return await service.notificationDestroyAllByReceiver(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const notificationStore = createAsyncThunk(
  "notifications/index",
  async (args, thunkAPI) => {
    try {
      return await service.notificationStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
const initialState = {
  data: {},
  orders: {
    data: [],
  },
  notifications: {
    data: [],
  },
  notification: {},
  order: {},
  notification_detail: {},
  notification_details: {
    data: [],
  },
  contact: {},
  loading: false,
  isSuccess: false,
  updating: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: {
    [notificationIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [notificationIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.notifications) {
        state.notifications = payload.data.notifications;
      }
      if (payload?.data?.notification) {
        state.notification = payload.data.notification;
      }
      if (payload?.data?.notification_detail) {
        state.notification_detail = payload.data.notification_detail;
      }
      if (payload?.data?.notification_details) {
        state.notification_detail = payload.data.notification_details;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [notificationIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [notification_pop.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [notification_pop.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.notifications) {
        state.notifications_pop = payload.data.notifications;
      }
      if (payload?.data?.notification_details) {
        state.notification_details = payload.data.notification_details;
      }

      // state.notifications_pop = payload.data.notifications;
      state.loading = false;
      state.isSuccess = true;
    },
    [notification_pop.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = notificationSlice.actions;

export default notificationSlice;
