import api from "../../../support/support_api";
import support_response from "../../../support/support_response";

const trainingAttendanceUpdate = async (params) => {
    const response  = await api.axiosPatch(`/training-attendances/${params.training_attendance_id}`, params);

    return response.data;
}

const trainingAttendanceShow = async (params) => {
    const response  = await api.axiosGet(`/training-attendances/${params.training_attendance_id}`, params, false);

    return response.data;
}



const trainingAttendanceDetailAdd = async (params) => {
    const response  = await api.axiosPost(`/training-attendances/${params.training_attendance_id}/detail-add`, params, false);

    return response.data;
}

const trainingAttendanceService = {
    trainingAttendanceUpdate,
    trainingAttendanceShow,
    trainingAttendanceDetailAdd,
}

export default trainingAttendanceService;