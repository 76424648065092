import api from "../../../support/support_api";

const adminRoleIndex = async (params) => {
	const response = await api.axiosGet("/admin/roles", params);

	return response.data;
};

const adminRoleStore = async (params) => {
	const response = await api.axiosPost("/admin/roles", params);

	return response.data;
};

const adminRoleShow = async (params) => {
	const response = await api.axiosGet(`/admin/roles/${params.id}`, params);

	return response.data;
};

const adminRoleUpdate = async (params) => {
	const response = await api.axiosPatch(`/admin/roles/${params.id}`, params);

	return response.data;
};

const adminRoleDelete = async (params) => {
	const response = await api.axiosDelete(`/admin/roles/${params.id}`, params);

	return response.data;
};

const adminRoleUpdatePassword = async (params) => {
	const response = await api.axiosPost(`/admin/roles/update-password`, params);

	return response.data;
};

const adminRoleUpdatePermissions = async (params) => {
	const response = await api.axiosPatch(`/admin/roles/${params.id}/update-permissions`, params);

	return response.data;
};

const adminRoleService = {
	adminRoleIndex,
	adminRoleStore,
	adminRoleShow,
	adminRoleUpdate,
	adminRoleDelete,
	adminRoleUpdatePassword,
	adminRoleUpdatePermissions,
};

export default adminRoleService;
