import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import support_response from "../../support/support_response";
import machineService from "./completeService";

export const completeMark = createAsyncThunk(
  "completes/mark",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.completeMark(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const completeCheck = createAsyncThunk(
  "completes/check",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.completeCheck(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  model: {},
  machine: {},
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
};

export const completeSlice = createSlice({
  name: "complete",
  initialState,
  reducers: {},
  extraReducers: {
    [completeMark.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = false;
      state.isSuccess = false;
    },
    [completeMark.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.model = payload.data.model;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [completeMark.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [completeCheck.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [completeCheck.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.machine = payload.data.machine;
      state.loading = false;
      state.isSuccess = true;
    },
    [completeCheck.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {} = completeSlice.actions;

export default completeSlice;
