import React, { Suspense, useEffect } from "react";
import { Spinner } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { set_print } from "../../support/support_url";

const FormPreviewWrapper = () => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    //   dispatch(
    //     machineShow({
    //       machine_id: params.machineId,
    //     })
    //   );
    setTimeout(() => {
      set_print();
    }, 3000);
  }, []);

  return (
    <div className="m-5">
      <Suspense fallback={<Spinner />}>
        <Outlet />
      </Suspense>
    </div>
  );
};

export default FormPreviewWrapper;
