import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";
import machineService from "./staticAccuracyService";

export const staticAccuracyUpdate = createAsyncThunk(
  "form/static-accuracies/show-or-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.staticAccuracyUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const staticAccuracyShow = createAsyncThunk(
  "form/static-accuracies/show-or-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.staticAccuracyShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  static_accuracy: {},
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const staticAccuracyCheckSheetSlice = createSlice({
  name: "staticAccuracyCheckSheet",
  initialState,
  reducers: {
    // employeeSetErrors(state, action) {
    //     state.errors =  action.payload.errors
    // },
    updatStaticAccuracyStore(state, action) {
      state.static_accuracy[action.payload.key] = action.payload.value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [staticAccuracyUpdate.pending]: (state, { payload }) => {
      // state.static_accuracy = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [staticAccuracyUpdate.fulfilled]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;

      if (payload.data?.static_accuracy) {
        state.static_accuracy = payload.data.static_accuracy;
      }

      // state.employees = payload.data.employees;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [staticAccuracyUpdate.rejected]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
  },
});

export const {
  // employeeSetErrors
  updatStaticAccuracyStore,
} = staticAccuracyCheckSheetSlice.actions;

export default staticAccuracyCheckSheetSlice;
