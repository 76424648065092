import api from "../../../support/support_api";

const issueFinderUpdate = async (params) => {
  const response = await api.axiosPatch(`/issue-finders/${params.id}`, params);

  return response.data;
};

const issueFinderShow = async (params) => {
  const response = await api.axiosGet(
    `/issue-finders/${params.id}`,
    params,
    false
  );

  return response.data;
};

const issueFinderDetailAdd = async (params) => {
  const response = await api.axiosPost(
    `/issue-finders/${params.id}/detail-add`,
    params,
    false
  );

  return response.data;
};

const issueFinderDelete = async (params) => {
  const response = await api.axiosDelete(
    `/issue-finders/${params.id}/detail-add`,
    params,
    false
  );

  return response.data;
};

const issueFinderService = {
  issueFinderShow,
  issueFinderUpdate,
  issueFinderDetailAdd,
  issueFinderDelete,
};

export default issueFinderService;
