import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import styles from "./ProgressBar.module.css";

const ProgressBar = () => {
  const [currentPath, setCurrentPath] = useState();
  const [currentStep, setCurrentStep] = useState(null);

  const location = useLocation();
  const params = useParams();

  let progress = 1;
  if (
    location.pathname ===
    `/enquiries/${params.orderId}/leasing/leasing-machine-details`
  ) {
    progress = 1;
  } else if (
    location.pathname === `/enquiries/${params.orderId}/leasing/leasing-details`
  ) {
    progress = 2;
  } else if (
    location.pathname === `/enquiries/${params.orderId}/leasing/leasing-review`
  ) {
    progress = 3;
  } else if (
    location.pathname ===
    `/enquiries/${params.orderId}/leasing/leasing-checkout`
  ) {
    progress = 4;
  }

  return (
    <div data-testid="leasing-progress" className="d-flex flex-column">
      <section className={styles.progressBarContainer}>
        <div
          style={{
            width:
              progress === 2
                ? "35%"
                : progress === 3
                ? "66%"
                : progress === 4
                ? "100%"
                : "0",
          }}
          className={`${styles.progressBar}`}
        ></div>

        <div className={`${styles.progressItems}`}>
          <div className={styles.item}>
            <p>Machine Details</p>
            <span
              className={
                progress === 1
                  ? `${styles.testActive}`
                  : progress > 1
                  ? `${styles.testComplete}`
                  : null
              }
            ></span>
          </div>
          <div className={styles.item}>
            <p>Leasing Details</p>
            <span
              className={
                progress === 2
                  ? `${styles.testActive}`
                  : progress > 2
                  ? `${styles.testComplete}`
                  : null
              }
            ></span>
          </div>

          <div className={styles.item}>
            <p>Subscription Setup</p>
            <span
              className={
                progress === 3
                  ? `${styles.testActive}`
                  : progress > 3
                  ? `${styles.testComplete}`
                  : null
              }
            ></span>
          </div>

          <div className={styles.item}>
            <p>Checkout</p>
            <span
              className={progress === 4 ? `${styles.testComplete}` : null}
            ></span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProgressBar;
