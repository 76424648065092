import { Outlet } from "react-router-dom";
import CommonCardWrapperContent from "components/commons/Content/CommonCardWrapperContent";
import DashboardHeader from "components/menus/dashboard/DashboardHeader";

import SupplierMenu from "components/menus/profiles/supplier/ProfileMenu";
import SuperAdminMenu from "components/menus/profiles/super-admin/SuperAdminMenu";
import AdminMenu from "components/menus/profiles/admin/AdminMenu";
import {get_role_id, get_role_name_text} from "../../support/support_url";
import { Suspense } from "react";
import { Spinner } from "react-bootstrap";

const DashboardContainer = () => {
  let sidebar = <SupplierMenu />;


  // console.log(" get_role_name_text ", get_role_name_text());

  switch (get_role_name_text()) {
    case 'superadmin': //superadmin
      sidebar = <SuperAdminMenu />;
      break;
    case 'admin': //admin
      sidebar = <AdminMenu />;
      break;
    default:
      break;
  }

  return (
    <div className="ec-sidebar-fixed">
      <div className="wrapper">
        {sidebar}
        <div className="ec-page-wrapper" style={{ backgroundColor: "#F5F6F8" }}>
          <DashboardHeader />
          <CommonCardWrapperContent>
            <Suspense fallback={<Spinner />}>
              <Outlet />
            </Suspense>
          </CommonCardWrapperContent>
          {/* <DashboardFooter /> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
