import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";

import machineService from "./activityUpgradeService";

export const activityUpgradeShow = createAsyncThunk(
  "activityUpgradeShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.activityUpgradeShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const activityUpgradeUpdateDetails = createAsyncThunk(
  "activityUpgradeUpdateDetails",
  async (args, thunkAPI) => {
    try {
      return await machineService.activityUpgradeUpdateDetails(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  activity_upgrade: {},
  errors: {},

  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const activityUpgradeSlice = createSlice({
  name: "activityUpgrade",
  initialState,
  reducers: {
    updateUpgradeActivityListStatus(state, action) {
      // state.params.activities = [];
      const { key, value, id } = action.payload;

      state.activity_upgrade.list.map((detail) => {
        if (id === undefined) {
          detail[key] = value;
          detail.dirty = true;
        } else {
          if (detail.id === id) {
            detail[key] = value;
            detail.dirty = true;
          }
        }
      });

      state.dirty = true;
    },
  },
  extraReducers: {
    [activityUpgradeShow.pending]: (state, { payload }) => {
      state.activity_upgrade = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [activityUpgradeShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.activity_upgrade) {
        state.activity_upgrade = payload.data.activity_upgrade;
      }

      if (payload.data?.activity_upgrades) {
        state.activity_upgrades = payload.data.activity_upgrades;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [activityUpgradeShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [activityUpgradeUpdateDetails.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [activityUpgradeUpdateDetails.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.activity_upgrade) {
        state.activity_upgrade = payload.data.activity_upgrade;
      }

      if (payload.data?.activity_upgrades) {
        state.activity_upgrades = payload.data.activity_upgrades;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [activityUpgradeUpdateDetails.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateUpgradeActivityListStatus } = activityUpgradeSlice.actions;

export default activityUpgradeSlice;
