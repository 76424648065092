import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideAlertToast, showAlertToast } from "features/alert/toastSlice";

import styles from "./Snackbar.module.css";
import support_storage from "support/support_storage";
import axios from "axios";

const Snackbar = () => {
  const dispatch = useDispatch();

  // axios.interceptors.response.use(
  //   (response) => {
  //     const show =
  //       response.status === 200 &&
  //       response.config.method !== "get" &&
  //       response.config.method !== "post";

  //       console.log(" test1 ")

  //       if (show === true) {
  //       dispatch(
  //         showAlertToast({
  //           status: "success",
  //           message: response.data.message,
  //         })
  //       );
  //     }
  //     return response;
  //   },
  //   (error) => {
  //     if (error) {
  //       if (error?.response?.status === 401) {
  //         support_storage.setUserClear();
  //         window.location = "/";
  //       } else if (error?.response?.status === 422) {
  //         // BAD ERROR REQUEST
  //         if (error.config.showError === false) {
  //           // do nothing
  //         } else {
  //           dispatch(
  //             showAlertToast({
  //               status: "error",
  //               message: error.response.data.message,
  //             })
  //           );
  //         }
  //       } else if (error?.response?.status === 500) {
  //         dispatch(
  //           showAlertToast({
  //             status: "error",
  //             message: "Internal Server error", //error.response
  //             hideLoadingIndicator: true,
  //           })
  //         );
  //       } else {

  //           console.log("test4")
  //         dispatch(
  //           showAlertToast({
  //             status: "error",
  //             message: "Something went wrong",
  //             hideLoadingIndicator: true,
  //           })
  //         );
  //       }
  //     }

  //     throw error;
  //   }
  // );

  const toast = useSelector((state) => state.toast);
  useEffect(() => {
    if (toast.show) {
      const getData = setTimeout(() => {
        dispatch(hideAlertToast());
      }, 2000);
      return () => clearTimeout(getData);
    }
  }, [toast]);

  const onClose = () => {
    dispatch(hideAlertToast());
  };
  return ReactDOM.createPortal(
    <div
      id="toast-status"
      className={`${styles.snackbarContainer} ${
        styles[toast.status || "success"]
      } ${!toast.show && "d-none"}`}
    >
      <div className={styles.snackbarLabel}>{toast.message}</div>
      <div
        id="toast-status-close"
        className={styles.snackbarDismiss}
        onClick={onClose}
      >
        &times;
      </div>
    </div>,
    document.getElementById("snackbar__root")
  );
};

export default Snackbar;
