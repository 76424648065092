import api from "../../../support/support_api";

const ballscrewSupportUpdate = async (params) => {
    const response  = await api.axiosPatch(`/ballscrew-supports/${params.ballscrew_support_id}`, params);

    return response.data;
}

const ballscrewSupportShow = async (params) => {
    const response  = await api.axiosGet(`/ballscrew-supports/${params.ballscrew_support_id}`, params, false);

    return response.data;
}

const ballscrewReportService = {
    ballscrewSupportUpdate,
    ballscrewSupportShow,
}

export default ballscrewReportService;