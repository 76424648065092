import storage from "../../support/support_storage";

import api from "../../support/support_api";
import secureLocalStorage from "react-secure-storage";
const auth = async () => {
  return JSON.parse(secureLocalStorage.getItem("user"));
};

const authUpdate = async (params) => {
  const response = await api.axiosPatch("/users", params);

  return response.data;
};

const authUpdateCompany = async (params) => {
  const response = await api.axiosPatch("/companies", params);

  return response.data;
};

const authUpdateCompanyPhoto = async (params) => {
  const response = await api.axiosPost(
    `/files/upload/${params.get("company_id")}`,
    params
  );

  return response.data;
};

const authUpdatePhoto = async (params) => {
  let config = await storage.getConfig();

  const response = await api.axiosPost(
    `/files/upload/${params.get("user_id")}`,
    params
  );

  return response.data;
};

const authRoleActiveChange = async (params) => {
  let config = await storage.getConfig();

  const response = await api.axiosPost(`/users/role-active-change`, params);

  return response.data;
};

const authService = {
  auth,
  authUpdate,
  authUpdateCompany,
  authUpdateCompanyPhoto,
  authUpdatePhoto,
  authRoleActiveChange,
};

export default authService;
