import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import support_response from "../../support/support_response";
import  machineService from "./activityService";

export const activityIndex = createAsyncThunk(
    "activities/index",
    async (args, thunkAPI) => {
        try {
            const response = await machineService.activityIndex(args);
            // showAlert
            return response;
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

const initialState = {
    data: {},
    activity: {},
    activities: {
        data: [ ]
    },
    categories: {
        data: [ ]
    },
    params:{
        activities: [
            {
                id:0,
                quantity:0
            }
        ]
    },

    errors: {},
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        activitySetErrors(state, action) {
            state.errors =  action.payload.errors
        },
        updateStatus(state, action) {
            // note: deep nested update works in reactjs toolki!!! it takes me several hours to figure out this!
                const {categoryIndex, activityIndex, id} = action.payload;

                let item = {...state.categories.data[categoryIndex].activities.data[activityIndex]};

                if(item.status == 0) {
                    item.status = 1;
                } else {
                    item.status = 0;
                }
                state.categories.data[categoryIndex].activities.data[activityIndex] = item;

            // append data to redux toolkit
                state.params.activities = state.params.activities.filter((arrow) => arrow.id !== id);
                state.params.activities = state.params.activities.filter((arrow) => arrow.id !== 0);

                state.params.activities.push({
                    id:id,
                    status: item.status
                })
        }
    },
    extraReducers : {
        [activityIndex.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [activityIndex.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating =false;
            // state.activity = payload.data.activity;
            // state.activities = payload.data.activities;
            state.categories = payload.data.categories;
            state.loading=false;
            state.isSuccess = true;
        },
        [activityIndex.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const { activitySetErrors, updateStatus} = activitySlice.actions

export default activitySlice;