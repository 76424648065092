import api from "../../../support/support_api";

const adminPermissionIndex = async (params) => {
	const response = await api.axiosGet(`/admin/permissions`, params);

	return response.data;
};

const adminPermissionUpdate = async (params) => {
	const response = await api.axiosPatch(`/admin/permissions/${params.id}`, params);

	return response.data;
};

const adminPermissionShow = async (params) => {
	const response = await api.axiosShow(`/admin/permissions/${params.id}`, params);

	return response.data;
};

const adminPermissionService = {
	adminPermissionIndex,
	adminPermissionUpdate,
	adminPermissionShow,
};

export default adminPermissionService;
