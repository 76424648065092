import api from "../../../support/support_api";

const adminPromotionIndex = async (params) => {
  const response = await api.axiosGet(`/admin/promotions`, params);

  return response.data;
};

const adminPromotionStore = async (params) => {
  const response = await api.axiosPost(`/admin/promotions`, params);

  return response.data;
};

const adminPromotionShow = async (params) => {
  const response = await api.axiosShow(
    `/admin/promotions/${params.id}`,
    params
  );

  return response.data;
};

const adminPromotionUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/admin/promotions/${params.id}`,
    params
  );

  return response.data;
};

const adminPromotionDelete = async (params) => {
  const response = await api.axiosDelete(
    `/admin/promotions/${params.id}`,
    params
  );

  return response.data;
};

const adminPromotionService = {
  adminPromotionIndex,
  adminPromotionStore,
  adminPromotionShow,
  adminPromotionUpdate,
  adminPromotionDelete,
};

export default adminPromotionService;
