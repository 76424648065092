import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  tab: {
    active: "",
  },
};

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setTab(state, action) {
      let newInfo = action.payload;
      let existingInfo = { ...state.tab };
      let updatedInfo = Object.assign(existingInfo, newInfo);

      state.tab = updatedInfo;
    },
  },
  extraReducers: {},
});

export const { setTab } = tabSlice.actions;

export default tabSlice;
