import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";
import machineService from "./trainingAttendanceService";

export const trainingAttendanceUpdate = createAsyncThunk(
  "training-attendances/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.dailyActivityUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const trainingAttendanceShow = createAsyncThunk(
  "trainingAttendanceShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.trainingAttendanceShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const trainingAttendanceDetailAdd = createAsyncThunk(
  "trainingAttendanceDetailAdd",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.trainingAttendanceDetailAdd(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  training_attendance: {},
  errors: {},
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const trainingAttendanceSlice = createSlice({
  name: "trainingAttendance",
  initialState,
  reducers: {
    updateAttendanceList(state, action) {
      const { index, key, value } = action.payload;
      state.training_attendance.details[index][key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [trainingAttendanceShow.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [trainingAttendanceShow.fulfilled]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;

      if (payload?.data?.training_attendance) {
        state.training_attendance = payload.data.training_attendance;
      }
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [trainingAttendanceShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [trainingAttendanceDetailAdd.fulfilled]: (state, { payload }) => {
      state.updating = false;

      if (payload?.data?.training_attendance) {
        state.training_attendance = payload.data.training_attendance;
      }
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
  },
});

export const {
  updateDetail,
  updateAttendanceList,

  // employeeSetErrors
} = trainingAttendanceSlice.actions;

export default trainingAttendanceSlice;
