import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";
import machineService from "./issueFinderService";

export const issueFinderUpdate = createAsyncThunk(
  "issue-finders/show-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.issueFinderUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const issueFinderShow = createAsyncThunk(
  "issue-finders/show-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.issueFinderShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const issueFinderDetailAdd = createAsyncThunk(
  "issue-finders/show-update",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.issueFinderDetailAdd(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const issueFinderDetailDelete = createAsyncThunk(
  "issue-finders/show-update",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.issueFinderDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  // data: {},
  // employee: {},
  // employees: {
  //     data: [ ]
  // },
  issue_finder: {},
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const issueFinderSlice = createSlice({
  name: "issueFinderGuide",
  initialState,
  reducers: {
    resetIssueFinderStore(state, action) {
      const { id } = action.payload;
      state.issue_finder.details.filter((x) => {
        if (x.id === id) {
          delete x.dirty;
        }
      });
    },

    updateIssueFinderStore(state, action) {
      const { id, key, value } = action.payload;
      state.issue_finder.details.filter((x) => {
        if (x.id === id) {
          x[key] = value;
          x.dirty = true;
        }
      });
      state.dirty = true;
    },
  },
  extraReducers: {
    [issueFinderUpdate.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      // state.issue_finder = {};
    },
    [issueFinderUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.issue_finder = payload.data.issue_finder;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [issueFinderUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  employeeSetErrors,
  updateIssueFinderStore,
  resetIssueFinderStore,
} = issueFinderSlice.actions;

export default issueFinderSlice;
