import api from "../../support/support_api";

const getBanks = async (params) => {
  const response = await api.axiosGet(`/payments/banks`, params);

  return response.data;
};

const processPayment = async (params) => {
  const response = await api.axiosPost(`/payments/process`, params);

  return response.data;
};

const getOperatorPayments = async (params) => {
  const response = await api.axiosGet(`/payment-details`, params);


  return response.data;
};
const getCustomerPayments = async (params) => {
  const response = await api.axiosGet(`/payments`, params);

  return response.data;
};

const paymentService = {
  getBanks,
  processPayment,
  getOperatorPayments,
  getCustomerPayments,
};

export default paymentService;
