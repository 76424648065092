import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { adminWebsiteDefaultShow } from "../../features/admin/website/websiteSlice";
import logoEaas from "assets/images/logo/logo-eaas.png";
import banner from "assets/images/banner/vector.png";
import shapeOne from "assets/images/banner/shape-1.png";
import shapeTwo from "assets/images/banner/shape-2.png";
import hand from "assets/images/banner/hand.png";
import company from "assets/images/icons/company.png";
import arrowRight from "assets/images/icons/arrow-right.png";
import arrowLeft from "assets/images/icons/arrow-left.png";
import millingMachine from "assets/images/icons/milling-machine.png";
import productImage from "assets/images/product-image/6_1.jpg";
import "../../style.css";
import "./SupplierLanding.scss";
import { useNavigate } from "react-router-dom";
import CommonButtonPrimary from "components/commons/Button/CommonButtonPrimary";
import CommonButtonSecondaryWhite from "components/commons/Button/CommonButtonSecondaryWhite";
import { Row } from "react-bootstrap";

const LandingPage = () => {
  const [website, setWebsite] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bg1 = encodeURI(website.section1_image?.url);
  const bg2 = website.section2_image?.url
    ? encodeURI(website.section2_image?.url)
    : null;
  const bg3 = encodeURI(website.section3_image?.url);
  useEffect(() => {
    dispatch(adminWebsiteDefaultShow()).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        //error modal ?
      } else {
        if (response.payload) {
          setWebsite(response.payload.data.website);
        }
      }
    });
  }, [dispatch]);

  return (
    <section>
      <div
        className="section banner-section"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="d-flex justify-content-between">
          <div className="content-column">
            <div className="inner-column">
              <div
                className="font-mobile-size"
                id="form_section_1"
                dangerouslySetInnerHTML={{ __html: website.section1 }}
              ></div>
              {/* <div className="col-md-6 mr-auto"> */}
              {/* <div className="btn-plan" style={{ marginTop: "20px" }}> */}
              <Row className="my-5">
                <div className="col-sm-6 col-md-6 mr-auto mt-2">
                  <CommonButtonPrimary
                    style={{ height: "60px" }}
                    label={"Browse Machine"}
                    onclick={() => {
                      navigate("/products");
                    }}
                  />
                </div>
                <div className="col-sm-6 col-md-6 mr-auto mt-2">
                  <CommonButtonSecondaryWhite
                    style={{ height: "60px", color: "#353535 !important" }}
                    label={"How it Works"}
                    className={"white-button-text-gray"}
                    onclick={() => navigate("/how-it-works")}
                  />
                </div>
              </Row>
            </div>
          </div>
          <div
            className="image-column"
            style={{ backgroundImage: "url(" + banner + ")" }}
          >
            <img src={hand} alt="hand holding a phone" />
          </div>
        </div>
      </div>

      <section
        className="section ec-gear-section section-space-p"
        style={bg2 ? { backgroundImage: `url(${bg2})` } : {}}
      >
        <div
          className="content-gear"
          id="form_section_2"
          dangerouslySetInnerHTML={{ __html: website.section2 }}
        ></div>
      </section>

      <section
        className="section ec-services-section section-space-p"
        style={{
          position: "relative",
          backgroundImage: `url(${bg3})`,
        }}
        id="how_it_works"
      >
        <div className="shape-layers-work">
          <div
            className="work-shape-1 mobile-hide-image"
            style={{ backgroundImage: "url(" + shapeOne + ")" }}
          ></div>
          <div
            className="work-shape-2 mobile-hide-image"
            style={{ backgroundImage: "url(" + shapeTwo + ")" }}
          ></div>
        </div>
        <div className="container">
          <h2 className="text-center title-section mb-5 mt-5">How it Works</h2>
          <div className="row">
            <div className="how-card">
              <div className="card how-card-item">
                <div className="card-body p-5 how-flex-card">
                  <img src={millingMachine} />
                  <span className="title-work">I have a machine:</span>
                  <ul className="list-work">
                    <li>That is pre-owned</li>
                    <li>That is for sale </li>
                  </ul>
                </div>
              </div>
              <div className="card how-card-item">
                <div className="card-body p-5 how-flex-card">
                  <img src={company} />
                  <span className="title-work">I want to lease a machine:</span>
                  <ul className="list-work">
                    <li>That improves overall cashflow</li>
                    <li>For short-to-medium term lease</li>
                    <li>Offering hassle-free maintenance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="work-arrow">
              <img
                src={arrowRight}
                className="work-arrow-right mobile-hide-image"
              />
              <img src={logoEaas} className="work-arrow-logo" />
              <img
                src={arrowLeft}
                className="work-arrow-left mobile-hide-image"
              />
            </div>
          </div>
          <div className="row">
            <div className="work-caption">
              <div
                className="find-out-more-text"
                id="form_section_3"
                dangerouslySetInnerHTML={{ __html: website.section3 }}
              ></div>
            </div>
          </div>
          <div className="row mt-4 mb-5">
            <div className="col text-center">
              <button
                className="btn btn-primary btn-plans"
                id={`btn-find-out-more`}
                style={{ height: "60px" }}
                onClick={() => navigate("/how-it-works")}
              >
                Find Out More
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="section ec-services-section section-space-p">
        <div className="container">
          <h2 className="text-center title-section mb-5 mt-5">
            New machines on the EAASE marketplace
          </h2>
          <div className="shop-pro-content mt-5">
            <div className="shop-pro-inner">
              <div className="row" id="assets_list"></div>
              <div className="row d-none">
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content">
                  <div className="card card-product-catalog">
                    <div className="card-body">
                      <div className="ec-product-inner">
                        <div className="ec-pro-image-outer">
                          <div className="ec-pro-image d-flex justify-content-center">
                            <a
                              href="product-left-sidebar.html"
                              className="image"
                            >
                              <img
                                className="main-image"
                                src={productImage}
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ec-pro-content">
                          <h5 className="ec-pro-title">
                            <a href="product-left-sidebar.html">Machine Name</a>
                          </h5>
                          <div className="rating-product">
                            <span className="label-rating">Condition</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="rating-product">
                            <span className="label-rating">Machine Health</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <span className="label-machine">MC Model</span>
                          <span className="label-machine">
                            MC Controller Model
                          </span>
                          <span className="label-machine">Year of Make</span>

                          <div className="d-flex justify-content-end mt-2">
                            <a href="/">
                              <button className="btn btn-primary px-4">
                                id={`btn-details`}
                                Details
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content">
                  <div className="card card-product-catalog">
                    <div className="card-body">
                      <div className="ec-product-inner">
                        <div className="ec-pro-image-outer">
                          <div className="ec-pro-image d-flex justify-content-center">
                            <a
                              href="product-left-sidebar.html"
                              className="image"
                            >
                              <img
                                className="main-image"
                                src={productImage}
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ec-pro-content">
                          <h5 className="ec-pro-title">
                            <a href="product-left-sidebar.html">Machine Name</a>
                          </h5>
                          <div className="rating-product">
                            <span className="label-rating">Condition</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="rating-product">
                            <span className="label-rating">Machine Health</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <span className="label-machine">MC Model</span>
                          <span className="label-machine">
                            MC Controller Model
                          </span>
                          <span className="label-machine">Year of Make</span>

                          <div className="d-flex justify-content-end mt-2">
                            <a href="/">
                              <button
                                className="btn btn-primary px-4 "
                                id={`btn-details-2`}
                              >
                                Details
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content">
                  <div className="card card-product-catalog">
                    <div className="card-body">
                      <div className="ec-product-inner">
                        <div className="ec-pro-image-outer">
                          <div className="ec-pro-image d-flex justify-content-center">
                            <a
                              href="product-left-sidebar.html"
                              className="image"
                            >
                              <img
                                className="main-image"
                                src={productImage}
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ec-pro-content">
                          <h5 className="ec-pro-title">
                            <a href="product-left-sidebar.html">Machine Name</a>
                          </h5>
                          <div className="rating-product">
                            <span className="label-rating">Condition</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="rating-product">
                            <span className="label-rating">Machine Health</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <span className="label-machine">MC Model</span>
                          <span className="label-machine">
                            MC Controller Model
                          </span>
                          <span className="label-machine">Year of Make</span>

                          <div className="d-flex justify-content-end mt-2">
                            <a href="/">
                              <button className="btn btn-primary px-4  id={`btn-details-3`}">
                                Details
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content">
                  <div className="card card-product-catalog">
                    <div className="card-body">
                      <div className="ec-product-inner">
                        <div className="ec-pro-image-outer">
                          <div className="ec-pro-image d-flex justify-content-center">
                            <a
                              href="product-left-sidebar.html"
                              className="image"
                            >
                              <img
                                className="main-image"
                                src={productImage}
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ec-pro-content">
                          <h5 className="ec-pro-title">
                            <a href="product-left-sidebar.html">Machine Name</a>
                          </h5>
                          <div className="rating-product">
                            <span className="label-rating">Condition</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="rating-product">
                            <span className="label-rating">Machine Health</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <span className="label-machine">MC Model</span>
                          <span className="label-machine">
                            MC Controller Model
                          </span>
                          <span className="label-machine">Year of Make</span>

                          <div className="d-flex justify-content-end mt-2">
                            <a href="/">
                              <button
                                className="btn btn-primary px-4"
                                id={`btn-details-4`}
                              >
                                Details
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content">
                  <div className="card card-product-catalog">
                    <div className="card-body">
                      <div className="ec-product-inner">
                        <div className="ec-pro-image-outer">
                          <div className="ec-pro-image d-flex justify-content-center">
                            <a
                              href="product-left-sidebar.html"
                              className="image"
                              id={`a-product`}
                            >
                              <img
                                className="main-image"
                                src={productImage}
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ec-pro-content">
                          <h5 className="ec-pro-title">
                            <a
                              href="product-left-sidebar.html"
                              id={`a-machine-name`}
                            >
                              Machine Name
                            </a>
                          </h5>
                          <div className="rating-product">
                            <span className="label-rating">Condition</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="rating-product">
                            <span className="label-rating">Machine Health</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <span className="label-machine">MC Model</span>
                          <span className="label-machine">
                            MC Controller Model
                          </span>
                          <span className="label-machine">Year of Make</span>

                          <div className="d-flex justify-content-end mt-2">
                            <a href="/">
                              <button className="btn btn-primary px-4 id={`btn-details-5`}">
                                Details
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-6 pro-gl-content">
                  <div className="card card-product-catalog">
                    <div className="card-body">
                      <div className="ec-product-inner">
                        <div className="ec-pro-image-outer">
                          <div className="ec-pro-image d-flex justify-content-center">
                            <a
                              href="product-left-sidebar.html"
                              className="image"
                              id={`a-product-2`}
                            >
                              <img
                                className="main-image"
                                src={productImage}
                                alt="Product"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="ec-pro-content">
                          <h5 className="ec-pro-title">
                            <a
                              href="product-left-sidebar.html"
                              id={`a-machine-name-2`}
                            >
                              Machine Name
                            </a>
                          </h5>
                          <div className="rating-product">
                            <span className="label-rating">Condition</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="rating-product">
                            <span className="label-rating">Machine Health</span>
                            <div className="ec-pro-rating">
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star fill"></i>
                              <i className="ecicon eci-star"></i>
                            </div>
                          </div>
                          <div className="my-2"></div>
                          <span className="label-machine">MC Model</span>
                          <span className="label-machine">
                            MC Controller Model
                          </span>
                          <span className="label-machine">Year of Make</span>

                          <div className="d-flex justify-content-end mt-2">
                            <a href="" id={`btn-details-6`}>
                              <button className="btn btn-primary px-4">
                                Details
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default LandingPage;
