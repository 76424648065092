import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    type: 'modal',
    status: '',
    title: '',
    message: '',
    show: false,
    // closeButton: false,
    // primaryButtonText: '',
    // secondaryButtonText: '',
    // primaryButtonCallback: '',
    // secondaryButtonCallback: '',
    // imageIcon: '',
    hideCloseButton:false,
    callback: '', 
    icon: '', 
    customActionButtons: '', 
    customBody: '', 
    headerTitle: '', 
    imageAlt: '', 
    secondaryButtonText: '', 
    primaryButtonText: '', 
    secondaryButtonFunction: '',
    primaryButtonFunction: ''
}

export const alertSlice = createSlice({
    name : 'alert',
    initialState,
    reducers: {
        showAlert : (state, action) => { 

            const {addressCopyAlert, hideCloseButton, hideNoButton, customBackground, callback, icon, title, message, customActionButtons, customBody, headerTitle, imageAlt, secondaryButtonText, primaryButtonText, secondaryButtonFunction,primaryButtonFunction, status } = action.payload;
            return {
                ...state,
                type: 'modal',
                status : status,
                title: title,
                icon: icon,
                message : message,
                show: true,
                primaryButtonText: primaryButtonText,
                secondaryButtonText: secondaryButtonText,
                hideCloseButton: hideCloseButton,
                callback: callback,
                customActionButtons: customActionButtons,
                customBody: customBody,
                headerTitle: headerTitle,
                imageAlt: imageAlt,
                hideNoButton: hideNoButton,
                secondaryButtonFunction:secondaryButtonFunction, 
                primaryButtonFunction: primaryButtonFunction,
                customBackground: customBackground,
                addressCopyAlert: addressCopyAlert

            }
        },

        hideAlert : (state, action) => {
            // const {message, status} = action.payload;
           
            
            return {
                ...state, 
                show: false
            }
        }
    }
}   
)

export const {showAlert, hideAlert } = alertSlice.actions;
export default alertSlice;