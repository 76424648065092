import api from "../../support/support_api";

const toolIndex = async (params) => {
    const response  = await api.axiosGet(`/tools`, params);

    return response.data;
}

const toolByCategory = async (params) => {
    const response  = await api.axiosGet(`/tools/by-category`, params);

    return response.data;
}

const toolService = {
    toolIndex,
    toolByCategory,
}

export default toolService;