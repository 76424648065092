import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "_helpers/i18n";
import { store } from "app/store";
import { SnackBarContextProvider } from "_helpers/snackbar-context";

import "assets/css/website/website.css"; // todo - temporary added as global becuase frontend requires this. This doesn't work when including it through module.css therefore we need to add it  here to make sure it's visible to everyone. Declared at first so that we can override it at anytime.
import "./style.css";
import "./global-figma.scss";
import "assets/css/vendor/ecicons.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <SnackBarContextProvider>
      <App />
    </SnackBarContextProvider>
  </Provider>
);

reportWebVitals();
