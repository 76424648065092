import { Link } from "react-router-dom";
import "./SelectionLogin.scss";
const SelectionLogin = () => {
  return (
    <>
      <div className="container pt-9  ">
        <div className="row mb-5 pt-4 mobile-padding">
          <div className="col-md-12 text-center">
            <h3 className="title-section-login">Login</h3>
          </div>
        </div>
        <div className="row  ">
          <div className="login-section mobile-layout">
            <div className="login-sub-section d-flex justify-content-center flex-column">
              <div className="login-customer align-self-center">
                <Link to="/login-customer">
                  <img
                    src={
                      require("assets/images/common/customer-icon.svg").default
                    }
                    alt="customer"
                  />
                </Link>
              </div>
              <h5 className="login-sub-title align-self-center">Customer</h5>
              <span className="login-label align-self-center">
                Don't have an account?{" "}
                <a className="text-black" href="/register">
                  Register here.
                </a>
              </span>
            </div>
            <div className="login-sub-section d-flex justify-content-center flex-column">
              <div className="login-supplier align-self-center">
                <Link to="/login-supplier">
                  <img
                    src={
                      require("assets/images/common/supplier-icon.svg").default
                    }
                    alt="customer"
                  />
                </Link>
                {/*</a>*/}
              </div>
              <h5 className="login-sub-title align-self-center">Supplier</h5>
              <span className="login-label align-self-center">
                Want to be a supplier?{" "}
                <a className="text-black" href="/suppliers">
                  Find out more here.
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <img
        src={require("assets/images/banner/login-vector.png")}
        className="login-image"
        alt="process steps"
      />
    </>
  );
};

export default SelectionLogin;
