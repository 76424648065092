import api from "../../../support/support_api";

const adminWebsiteDefaultUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/admin/websites/default`, params);

    return response.data;
}

const adminWebsiteDefaultShow = async (params) => {
    const response  = await  api.axiosGet(`/admin/websites/default`, params, false);

    return response.data;
}

const catalogService = {
    adminWebsiteDefaultUpdate,
    adminWebsiteDefaultShow,
}

export default catalogService;