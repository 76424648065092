import api from "../../support/support_api";
import storage from "../../support/support_storage";

// const registerDetail = async (userData) => {
//     const response = await api.axiosPost(`/users/register`, userData);
//
//     if(response.data) {
//         storage.setUser(response.data)
//     }
//
//     return response.data;
// }
//
// const registerType = async (roles) => {
//     const response = await api.axiosPost(`/users/register-type`,  {
//         'roles' : roles
//     });
//
//     return response.data;
// }
//
// const registerCompany = async (company) => {
//     const response = await api.axiosPost(`/companies/register`, company);
//
//     if(response.data) {
//         storage.setUser(response.data)
//     }
//
//     return response.data;
// }

const registerCustomer = async (params) => {
  let config = await storage.getConfig({ showError: false });
  const response = await api.axiosPost(
    `/users/register-customer`,
    params,
    config
  );

  if (response.data) {
    storage.setUser(response.data);
  }

  return response.data;
};

const registerService = {
  registerCustomer,
  // registerType,
  // registerCompany
};

export default registerService;
