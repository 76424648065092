import secureLocalStorage from "react-secure-storage";
const getAuth = async () => {
  return JSON.parse(secureLocalStorage.getItem("user"));
};

/**
 *  import  storage from "../../support/storage";
 *  let token = await storage.getToken();
 *
 * @param params
 * @returns {Promise<string|*|executor|CancelToken|string|any>}
 */
const getToken = async (params) => {
  let user = await JSON.parse(secureLocalStorage.getItem("user"));

  return user.data.token;
};

const setUser = async (params) => {
  // let user = await JSON.parse(secureLocalStorage.getItem("user"));
  secureLocalStorage.setItem("user", JSON.stringify(params));
  // secureLocalStorage.setItem("showMarkAsComplete", null);
  const user = await JSON.parse(secureLocalStorage.getItem("user"));

  return user;
};

const setUserClear = async (params) => {
  secureLocalStorage.removeItem("user", null);
  // secureLocalStorage.setItem("showMarkAsComplete", null);

  const user = await secureLocalStorage.getItem("user");

  return user;
};

const getConfig = async (params) => {
  let user = await JSON.parse(secureLocalStorage.getItem("user"));
  let token = null;

  if (user != null) {
    token = user.data.token;
  }

  let config = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
    timeout: 0,
    showError: params?.showError,
  };

  return config;
};

const support_storage = {
  getToken,
  getAuth,
  getConfig,
  setUser,
  setUserClear,
};

export default support_storage;
