import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import {
  mdiAccountMultiple, //users
  mdiHexagonMultiple, //roles
  mdiKeyVariant, // permissions
  mdiBellOutline, // notifications
  mdiViewModule, // modules
  /* mdiFileDocument, // forms */
  mdiNaturePeople, // change role
  mdiLogoutVariant, //logout
} from "@mdi/js";

import CommonSidebar from "components/commons/SideBar/CommonSidebar";
import { getCurrentUserInfo } from "../../../../features/auth/authSlice";

import { logout } from "../../../../features/auth/logoutSlice";
import { routesPaths } from "constants/routes.constants";
import { get_role_name } from "../../../../support/support_url";
import { showAlertToast } from "features/alert/toastSlice";

const AdminMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const roleName = get_role_name();
  const { currentUser, currentRole } = useSelector((state) => state.auth);
  const [highlight, setHighlight] = useState();

  const links = [
    { title: "Users", path: `${roleName}/users` },
    { title: "Roles", path: `${roleName}/roles` },
    {
      title: "Permissions",
      path: `${roleName}/permissions`,
    },
    {
      title: "Notifications",
      path: `${roleName}/notifications`,
    },
    { title: "Modules", path: `${roleName}/modules` },
    {
      icon: mdiNaturePeople,
      title: "Change Role",
      path: `${roleName}${routesPaths.ROLE_CHANGE}`,
      separator: true,
    },
  ];

  useEffect(() => {
    dispatch(getCurrentUserInfo());
   const currentLinkIndex = links.findIndex((link) => link.path === location.pathname)
    currentLinkIndex === -1 && location.pathname.includes("roles/edit") ?
      setHighlight(2) : currentLinkIndex === -1 && location.pathname.includes("modules") ?
        setHighlight(5) : setHighlight(currentLinkIndex+1);
  }, [highlight]);

  const logoutHandler = (event) => {
    dispatch(logout()).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        dispatch(
          showAlertToast({
            status: "error",
            message: response.payload,
          })
        );
      } else {
        if (get_role_name() === "customer") {
          window.location = "/login-customer";
        } else {
          window.location = "/login-supplier";
        }
      }
    });

    // event.preventDefault();
  };

  const setClassName = (e) => {
    if (e && +e.target.dataset.index) {
      setHighlight(+e.target.dataset.index);
    }
  }
  return (
    <CommonSidebar>
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-3">
        <div className="symbol symbol-50px">
          <img src={require("assets/img/user/Ellipse 1.png")} alt="ellipse" />
        </div>
        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          <div className="d-flex">
            <div className="flex-grow-1 me-2">
              <span className="fs-6 fw-bold user-name">{currentUser.name}</span>
              <br />
              <span className="user-role">{currentRole.name_display}</span>
              <br />
              <small>{currentUser?.organization?.name_limit}</small>
            </div>
          </div>
        </div>
      </div>
      <div className="ec-navigation" data-simplebar>
        <ul className="nav sidebar-inner" id="sidebar-menu">
          {links.map((link, index) => {
            return (
              <li
                className={highlight === index+1 ? "active" : ""}
                key={`nav-menu-${index}`}
                onClick={(e)=> setClassName(e)}
              >
                {link.separator ? <hr className={"border"} /> : null}
                <Link
                  id={`link-${link.title}`}
                  className="sidenav-item-link"
                  to={link.path}
                  data-index = {index+1}
                >
                  {link.icon ? (
                    <Icon
                      path={link.icon}
                      size={1}
                      data-index = {index+1}
                      color={
                        highlight === index+1 ? `#fff` : `#C2CFE0`
                      }
                    />
                  ) : (
                    <div
                      className={`side-menu-icon ${link.title.toLowerCase()}-icon${
                        highlight === index+1 ? "-s" : ""
                      }`}
                      data-index = {index+1}
                    />
                  )}

                  <span className="nav-text"  data-index = {index+1}>{link.title}</span>
                </Link>
              </li>
            );
          })}

          <li style={{ marginLeft: "25px", marginTop: "10px" }}>
            <button
              id="logout-btn"
              className="sidenav-item-link btn-logout"
              onClick={() => logoutHandler()}
            >
              <div className="side-menu-icon logout-icon-s" />
              <span className="nav-text">Log Out</span>
            </button>
          </li>
        </ul>
      </div>
    </CommonSidebar>
  );
};

export default AdminMenu;
