import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "axios";
// import { AxiosResponse } from "axios";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
// import {greet} from "support/api";

import support_response from "../../../support/support_response";

import service from "./websiteService";

export const adminWebsiteDefaultUpdate = createAsyncThunk(
  "adminWebsiteDefaultUpdate",
  async (args, thunkAPI) => {
    try {
      return await service.adminWebsiteDefaultUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminWebsiteDefaultShow = createAsyncThunk(
  "adminWebsiteDefaultShow",
  async (args, thunkAPI) => {
    try {
      return await service.adminWebsiteDefaultShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
const initialState = {
  data: {},

  websites: {
    data: [],
  },
  website: {},

  loading: false,
  isSuccess: false,
  updating: false,
};

export const adminWebsiteSlice = createSlice({
  name: "adminWebsite",
  initialState,
  reducers: {},
  extraReducers: {
    [adminWebsiteDefaultShow.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [adminWebsiteDefaultShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      state.website = payload.data.website;

      state.loading = false;
      state.isSuccess = true;
    },
    [adminWebsiteDefaultShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [adminWebsiteDefaultUpdate.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [adminWebsiteDefaultUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.website = payload.data.website;
      state.loading = false;
      state.isSuccess = true;
    },
    [adminWebsiteDefaultUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = adminWebsiteSlice.actions;

export default adminWebsiteSlice;
