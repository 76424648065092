import "./CustomerLogin.css";

import darkLogoEaas from "assets/images/logo/dark-logo-eaas.png";
import { useDispatch } from "react-redux/es/index";

import React, { useState } from "react";
import { showAlertToast } from "features/alert/toastSlice";
import { Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { resetPassword, sendEmail } from "features/auth/accountPasswordSlice";

const ForgotPasswordCustomer = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const email = urlParams.get("email");
  const name = urlParams.get("name");
  const forgotPassword = () => {
    let action;

    if (params.action === "reset") {
      action = resetPassword({
        email: email,
        password: password,
        password_confirmation: confirmPassword,
        token: token,
      });
    } else {
      action = sendEmail({
        email: username,
      });
    }

    dispatch(action).then(function (response) {
      let status,
        message = "";
      if (response.error && response.error.message === "Rejected") {
        status = "error";
        message = response.payload;
      } else {
        status = "success";
        message = response.payload.message;
        setTimeout(() => {
          if (params.action === "reset") {
            navigate("/login-customer");
          }
        }, 3000);
      }
      dispatch(
        showAlertToast({
          status: status,
          message: message,
        })
      );
    });
  };

  return (
    <div className="background-customer mobile-height">
      {/* Logo */}
      <div className="ec-header-login">
        <div className="header-logo mt-2 ml-5">
          <a href="/" id="a-logo">
            <img
              src={darkLogoEaas}
              alt="Site Logo"
              style={{
                width: "143px",
                height: "40px",
              }}
            />
            <img
              className="dark-logo"
              src={darkLogoEaas}
              alt="Site Logo"
              style={{ display: "none", width: "143px", height: "45px" }}
            />
          </a>
        </div>
      </div>
      {/* content */}
      <div className="ec-page-content section-space-ptb-100">
        <div className="container mt-5 mobile-width">
          <div className="section-title">
            <div className="text-center full-text-layout">
              {" "}
              <h1 className="fw-600" style={{ color: "#fff" }}>
                Reset Your Password
              </h1>
              {params.action === "reset" && token ? (
                <p className="sub-title text-white line-height-21">
                  Hello, {name} your email has been verified. Please input your
                  new password.
                </p>
              ) : (
                <p className="sub-title line-height-21">
                  Please write down your username or email. You should receive
                  an email to the associated address in the following 5 minutes.
                  Click on the associated link to proceed with the changes.
                </p>
              )}
            </div>
          </div>

          <div className="ec-login-wrapper">
            <div className="ec-login-container">
              <div className="ec-login-form full-text-layout">
                <form
                  novalidate
                  onSubmit={(event) => {
                    event.preventDefault();
                    forgotPassword();
                  }}
                >
                  {params.action === "reset" && token ? (
                    <>
                      <span className="ec-login-wrap">
                        <label>New Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control mb-0"
                          id="input-text-email"
                          placeholder="new password"
                          value={password}
                          onChange={({ target }) => setPassword(target.value)}
                        />
                      </span>
                      <span className="ec-login-wrap mt-2">
                        <label>Confirmation of New Password</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control mb-0"
                          id="input-text-email"
                          placeholder="confirmation of new password"
                          value={confirmPassword}
                          onChange={({ target }) =>
                            setConfirmPassword(target.value)
                          }
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="ec-login-wrap">
                        <label>Username</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control mb-0"
                          id="input-text-email"
                          placeholder="email"
                          value={username}
                          onChange={({ target }) => setUsername(target.value)}
                        />
                      </span>
                    </>
                  )}

                  <span className="ec-login-wrap ec-login-btn margin-top-20">
                    <button
                      id="btn-confirm"
                      type="submit"
                      className="btn btn-primary btn-block"
                    >
                      Confirm
                    </button>
                  </span>
                  {params.action !== "reset" && (
                    <span className="ec-login-wrap ec-login-fp margin-top-20 text-center">
                      <Link
                        to="/login-supplier"
                        id="a-back-login"
                        className="sub-title  text-decoration-underline"
                        style={{ color: "#fff" }}
                      >
                        Bring me back to the login page
                      </Link>
                    </span>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordCustomer;
