import api from "../../support/support_api";

const assignmentStore = async (params) => {
  const response = await api.axiosPost(`/assignments`, params);

  return response.data;
};

const assignmentAddOrUpdate = async (params) => {
  const response = await api.axiosPost(`/assignments/add-or-update`, params);

  return response.data;
};

const assignmentAcceptOrDecline = async (params) => {
    const response = await api.axiosPatch(`/assignments/${params.assignment_id}/re-assignment-request-accept-or-decline`);
    return response.data
}

const assignmentUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/assignments/${params.assignment_id}`,
    params
  );

  return response.data;
};

const assignmentToolsAddOrUpdate = async (params) => {
  const response = await api.axiosPost(
    `/assignments/${params.assignment_id}/tools/add-or-update`,
    params
  );

  return response.data;
};

const assignmentActivitiesAddOrUpdate = async (params) => {
  const response = await api.axiosPost(
    `/assignments/${params.assignment_id}/activities/add-or-update`,
    params
  );

  return response.data;
};

const assignmentToolsRetrieve = async (params) => {
  const response = await api.axiosGet(
    `/assignments/${params.assignment_id}/tools/retrieve`,
    params,
    false
  );

  return response.data;
};

const assignmentActivitiesRetrieve = async (params) => {
  const response = await api.axiosGet(
    `/assignments/${params.assignment_id}/activities/retrieve`,
    params,
    false
  );

  return response.data;
};

const assignmentRetrieve = async (params) => {
  const response = await api.axiosGet(`/assignments/retrieve`, params, false);

  return response.data;
};

const assignmentSaveCustomerBooking = async (params) => {
  const response = await api.axiosPatch(
    `/assignments/${params.assignment_id}/save-customer-booking`,
    params,
    false
  );

  return response.data;
};

const assignmentShow = async (params) => {
  const response = await api.axiosGet(
    `/assignments/${params.assignment_id}`,
    params,
    false
  );

  return response.data;
};

const assignmentConfirmed = async (params) => {
  const response = await api.axiosPatch(
    `/assignments/${params.assignment_id}/confirmed`,
    params
  );

  return response.data;
};

const assignmentReAssignmentRequest = async (params) => {
  const response = await api.axiosPost(
    `/assignments/${params.assignment_id}/re-assignment-request`,
    params
  );

  return response.data;
};
const assignmentToolsUpdateSelectAll = async (params) => {
  const response = await api.axiosPatch(
    `/assignments/${params.assignment_id}/tools/select-all`,
    params
  );

  return response.data;
};

const assignmentService = {
    assignmentConfirmed,
    assignmentSaveCustomerBooking,
    assignmentShow,
    assignmentStore,
    assignmentUpdate,
    assignmentRetrieve,
    assignmentAddOrUpdate,
    assignmentToolsAddOrUpdate,
    assignmentActivitiesAddOrUpdate,
    assignmentToolsRetrieve,
    assignmentActivitiesRetrieve,
    assignmentReAssignmentRequest,
    assignmentToolsUpdateSelectAll,
    assignmentAcceptOrDecline,

}

export default assignmentService;
  
