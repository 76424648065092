import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";

import service from "./bookingParameterService";

export const bookingParameterIndex = createAsyncThunk(
  "admin/booking-parameters/index",
  async (args, thunkAPI) => {
    try {
      return await service.bookingParameterIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const bookingParameterUpdateMultiple = createAsyncThunk(
  "admin/booking-parameters/index",
  async (args, thunkAPI) => {
    try {
      return await service.bookingParameterUpdateMultiple(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: [],
  loading: false,
  isSuccess: false,
  updating: false,
};

export const bookingParameterSlice = createSlice({
  name: "bookingParameter",
  initialState,
  reducers: {
    //
  },
  extraReducers: {
    [bookingParameterIndex.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [bookingParameterIndex.fulfilled]: (state, { payload }) => {
      const sortedData = payload.data.booking_parameters.data.sort((a, b) => {
        return a.module_id - b.module_id;
      });
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: false,
        data: sortedData,
      };
    },
    [bookingParameterIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = bookingParameterSlice.actions;

export default bookingParameterSlice;
