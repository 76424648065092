import api from "../../support/support_api";

const sendEmail = async (params) => {
  const response = await api.axiosPost(`/password/forgot`, params);
  return response.data;
};

const resetPassword = async (params) => {
  const response = await api.axiosPost(`/password/reset`, params);
  return response.data;
};

const changePassword = async (params) => {
  const response = await api.axiosPost(`/users/update-password`, params);
  return response.data;
};
const resendVerification = async (params) => {
  const response = await api.axiosPost(`/email/verify/resend`, params);

  return response.data;
};

const verifyEmail = async (params) => {
  const response = await api.axiosPost(
    `/email/verify/${params.id}/${params.hash}?expires=${params.expires}&signature=${params.signature}`,
    params
  );

  return response.data;
};
const accountService = {
  sendEmail,
  changePassword,
  resetPassword,
  verifyEmail,
  resendVerification,
};

export default accountService;
