import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";

import support_response from "../../support/support_response";
import  machineService from "./checkService";

export const checkUpdate = createAsyncThunk(
    "check/update",
    async (args, thunkAPI) => {
        try {
            return await machineService.checkUpdate(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)


const initialState = {
    data: {},
    model: {},
    check: {},
    errors: {},
    // updating: false,
    loading: false,
    isSuccess: false,
    updating: false,
}

export const checkSlice = createSlice({
    name: 'check',
    initialState,
    reducers: {
    },
    extraReducers : {
        [checkUpdate.pending]:(state, {payload}) => {
            state.data = {};
            state.updating=true;
            state.loading=true;
            state.isSuccess = false;
        },
        [checkUpdate.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.updating =false;

            if(payload.data?.check) {
                state.model = payload.data.check;
            }

            state.loading=false;
            state.isSuccess = true;
        },
        [checkUpdate.rejected]:(state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const {

} = checkSlice.actions

export default checkSlice;