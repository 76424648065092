import secureLocalStorage from "react-secure-storage";
import {hasPermission} from "../components/RoleHelper";
import PERMISSIONS from "../constants/permissions";
export function parse_query_string(query) {
  const vars = query.split("&");
  let query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair.shift());
    var value = decodeURIComponent(pair.join("="));
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = value;
      // query_string[key] = value;
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], value];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(value);
    }
  }
  return query_string;
}

export function get_current_query_string() {
  let query = window.location.search.substring(1);
  let qs = parse_query_string(query);

  return qs;
}

export function composer_url(url) {
  let user = JSON.parse(secureLocalStorage.getItem("user"));

  let roleName = user.data.user.role_active.name;

  if (url === "USE_ROLE") {
    return roleName;
  }

  const {
    pathname,
    //host, hostname, href, origin,  port, protocol, search
  } = window.location;

  if (pathname.indexOf(roleName) > -1) {
    url = "/" + url;
  } else {
    url = "/" + roleName + "/" + url;
  }

  return url;
}

export function get_role_name() {
  const user = JSON.parse(secureLocalStorage.getItem("user"));

  if (user) {
    let roleName = user.data.user.role_active.name;



    if(hasPermission(user.data.user, PERMISSIONS.PERMISSION_CAN_DO_ADMIN)) {
      return '/admin';
    }
    else if(hasPermission(user.data.user, PERMISSIONS.PERMISSION_CAN_DO_SUPERADMIN)) {
        return '/superadmin';
    }
    else if(hasPermission(user.data.user, PERMISSIONS.PERMISSION_CAN_DO_SUPERHERO)) {
        return '/super-hero';
    }

    return "/home";

    // return "/" + roleName;
    // return "/evaluator";
  }

  return null;
}
export function get_role_name_plain_text() {
  const user = JSON.parse(secureLocalStorage.getItem("user"));

  if (user) {
    return user.data.user.role_default.name;
  }

  return null;
}

export function get_role_id() {
  const user = JSON.parse(secureLocalStorage.getItem("user"));
  return user?.data.user.role_default.id ?? null;
}
export function get_role_name_text() {
  const user = JSON.parse(secureLocalStorage.getItem("user"));
  return user?.data.user.role_default.name ?? null;
}

export function set_current_query_string_filter(query) {
  const params = new URLSearchParams();

  params.append("searchBy", query.searchBy);
  params.append("filter", query.selectedFilter);
  params.append("search", query.input);

  return params;
}

export function set_print() {
  // intended for machine passport only
  const queryParameters = new URLSearchParams(window.location.search);
  const print = queryParameters.get("print");

  if (print) {
    window.print();
  }
}
