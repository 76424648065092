import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiCartOutline } from "@mdi/js";
import { get_role_name } from "support/support_url";
import darkLogoEaas from "assets/images/logo/dark-logo-eaas.png";
import logoEaas from "assets/images/logo/logo-eaas.png";
import { getCurrentUserInfo } from "../../../features/auth/authSlice";
import styles from "./WebsiteHeader.module.css";
// import Tooltip from "../../ui/tooltip/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { updateOrderStore } from "features/order-detail/orderDetailSlice";
import { orderPending } from "features/order/orderSlice";
import support_device from "support/support_device";
const WebsiteHeader = () => {
  const dispatch = useDispatch();
  const selectedMenu = window.location.pathname.split("/")[1];
  const navigate = useNavigate();

  const reduxOrder = useSelector((state) => state.order);
  const reduxOrderDetail = useSelector((state) => state.orderDetail);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { isRegistered } = useSelector((state) => state.register);
  const [showTooltip, setShowTooltip] = useState(false);
  const target = useRef(null);
  useEffect(() => {
    dispatch(getCurrentUserInfo());
    dispatch(
      orderPending({
        device_id: support_device.getBrowserId(),
      })
    );
  }, []);

  useEffect(() => {
    if (
      reduxOrder.order?.details?.length >= 1 &&
      reduxOrderDetail.showCartOverlay
    ) {
      setShowTooltip(true);
      setTimeout(() => {
        dispatch(updateOrderStore({ showCartOverlay: false }));
        setShowTooltip(false);
      }, 5000);
    }
  }, [reduxOrder.order?.details]);

  return (
    <header className="ec-header">
      <div id="ec-main-menu-desk" className="d-lg-block sticky-nav">
        <div>
          <div className="position-relative">
            <div className="ec-flex">
              <div className="align-self-center pl-2rem">
                <div className="header-logo">
                  <a href="/" id="a-logo">
                    <img
                      src={logoEaas}
                      alt="Site Logo"
                      style={{
                        width: "157px",
                        height: "58px",
                      }}
                    />
                    <img
                      className="dark-logo"
                      src={darkLogoEaas}
                      alt="Site Logo"
                      style={{
                        display: "none",
                        width: "143px",
                        height: "45px",
                      }}
                    />
                  </a>
                </div>
              </div>
              {/* hide for */}
              <div
                id="menutop"
                className="align-self-center"
                style={{ flexGrow: 1 }}
              >
                <div className="position-relative">
                  <div className="row">
                    <div className="d-flex justify-content-between">
                      <div className="ec-main-menu">
                        <ul>
                          <li
                            className={`${
                              selectedMenu === "products" ? "active" : ""
                            }`}
                          >
                            <Link id="link-products" to="/products">
                              {" "}
                              Browse{" "}
                            </Link>
                          </li>
                          <li
                            className={`${
                              selectedMenu === "suppliers" ? "active" : ""
                            }`}
                          >
                            <Link id="link-suppliers" to="/suppliers">
                              {" "}
                              Supplier{" "}
                            </Link>
                          </li>
                          <li
                            className={`${
                              selectedMenu === "how-it-works" ? "active" : ""
                            }`}
                          >
                            <Link id="link-how-it-works" to="/how-it-works">
                              {" "}
                              How it Works{" "}
                            </Link>
                          </li>
                          <li
                            className={`${
                              selectedMenu === "contact-us" ? "active" : ""
                            }`}
                          >
                            <Link id="link-contact-us" to="/contact-us">
                              {" "}
                              Contact{" "}
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="ec-main-menu pr-6">
                        <ul>
                          <li
                            className={styles.shoppingCart}
                            id="li-shopping-cart"
                            onClick={() => navigate("/shopping-cart")}
                          >
                            <Icon
                              path={mdiCartOutline}
                              size={1.5}
                              color={"#FFFFFF"}
                              ref={target}
                            />
                            {reduxOrder.order?.details &&
                            reduxOrder.order?.details?.length > 0 ? (
                              <p>{reduxOrder.order?.details?.length}</p>
                            ) : null}
                            <Overlay
                              target={target.current}
                              show={showTooltip}
                              placement="bottom"
                            >
                              {(props) => (
                                <Tooltip id="overlay-cart" {...props}>
                                  <div className="m-3 rounded-5">
                                    <span className="fw-600">
                                      {" "}
                                      Item added to cart.
                                    </span>
                                    <br />
                                    <Link
                                      id="view-cart"
                                      className="fw-600"
                                      style={{ color: "#FD9801" }}
                                      to="/shopping-cart"
                                    >
                                      View Cart
                                    </Link>
                                  </div>
                                </Tooltip>
                              )}
                            </Overlay>
                          </li>

                          <li>
                            {isLoggedIn || isRegistered ? (
                              <Link id="a-dashboard" to={get_role_name()}>
                                Dashboard
                              </Link>
                            ) : (
                              <Link id="link-login" to="/login-selection">
                                {" "}
                                Login{" "}
                              </Link>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ec-header-bottom d-lg-none">
          <div className="container position-relative">
            <div className="row ">
              <div className="col">
                <div className="header-logo">
                  <a href="/">
                    <img src={logoEaas} alt="Site" />
                    <img
                      className="dark-logo"
                      src={darkLogoEaas}
                      style={{ display: "none" }}
                      alt="dark-logo"
                    />
                  </a>
                </div>
              </div>

              <div className="col">
                <div className="header-search">
                  <form className="ec-btn-group-form" action="#">
                    <button className="submit" type="submit" id={`btn-search`}>
                      <img
                        src={require("assets/images/icons/search.svg").default}
                        className="svg_img header_svg"
                        alt="icon"
                      />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>{" "} */}
      </div>
    </header>
  );
};

export default WebsiteHeader;
