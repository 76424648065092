/**
 * GET|HEAD   api/admin/permissions ...................................................................................................... permissions.index › Api\Admin\PermissionController@index
 * PUT|PATCH  api/admin/permissions/{permission} ......................................................................................... permissions.update › Api\Admin\PermissionController@update
 * GET|HEAD   api/admin/permissions/{permission} ......................................................................................... permissions.show › Api\Admin\PermissionController@show
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";

import service from "./permissionService";


export const adminPermissionIndex = createAsyncThunk(
	"admin/permission/show", async (args, thunkAPI) => {
	try {
		return await service.adminPermissionIndex(args);
	} catch (error) {
		return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
	}
});

export const adminPermissionUpdate = createAsyncThunk(
	"admin/permission/show", async (args, thunkAPI) => {
	try {
		return await service.adminPermissionUpdate(args);
	} catch (error) {
		return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
	}
});

export const adminPermissionShow = createAsyncThunk(
	"admin/permission/show", async (args, thunkAPI) => {
	try {
		return await service.adminPermissionShow(args);
	} catch (error) {
		return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
	}
});

const initialState = {
	data: {},
    permission: {},
    permissions: {
		data: [],
	},
	loading: false,
	isSuccess: false,
	updating: false,
};

export const adminPermissionSlice = createSlice({
	name: "adminPermission",
	initialState,
	reducers: {},
	extraReducers: {
		[adminPermissionShow.pending]: (state, { payload }) => {
			// state.data = {};
			state.updating = true;
			state.loading = true;
			state.isSuccess = false;
		},

		[adminPermissionShow.fulfilled]: (state, { payload }) => {
			state.data = payload;
			state.updating = false;

			if (payload?.data?.permissions) {
				state.permissions = payload.data.permissions;
			}

			if (payload?.data?.permission) {
				state.permission = payload.data.permission;
			}

			state.loading = false;
			state.isSuccess = true;
		},

		[adminPermissionShow.rejected]: (state, { payload }) => {
			state.data = payload;
			state.updating = false;
			state.loading = false;
			state.isSuccess = true;
		},
	},
});

export const {
	//
} = adminPermissionSlice.actions;

export default adminPermissionSlice;
