/***
 * @type {{can_approve_evaluation_machine: string, can_add_machine: string, can_accept_evaluation_machine: string, can_view_machine: string, can_view_all_machines_iot_in_organization: string, can_checkout_machine: string, can_assign_machine: string, can_view_his_own_machines_only_iot: string, can_do_evaluation_machine: string, can_view_enquiries: string, can_do_commissioning_machine_qualification: string, can_book_machine: string, can_do_commissioning_pre_delivery_inspection: string, can_assign_commissioner_machine: string, can_do_commissioning_health_setup: string, can_assign_commissioning_machine_qualification: string, can_edit_machine: string, can_do_commissioning_send_for_acceptance: string, can_do_refurbishment_machine: string, can_assign_commissioning_health_setup: string, can_release_for_leasing_enquiries: string, can_do_commissioning_handover: string, can_view_finance: string, can_delete_machine: string, can_assign_commissioning_pre_delivery_inspection: string, can_assign_evaluator_machine: string, can_re_assign_machine: string, can_do_commissioning: string, can_assign_to_himself_for_enquiries: string, can_approve_assign_refurbisher_machine: string, can_add_to_catalog: string, can_proceed_to_leasing_enquiries: string}}
 *
 * These permissions should be identical to table.permissions
 *
 * NOTE: IF you do some updates here, permissions in the database should be updated as well.
 *
 */

const PERMISSIONS = {
  PERMISSION_CAN_DO_SUPERADMIN: "permission_can_do_super_admin",
  PERMISSION_CAN_DO_ADMIN: "permission_can_do_admin",
  PERMISSION_CAN_DO_OPERATE: "permission_can_do_operate",
  PERMISSION_CAN_DO_MANAGE: "permission_can_do_manage",
  PERMISSION_CAN_DO_EVALUATE: "permission_can_do_evaluate",
  PERMISSION_CAN_DO_REFURBISH: "permission_can_do_refurbish",
  PERMISSION_CAN_DO_COMMISSION: "permission_can_do_commission",
  PERMISSION_CAN_DO_CUSTOMER: "permission_can_do_customer",
  PERMISSION_CAN_DO_SUPERHERO: "permission_can_do_super_hero",

  PERMISSION_CAN_EDIT_MACHINE: "permission_can_edit_machine",
  PERMISSION_CAN_VIEW_MACHINE: "permission_can_view_machine",
  PERMISSION_CAN_DELETE_MACHINE: "permission_can_delete_machine",
  PERMISSION_CAN_RE_ASSIGN_MACHINE: "permission_can_re_assign_machine",
  PERMISSION_CAN_ASSIGN_REFURBISHER: "permission_can_assign_refurbisher",
  PERMISSION_CAN_APPROVE_ASSIGN_REFURBISHER:
    "permission_can_approve_assign_refurbisher_machine",

  PERMISSION_CAN_ASSIGN_COMMISSIONER:
    "permission_can_assign_commissioner_machine",
  PERMISSION_CAN_ASSIGN_EVALUATOR: "permission_can_assign_evaluator",
  PERMISSION_CAN_ASSIGN_MACHINE: "permission_can_assign_machine",
  PERMISSION_CAN_ASSIGN_COMMISSIONER_HEALTH_SETUP:
    "permission_can_assign_commissioning_health_setup",
  PERMISSION_CAN_ASSIGN_COMMISSIONER_MACHINE_QUALIFICATION:
    "permission_can_assign_commissioning_machine_qualification",
  PERMISSION_CAN_ASSIGN_COMMISSIONER_PRE_DELIVERY_INSPECTION:
    "permission_can_assign_commissioning_pre_delivery_inspection",
  PERMISSION_CAN_ACCEPT_EVALUATION_MACHINE:
    "permission_can_accept_evaluation_machine",
  PERMISSION_CAN_ADD_MACHINE: "permission_can_add_machine",
  PERMISSION_CAN_ASSIGN_HIMSELF_FOR_ENQURIES:
    "permission_can_assign_to_himself_for_enquiries",
  PERMISSION_CAN_VIEW_ALL_MACHINES_IN_ORGANIZATION:
    "permission_can_view_all_machines_in_organization",
  PERMISSION_CAN_VIEW_HIS_OWN_MACHINES_ONLY_IOT:
    "permission_can_view_his_own_machines_only_iot",
  PERMISSION_CAN_VIEW_MACHINE_PASSPORT_URL:
    "permission_can_view_machine_passport_url",
  PERMISSION_CAN_DO_EVALUATION: "permission_can_do_evaluation_machine",
  PERMISSION_CAN_APPROVE_EVALUATION_MACHINE:
    "permission_can_approve_evaluation_machine",
  PERMISSION_CAN_ADD_TO_CATALOG: "permission_can_add_to_catalog",
  PERMISSION_CAN_UPDATE_MACHINE_FOR_CATALOG: "permission_can_update_machine_for_catalog",

  PERMISSION_CAN_RELEASE_FOR_LEASING_ENQURIES:
    "permission_can_release_for_leasing_enquiries",
  PERMISSION_CAN_DO_REFURBISHMENT_MACHINE:
    "permission_can_do_refurbishment_machine",
  PERMISSION_CAN_DO_COMMISSIONING_MACHINE: "permission_can_do_commissioning",
  PERMISSION_CAN_DO_COMMISSIONING_HEALTH_SETUP:
    "permission_can_do_commissioning_health_setup",
  PERMISSION_CAN_DO_COMMISSIONING_PRE_DELEVIRY_INSPECTION:
    "permission_can_do_commissioning_pre_delivery_inspection",
  PERMISSION_CAN_DO_COMMISSIONING_MACHINE_QUALIFITION:
    "permission_can_do_commissioning_machine_qualification",
  PERMISSION_CAN_DO_COMMISSIONING_HANDOVER:
    "permission_can_do_commissioning_handover",
  PERMISSION_CAN_DO_COMMISSIONING_SEND_FOR_ACCEPTANCE:
    "permission_can_do_commissioning_send_for_acceptance",
  PERMISSION_CAN_BOOK_MACHINE: "permission_can_book_machine",
  PERMISSION_CAN_CHECKOUT_MACHINE: "permission_can_checkout_machine",
  PERMISSION_CAN_PROCEED_TO_LEASING_ENQURIES:
    "permission_can_proceed_to_leasing_enquiries",
  PERMISSION_CAN_VIEW_FINANCE: "permission_can_view_finance",
  PERMISSION_CAN_VIEW_ENQUIRIES: "permission_can_view_enquiries",
  PERMISSION_CAN_REMOVE_FROM_CATALOG: "permission_can_remove_from_catalog",
  PERMISSION_CAN_SCHEDULE: "permission_can_schedule",
  PERMISSION_CAN_RESCHEDULE: "permission_can_reschedule",
  PERMISSION_CAN_DO_REFURBISHMENT_SEND_FOR_ACCEPTANCE:
    "permission_can_do_refurbishment_send_for_acceptance",
  PERMISSION_CAN_CLOSE_ENQURY: "permission_can_close_enquiries",
  PERMISSION_CAN_COMPLETE_ADVISORY: "permission_can_complete_advisory_for_enquiries",
  PERMISSION_CAN_SEND_FOR_QUALIFICATION:
    "permission_can_send_for_qualification",
  PERMISSION_CAN_APPROVE_FOR_INSPECTION_PRE_DELIVERY:
    "permission_can_approve_for_inspection_pre_delivery",
  PERMISSION_CAN_DO_PRE_ACCETANCE_PRE_DELIVERY_INSPECTION:
    "permission_can_do_pre_acceptance_pre_delivery_inspection",
  PERMISSION_CAN_DO_COMMISSIONING_START_HAND_OVER:
    "permission_can_do_commissioning_start_handover",
  PERMISSION_CAN_DO_COMMISSIONING_FINISH_HANDOVER:
    "permission_can_do_commissioning_finish_handover",
  PERMISSION_CAN_DO_COMMISSIONING_SEND_FOR_ACCEPTANCE_FINAL:
    "permission_can_do_commissioning_for_acceptance_final",
  PERMISSION_CAN_INITIATE_START_REFURBISHMENT:
    "permission_can_initiate_start_refurbishment",
  PERMISSION_CAN_EDIT_FROM_CATALOG: "permission_can_edit_from_catalog",
  PERMISSION_CAN_VIEW_ALL_MACHINES_IOT_IN_ORGANIZATION:
    "permission_can_view_all_machines_iot_in_organization",
  PERMISSION_CAN_SEND_TO_TRAINING:
    "permission_can_do_commissioning_for_acceptance_set_training",
};

export default PERMISSIONS;
