/**
 * GET|HEAD        api/admin/roles ........................................................................................................................................................................................... roles.index › Api\Admin\RoleController@index
 * GET|HEAD        api/admin/roles/{role} ...................................................................................................................................................................................... roles.show › Api\Admin\RoleController@show
 * PUT|PATCH       api/admin/roles/{role} .................................................................................................................................................................................. roles.update › Api\Admin\RoleController@update
 * PATCH           api/admin/roles/{role}/update-permissions ................................................................................................................................................................... Api\Admin\RoleController@updatePermissions
 */

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";

import service from "./settingService";

export const getSetting = createAsyncThunk(
  "getSetting",
  async (args, thunkAPI) => {
    try {
      return await service.requestSettings(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  loading: false,
  isSuccess: false,
  updating: false,
};

export const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: {
    [getSetting.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [getSetting.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [getSetting.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = settingSlice.actions;

export default settingSlice;
