import logoEaas from "assets/images/logo/logo-eaas.png";
import fb from "assets/images/icons/fb.png";
import twitter from "assets/images/icons/twitter.png";
import instagram from "assets/images/icons/instagram.png";
import linkedin from "assets/images/icons/linkedin.png";
import playStore from "assets/images/icons/PlayStore.svg";
import appStore from "assets/images/icons/AppStore.svg";
import eaas_privacy_policy from "assets/images/eaas_privacy_policy.pdf";
import eaas_terms_of_use from "assets/images/eaas_terms_of_use.pdf";
import qrCode from "assets/images/icons/qrcode-footer.png";

const WebsiteFooter = () => {
  return (
    <footer className="ec-footer section-space-mt">
      <div className="footer-container">
        <div className="footer-top section-space-footer-p">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="ec-footer-logo mobile-logo-center">
                  <a href="#" id={"a-logo"}>
                    <img src={logoEaas} alt="eaas logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row  mobile-footer-layout-main">
              <div className="col-sm-12 col-lg-4 ec-footer-contact">
                <div className="ec-footer-widget">
                  <div className="ec-footer-links ec-footer-dropdown">
                    <ul className="align-items-center">
                      <li className="ec-footer-link">
                        <a href= 'https://singularityaerotech.asia/eaase/' target={"_blank"} rel="noreferrer">About us</a>
                      </li>
                      <li className="ec-footer-link">
                        <a href= {eaas_terms_of_use} target={"_blank"} rel="noreferrer">Term of Use</a>
                      </li>
                      <li className="ec-footer-link">
                        <a href= {eaas_privacy_policy} target={"_blank"} rel="noreferrer">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                  <span
                    className="mt-5 fw-400"
                    style={{
                      color: "#353535",
                      display: "block",
                      fontSize: "16px",
                    }}
                  >
                    &copy; 2022 SAT.ASIA
                  </span>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 ec-footer-info">
                <div className="ec-footer-widget">
                  <div className="ec-footer-links ec-footer-dropdown">
                    <ul className="align-items-center">
                      <li className="ec-footer-link">
                        <a href="http://www.carrd.co" target={"_blank"}>FAQ</a>
                      </li>
                      <li className="ec-footer-link">
                        <a href="/contact-us">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 ec-footer-account custom-padding-left">
                <div className="row">
                  <div className="ec-footer-social">
                    <img src={appStore} alt="App Store"/>
                    <img src={playStore} alt="Play Store" />
                  </div>
                </div>

                <div className="row">
                  <div className="ec-footer-download">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
