import api from "../../support/support_api";

const orderIndex = async (params) => {
  const response = await api.axiosGet("/orders", params);

  return response.data;
};

const orderShow = async (params) => {
  const response = await api.axiosGet(`/orders/${params.order_id}`, params);

  return response.data;
};

const orderGuestPending = async (params) => {
  const response = await api.axiosGet("/orders/guest/pending", params);

  return response.data;
};

const orderGuestSendInquiry = async (params) => {
  const response = await api.axiosPatch("/orders/guest/send-inquiry", params);

  return response.data;
};
const orderPending = async (params) => {
  const response = await api.axiosGet("/orders/pending", params);

  return response.data;
};
const orderSendInquiry = async (params) => {
  const response = await api.axiosPatch("/orders/send-inquiry", params);

  return response.data;
};
const orderUpdateSelected = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/update-selected`,
    params
  );

  return response.data;
};
const orderLeasingCheckout = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/leasing-checkout`,
    params
  );

  return response.data;
};
const orderLeasingProceed = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/leasing-proceed`,
    params
  );

  return response.data;
};
const orderLeasingRelease = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/leasing-release`,
    params
  );

  return response.data;
};

const orderAssignToAdvisor = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/assign-to-advisor`,
    params
  );

  return response.data;
};
const orderEnquiryClose = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/enquiry-close`,
    params
  );

  return response.data;
};
const orderAdvisoryComplete = async (params) => {
  const response = await api.axiosPatch(
    `/orders/${params.order_id}/advisory-complete`,
    params
  );

  return response.data;
};

const catalogService = {
  orderIndex,
  orderAssignToAdvisor,
  orderShow,
  orderGuestPending,
  orderGuestSendInquiry,
  orderPending,
  orderSendInquiry,
  orderUpdateSelected,
  orderLeasingCheckout,
  orderLeasingProceed,
  orderLeasingRelease,
  orderEnquiryClose,
  orderAdvisoryComplete,
};

export default catalogService;
