import api from "../../../support/support_api";

const costBenefitUpdate = async (params) => {
    const response  = await api.axiosPatch(`/cost-benefits/${params.cost_benefit_id}`, params);

    return response.data;
}

const costBenefitShow = async (params) => {
    const response  = await api.axiosGet(`/cost-benefits/${params.id}`, params, false);

    return response.data;
}
const costBenefitUpdatePurchase = async (params) => {
    const response  = await api.axiosPatch(`/cost-benefits/${params.cost_benefit_id}/update-purchase`, params, false);

    return response.data;
}


const costBenefitService = {
    costBenefitUpdatePurchase,
    costBenefitShow,
    costBenefitUpdate,

}

export default costBenefitService;