import React from "react";
import { Modal } from "react-bootstrap";
import { imagePaths } from "constants/image";
import "./Alert.scss";

const Alert = ({
  qrCode,
  show,
  hideCloseButton = false,
  callback,
  icon,
  title,
  message,
  customActionButtons,
  customBody,
  headerTitle,
  imageAlt,
  secondaryButtonText,
  primaryButtonText,
  secondaryButtonFunction,
  primaryButtonFunction,
  customBackground,
  hideNoButton,
  secondaryButtonDisable = false,
  secondaryButtonCls,
  addressCopyAlert = false,
}) => {
  return (
    <Modal
      id={`modal-${headerTitle}`}
      show={show}
      onHide={callback}
      backdrop="static"
      // keyboard={false}
      // aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        className={customBackground}
        style={addressCopyAlert ? { height: "300px" } : null}
      >
        {hideCloseButton ? (
          <Modal.Header>
            {headerTitle && <Modal.Title>headerTitle</Modal.Title>}
          </Modal.Header>
        ) : (
          <Modal.Header closeButton id="modal-close">
            {headerTitle && <Modal.Title>headerTitle</Modal.Title>}
          </Modal.Header>
        )}

        <Modal.Body className={`p-0 text-center`} style={{ color: "#8A909D" }}>
          <div>
            {qrCode ? (
              <div style={{ margin: "20px" }}>{qrCode}</div>
            ) : (
              <div className="image-center">
                {addressCopyAlert ? null : (
                  <img
                    src={icon || imagePaths.SUCCESS}
                    alt={imageAlt || "modal-photo"}
                  />
                )}
              </div>
            )}

            <p className="modal-content-title h4">{title}</p>
            {customBody ? (
              customBody
            ) : (
              <div className="modal-content-text modal-content-custom-text font-weight-medium customWidth">
                <div className="fw-600 line-height-21"> {message}</div>
              </div>
            )}
          </div>
        </Modal.Body>
        {customActionButtons ? (
          <div className="m-10">{customActionButtons}</div>
        ) : (
          <Modal.Footer>
            <div className="row">
              {secondaryButtonText ? (
                <div className="col">
                  <button
                    disabled={secondaryButtonDisable}
                    id={`btn-secondary-${secondaryButtonText
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                    className={`${
                      secondaryButtonCls
                        ? secondaryButtonCls
                        : "btn btn-primary-white btn-block p-0 "
                    }`}
                    style={{ width: "200px" }}
                    onClick={() => {
                      secondaryButtonFunction
                        ? secondaryButtonFunction()
                        : callback();
                    }}
                  >
                    {secondaryButtonText && secondaryButtonText}
                  </button>
                </div>
              ) : (
                ""
              )}

              <div className="col">
                <button
                  className={`btn btn-primary btn-block p-0 ${
                    hideNoButton === 0 ? "d-none" : ""
                  }`}
                  style={{ width: "200px" }}
                  id={`btn-primary-${primaryButtonText
                    .toLowerCase()
                    .replaceAll(" ", "-")}`}
                  onClick={() => {
                    primaryButtonFunction
                      ? primaryButtonFunction()
                      : callback();
                  }}
                >
                  {primaryButtonText && primaryButtonText}
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </div>
    </Modal>
  );
};

export default Alert;

/**
 *  <Modal.Header>
            {headerTitle && <Modal.Title>headerTitle</Modal.Title>}
        </Modal.Header>
 */
