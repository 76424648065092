import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";
import service from "./customerSurveyService";

export const customerSurveyShow = createAsyncThunk(
  "customer-surveys/index",
  async (args, thunkAPI) => {
    try {
      return await service.customerSurveyShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const customerSurveyUpdate = createAsyncThunk(
  "customer-surveys/index",
  async (args, thunkAPI) => {
    try {
      return await service.customerSurveyUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},

  customer_surveys: {
    data: [],
  },

  customer_survey: {},

  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const customerSurveySlice = createSlice({
  name: "customerSurvey",
  initialState,
  reducers: {
    updateCustomerSurvey(state, action) {
      const { status, indexCategory, indexCustomerSurvey, remark } =
        action.payload;

      let CustomerSurvey = {
        ...state.customer_survey.details[indexCategory].customer_surveys[
          indexCustomerSurvey
        ],
      };
      if (status !== undefined) {
        CustomerSurvey.status = status;
      }
      if (remark !== undefined) {
        CustomerSurvey.remark = remark;
      }

      state.customer_survey.details[indexCategory].customer_surveys[
        indexCustomerSurvey
      ] = CustomerSurvey;
      state.dirty = true;
    },
    updateChecklist(state, action) {
      // TODO: Refactor making the index dynamic
      const { id, value, key, column } = action.payload;
      const fi = state.customer_survey.details[1].customer_surveys.filter(
        (item) => {
          return item.column === column;
        }
      );
      const d = fi[0].detail[0].customer_surveys.filter((item) => {
        return item.id === id;
      });

      d[0][key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [customerSurveyShow.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [customerSurveyShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.customer_survey) {
        state.customer_survey = payload.data.customer_survey;
      }

      if (payload.data?.customer_surveys) {
        state.customer_surveys = payload.data.customer_surveys;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [customerSurveyShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateCustomerSurvey, updateChecklist } =
  customerSurveySlice.actions;

export default customerSurveySlice;
