import api from "../../support/support_api";

const iotCustomers = async (params) => {
    const response  = await  api.axiosGet('/iot/customers', params);

    return response.data;
}

const iotUserMachines = async (params) => {
    const response  = await  api.axiosGet(`/iot/users/${params.user_id}/machines`, params);

    return response.data;
}

const iotMachineShow = async (params) => {
    const response  = await  api.axiosShow(`/iot/machines/${params.machine_id}`, params);

    return response.data;
}

const iotUpdateMachineCurrentOperation = async (params) => {
    const response  = await  api.axiosPatch(`/iot/machines/${params.machine_id}/update-current-operation`, params);

    return response.data;
}

const iotUpdateMachineCurrentSetting = async (params) => {
    const response  = await  api.axiosPatch(`/iot/machines/${params.machine_id}/update-current-setting`, params);

    return response.data;
}

const iotUpdateMachineCurrentSettingIot = async (params) => {
    const response  = await  api.axiosPatch(`/iot/machines/${params.machine_id}/update-current-setting-iot`, params);

    return response.data;
}

const iotMachineGraphShow = async (params) => {
    const response  = await  api.axiosGet(`/iot/machines/${params.machine_id}/graph`, params);

    return response.data;
}

const catalogService = {
    iotCustomers,
    iotUserMachines,
    iotMachineShow,
    iotUpdateMachineCurrentOperation,
    iotUpdateMachineCurrentSetting,
    iotUpdateMachineCurrentSettingIot,
    iotMachineGraphShow,
}

export default catalogService;