import WebsiteHeader from "components/headers/website/WebsiteHeader";
import { WebsiteHeaderMobile } from "components/headers/website/WebsiteHeaderMobile";

import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import "./WebsiteContainer.css";

const WebsiteContainer = () => {
  return (
    <div>
      {/**The right header is displayed based on a screen size. 1000px and smaller for mobile header. */}
      <div className="website-header-main">
        <WebsiteHeader />
      </div>
      <div className="mobile-header-main">
        <WebsiteHeaderMobile />
      </div>
      <div className="ec-content-wrapper">
        <Suspense fallback={<Spinner />}>
          <Outlet />
        </Suspense>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default WebsiteContainer;
