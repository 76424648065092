import api from "../../support/support_api";

// const fileUpload = async (params) => {
//     const response  = await api.axiosPost(`/files/upload/${params.get('model_id')}`, params);
//
//     return response.data;
// }
//
// const fileDelete = async (params) => {
//     const response  = await api.axiosDelete(`/files/${params.file_id}`, params);
//
//     return response.data;
// }
//
// const fileUpdate = async (params) => {
//     const response  = await api.axiosPatch(`/files/${params.file_id}`, params);
//
//     return response.data;
// }

const afterPhotoShow = async (params) => {
    const response  = await api.axiosShow(`/after-photos/${params.after_photo_id}`, params);

    return response.data;
}

const afterPhotoService = {
    // fileUpload,
    // fileDelete,
    // fileUpdate,
    afterPhotoShow
}

export default afterPhotoService;