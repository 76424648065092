import api from "../../support/support_api";

// const machineStore = async (params) => {
//     const response  = await  api.axiosPost('/catalogs', params);
//
//     return response.data;
// }

// const catalogUpdate = async (params) => {
//     const response  = await  api.axiosPatch(`/catalogs/${params.catalog_id}`, params);
//
//     return response.data;
// }
//
// const catalogUploadPhotos = async (params) => {
//     const response  = await  api.axiosPatch(`/catalogs/${params.catalog_id}`, params);
//
//     return response.data;
// }

const machineShow = async (params) => {
  const response = await api.axiosGet(`/machines/${params.machine_id}`, params);

  return response.data;
};

const machineIndex = async (params) => {
  const response = await api.axiosGet(`/machines`, params);
  // const response = await api.axiosGet(`/offline/machines?page_role_name=evaluator`, params);
  // const response = await api.axiosGet(`/offline/machines?page_role_name=commissioner`, params);
  // const response = await api.axiosGet(`/offline/machines?page_role_name=refurbisher`, params);

  return response.data;
};

const machineSearch = async (params) => {
  const response = await api.axiosGet(
    `/machines/search/${params.machine_id}`,
    params
  );

  return response.data;
};

const machineAddOrRetrieve = async (params) => {
  const response = await api.axiosPost(`/machines/add-or-retrieve`, params);

  return response.data;
};
const machineUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}`,
    params
  );

  return response.data;
};
const machineUpdateDetail = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-detail`,
    params
  );

  return response.data;
};
const machineUpdateCompany = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-company`,
    params
  );

  return response.data;
};
const machineEvaluationCompleted = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/evaluation-completed`,
    params
  );

  return response.data;
};
const machineReuseInformation = async (params) => {
  const response = await api.axiosPost(
    `/machines/${params.machine_id}/reuse-information`,
    params
  );

  return response.data;
};

const machineCatalogs = async (params) => {
  const response = await api.axiosGet(`/machines/catalogs`, params);

  return response.data;
};

const machineFilters = async (params) => {
  const response = await api.axiosGet(`/catalogs/filters`, params);

  return response.data;
};

const machineCatalog = async (params) => {
  const response = await api.axiosGet(
    `/machines/${params.machine_id}/catalog`,
    params
  );

  return response.data;
};

const machineUpdateAdditionalInformation = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/technical-additional-information`,
    params
  );
  return response.data;
};

//Newly created
const machineAddToCatalog = async (params) => {
  const response = await api.axiosPost(
    `/machines/${params.machine_id}/add-to-catalog`,
    params
  );
  return response.data;
};
const machineAddOrRemoveCatalog = async (params) => {
  const response = await api.axiosPost(
    `/machines/${params.machine_id}/add-or-remove-catalog`,
    params
  );
  return response.data;
};
const machineVisibleOnWebsite = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/visible-on-website`,
    params
  );
  return response.data;
};

const machineUpdateRoleToRefurbisher = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-role-to-refurbisher`,
    params
  );

  return response.data;
};
const machineShowForm = async (params) => {
  const response = await api.axiosGet(
    `/machines/${params.machine_id}/show-form`,
    params
  );

  return response.data;
};
const machineReuseForms = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/reuse-forms`,
    params
  );

  return response.data;
};
const machineUpdateModuleProcess = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-module-process`,
    params
  );

  return response.data;
};

const machineUpdateSupport = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-support`,
    params
  );

  return response.data;
};

const machineUpdateFeature = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-feature`,
    params
  );

  return response.data;
};

const machineUpdateAddon = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/addon/${params.addon_id}/update`,
    params
  );

  return response.data;
};

const machineUpdatePlan = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/plan/${params.plan_id}/update`,
    params
  );

  return response.data;
};

const machineUpdateFailure = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-failure`,
    params
  );

  return response.data;
};

const machineUpdateField = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/update-field`,
    params
  );

  return response.data;
};

const machinePassportUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/machines/${params.machine_id}/passport/update`,
    params
  );

  return response.data;
};

//
// const catalogSearch = async (params) => {
//     const response  = await api.axiosGet(`/catalogs/search`, params, false);
//
//     return response.data;
// }
//
// const catalogDestroy = async (params) => {
//     const response  = await api.axiosDelete(`/catalogs/${params.catalog_id}`, params);
//
//     return response.data;
// }

const catalogService = {
  machineUpdateRoleToRefurbisher,
  machineEvaluationCompleted,
  machineIndex,
  machineSearch,
  machineAddOrRetrieve,
  machineUpdate,
  machineUpdateCompany,
  machineUpdateDetail,
  machineShow,
  machineReuseInformation,
  machineCatalogs,
  machineCatalog,
  machineShowForm,
  machineReuseForms,
  machineUpdateModuleProcess,
  machineAddToCatalog,
  machineAddOrRemoveCatalog,
  machineVisibleOnWebsite,
  machineUpdateSupport,
  machineUpdateFeature,
  machineUpdateAddon,
  machineUpdatePlan,
  machineUpdateFailure,
  machineUpdateField,
  machinePassportUpdate,
  machineUpdateAdditionalInformation,
  machineFilters,
  // catalogStore, catalogUpdate, catalogUploadPhotos, catalogShow, catalogIndex, catalogDestroy, catalogSearch
};

export default catalogService;
