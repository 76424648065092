import api from "../../support/support_api";

const storeOrDestroy = async (params) => {
  console.log("params", params);
  const response = await api.axiosPatch(
    `/task-favorites/store-or-destroy/${params.machine_id}`,
    params
  );

  return response.data;
};

const machineTasks = async (params) => {
  const response = await api.axiosGet(`/machines/tasks`, params);

  return response.data;
};

const taskFavoriteService = {
  storeOrDestroy,
  machineTasks,
};

export default taskFavoriteService;
