import  api from "../../support/support_api";
import storage from "../../support/support_storage"
const login = async (credentials) => {

   let response = await api.axiosPost('/users/login', credentials)

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}
const loginCustomer = async (credentials) => {

   let response = await api.axiosPost('/users/login-customer', credentials)

    if(response.data) {
        storage.setUser(response.data)
    }

    return response.data;
}
const changeRole = async (params) => {
   let response = await api.axiosPatch(`/users/roles/${params.role_id}/change`, params)

    return response.data;
}

const loginService = {
    login,
    loginCustomer,
    changeRole,
}

export default loginService;