import api from "../../support/support_api";



const orderDetailIndex = async (params) => {
    const response  = await  api.axiosGet('/order-details', params);

    return response.data;
}

const orderDetailStore = async (params) => {
    const response  = await  api.axiosPost('/order-details', params);

    return response.data;
}

const orderDetailShow = async (params) => {
    const response  = await  api.axiosGet(`/order-details/${params.order_detail_id}`, params);

    return response.data;
}

const orderDetailUpdate = async (params) => {
    const response  = await   api.axiosPatch(`/order-details/${params.order_detail_id}`, params);

    return response.data;
}

const orderDetailDestroy = async (params) => {
    const response  = await   api.axiosDelete(`/order-details/${params.order_detail_id}`, params);

    return response.data;
}


const catalogService = {
    orderDetailIndex,
    orderDetailStore,
    orderDetailShow,
    orderDetailUpdate,
    orderDetailDestroy,
}

export default catalogService;