import api from "../../../support/support_api";

const issueFinderDetailUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/issue-finder-details/${params.id}`,
    params
  );

  return response.data;
};

const issueFinderDetailDelete = async (params) => {
  const response = await api.axiosDelete(
    `/issue-finder-details/${params.id}`,
    params
  );

  return response.data;
};

const issueFinderGuideDetailService = {
  issueFinderDetailUpdate,
  issueFinderDetailDelete,
};

export default issueFinderGuideDetailService;
