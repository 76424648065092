import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";
import machineService from "./issueFinderDetailService";

export const issueFinderDetailUpdate = createAsyncThunk(
  "issue-finders-detail/update",
  async (args, thunkAPI) => {
    try {
      return await machineService.issueFinderDetailUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const issueFinderDetailDelete = createAsyncThunk(
  "issue-finders-detail/delete",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.issueFinderDetailDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  // data: {},
  issue_finder_guide_detail: {},
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const issueFinderGuideDetailSlice = createSlice({
  name: "issueFinderGuideDetail",
  initialState,
  reducers: {
    employeeSetErrors(state, action) {
      state.errors = action.payload.errors;
    },
    updateIssueFinderStore(state, action) {
      const { index, key, value } = action.payload;
      // state.issue_finder_guide_detail.details[index][key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [issueFinderDetailUpdate.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [issueFinderDetailUpdate.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.issue_finder_guide_detail = payload.data.issue_finder_guide_detail;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [issueFinderDetailUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  // employeeSetErrors
  updateIssueFinderStore,
} = issueFinderGuideDetailSlice.actions;

export default issueFinderGuideDetailSlice;
