import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";

import taskService from "./taskService";

export const machineTasks = createAsyncThunk(
  "machineTasks",
  async (args, thunkAPI) => {
    try {
      return await taskService.machineTasks(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const storeOrDestroy = createAsyncThunk(
  "task-favorites/index",
  async (args, thunkAPI) => {
    try {
      return await taskService.storeOrDestroy(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  // data: {},
  // task_favorite: {},
  machines: {},
  // notification_detail: {},

  errors: {},

  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
  filter: {},
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    //
  },
  extraReducers: {
    [storeOrDestroy.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [storeOrDestroy.fulfilled]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;
      state.machines.data.map((data) => {
        if (data.id === payload.data.machine.id) {
          data.task_favorite_count_id = payload.data.task_favorite?.id || null;
        }
      });

      state.loading = false;
      state.isSuccess = true;
    },
    [storeOrDestroy.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [machineTasks.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      // state.machines = {};
      // state.machines.data = [];
    },
    [machineTasks.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      state.machines = payload.data.machines;
      state.loading = false;
      state.isSuccess = true;
    },
    [machineTasks.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = taskSlice.actions;

export default taskSlice;
