import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import loginService from "./loginService";

export const login = createAsyncThunk(
  "auth/users/login",
  async (credentials, thunkAPI) => {
    try {
      return await loginService.login(credentials);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginCustomer = createAsyncThunk(
  "auth/users/login",
  async (credentials, thunkAPI) => {
    try {
      return await loginService.loginCustomer(credentials);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changeRole = createAsyncThunk(
  "auth/users/roles/:roleId/change",
  async (credentials, thunkAPI) => {
    try {
      return await loginService.changeRole(credentials);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//
// export const currentUser = createAsyncThunk(
//     "auth/users/login",
//     async (credentials, thunkAPI) => {
//         try {
//             return await loginService.login(credentials);
//         } catch (error) {
//             const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//
//             return thunkAPI.rejectWithValue(message);
//         }
//     }
// )

const initialState = {
  data: {},
  loading: false,
  isSuccess: false,
  currentUser: {},
  currentCompany: {},
  token: "asdasd",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getCurrentUserInfo: (state) => {
      let user = JSON.parse(secureLocalStorage.getItem("user"));

      state.token = user.data.token;
      state.currentUser = user.data.user;
      // state.currentCompany = user.data.company;
    },
  },
  extraReducers: {
    [login.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [login.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },

    [loginCustomer.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [loginCustomer.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [loginCustomer.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },

    [changeRole.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [changeRole.fulfilled]: (state, { payload }) => {
      state.data = payload;

      state.loading = false;
      state.isSuccess = true;
    },
    [changeRole.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { getCurrentUserInfo } = loginSlice.actions;

export default loginSlice;
