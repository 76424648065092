import api from "../../../support/support_api";

const dailyActivityUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/daily-activities/${params.daily_activity_id}`,
    params
  );

  return response.data;
};

const dailyActivityShow = async (params) => {
  const response = await api.axiosGet(
    `/daily-activities/${params.id}`,
    params,
    false
  );

  return response.data;
};

const dailyActivitySectionDetailAdd = async (params) => {
  const response = await api.axiosPost(
    `/daily-activities/${params.id}/section/${params.section_id}/detail-add`,
    params,
    false
  );

  return response.data;
};

const dailyActivitySectionAdd = async (params) => {
  const response = await api.axiosPost(
    `/daily-activities/${params.id}/section-add`,
    params,
    false
  );

  return response.data;
};

const dailyActivityDetailUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/daily-activity-details/${params.id}`,
    params
  );

  return response.data;
};

const dailyActivityDetailDelete = async (params) => {
  const response = await api.axiosDelete(
    `/daily-activity-details/${params.id}`,
    params
  );

  return response.data;
};

const dailyActivityService = {
  dailyActivityUpdate,
  dailyActivityShow,
  dailyActivitySectionDetailAdd,
  dailyActivitySectionAdd,
  dailyActivityDetailUpdate,
  dailyActivityDetailDelete,
};

export default dailyActivityService;
