import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
 
import  fileService from "./afterPhotoService";
import support_response from "../../support/support_response";

export const afterPhotoShow = createAsyncThunk(
    "after-photo/show",
    async (args, thunkAPI) => {
        try { 
            return await fileService.afterPhotoShow(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

// export const fileDelete = createAsyncThunk(
//     "files/:model_id/delete",
//     async (args, thunkAPI) => {
//         try {
//             console.log(" Deleting a file", args );
//
//             return await fileService.fileDelete(args);
//         } catch (error) {
//             return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
//         }
//     }
// )
//
// export const fileUpdate = createAsyncThunk(
//     "files/:model_id/update",
//     async (args, thunkAPI) => {
//         try {
//             console.log(" Deleting a file", args );
//
//             return await fileService.fileUpdate(args);
//         } catch (error) {
//             return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
//         }
//     }
// )
//
// export const fileShow = createAsyncThunk(
//     "files/:model_id/show",
//     async (args, thunkAPI) => {
//         try {
//             console.log(" Deleting a file", args );
//
//             return await fileService.fileShow(args);
//         } catch (error) {
//             return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
//         }
//     }
// )

const initialState = {
    file: {},
    data: {},
    // farm: {},
    // farms: {},
    model: {},
    // machine: {},
    // models: [],
    loading: false,
    isSuccess: false,
    updating: false,
}

export const afterPhotoSlice = createSlice({
    name: 'afterPhoto',
    initialState,
    reducers: {
        // getCurrentUserInfo: (state) => {
        //
        // },
    },
    extraReducers : {
        [afterPhotoShow.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [afterPhotoShow.fulfilled]:(state, {payload}) => {
            state.data = payload;
            state.after_photo = payload.data.after_photo;
            // state.farm = payload.data.farm;
            state.loading=false;
            state.isSuccess = true;
        },
        [afterPhotoShow.rejected]:(state, {payload}) => {
            state.data = payload;
            state.loading = false;
            state.isSuccess = true;
        },

        // [fileDelete.pending]:(state, {payload}) => {
        //     state.data      = {};
        //     state.loading   = true;
        //     state.isSuccess = false;
        // },
        // [fileDelete.fulfilled]:(state, {payload}) => {
        //     state.data      = payload;
        //     state.model     = payload.data.model;
        //     // state.farm      = payload.data.farm;
        //     state.loading   = false;
        //     state.isSuccess = true;
        // },
        // [fileDelete.rejected]:(state, {payload}) => {
        //     state.data       = payload;
        //     state.loading    = false;
        //     state.isSuccess  = true;
        // },
        //
        // [fileUpdate.pending]:(state, {payload}) => {
        //     state.data      = {};
        //     state.loading   = true;
        //     state.isSuccess = false;
        //     state.updating = true;
        // },
        // [fileUpdate.fulfilled]:(state, {payload}) => {
        //     state.data      = payload;
        //     state.file      = payload.data.file;
        //     state.loading   = false;
        //     state.isSuccess = true;
        //     state.updating = false;
        // },
        // [fileUpdate.rejected]:(state, {payload}) => {
        //     state.data       = payload;
        //     state.loading    = false;
        //     state.isSuccess  = true;
        //     state.updating  = false;
        // },
        //
        // [fileShow.pending]:(state, {payload}) => {
        //     state.data      = {};
        //     state.file      = {};
        //     state.loading   = true;
        //     state.isSuccess = false;
        // },
        // [fileShow.fulfilled]:(state, {payload}) => {
        //     state.data      = payload;
        //     state.file      = payload.data.file;
        //     state.loading   = false;
        //     state.isSuccess = true;
        // },
        // [fileShow.rejected]:(state, {payload}) => {
        //     state.data       = payload;
        //     state.loading    = false;
        //     state.isSuccess  = true;
        // },
    }
})

export const {  } = afterPhotoSlice.actions

export default afterPhotoSlice;