// import axios from "axios";

import storage from "../../support/support_storage";
import api from "../../support/support_api";

const logout = async (args) => {
  const response = await api.axiosPost("/users/logout", args);
  if (response.data) {
    storage.setUserClear();
  }

  return response.data;
};

const logoutService = {
  logout,
};

export default logoutService;
