import { React, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiViewDashboardOutline, mdiNaturePeople } from "@mdi/js";

import CommonSidebar from "components/commons/SideBar/CommonSidebar";
import { getCurrentUserInfo } from "../../../../features/auth/authSlice";

import { logout } from "../../../../features/auth/logoutSlice";
import { routesPaths } from "constants/routes.constants";
import { get_role_name } from "../../../../support/support_url";
import { showAlertToast } from "features/alert/toastSlice";
import { resendVerification } from "features/auth/accountPasswordSlice";
import {
  hasViewEnqueryPermission,
  hasViewFinancePermission,
  isCustomer,
} from "components/RoleHelper";
import { get_role_name_plain_text } from "../../../../support/support_url";

const ProfileMenu = () => {
  const dispatch = useDispatch();
  const selectedMenu = window.location.pathname.split("/")[2] || "dashboard";
  const [menu, setMenu] = useState(selectedMenu);
  const { currentUser, currentRole } = useSelector((state) => state.auth);
  // const { currentRole, currentUser} = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const resendEmailVerification = () => {
    dispatch(resendVerification());
  };
  useEffect(() => {
    const menu = window.location.pathname.split("/")[2] || "dashboard";
    if (menu === "machines") {
      setMenu("dashboard");
    } else {
      setMenu(menu);
    }
  }, [window.location.pathname]);
  const submitHandler = (event) => {
    var roleName = get_role_name_plain_text();
    dispatch(logout()).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        dispatch(
          showAlertToast({
            status: "error",
            message: response.payload,
          })
        );
      } else {
        if (roleName === "customer") {
          window.location = "/login-customer";
        } else {
          window.location = "/login-supplier";
        }
      }
    });

    // event.preventDefault();
  };

  useEffect(() => {
    dispatch(getCurrentUserInfo());
  }, []);

  return (
    <CommonSidebar>
      <div className="aside-user d-flex align-items-sm-center justify-content-center py-3">
        <div
          className="symbol symbol-50px cursor-pointer"
          onClick={() =>
            navigate(
              get_role_name() +
                `/settings/users/${currentUser.id}/general-information`
            )
          }
        >
          {currentUser.photo ? (
            <img
              src={currentUser.photo.url}
              alt="user"
              className="rounded-image"
            />
          ) : (
            <img src={require("assets/img/user/Ellipse 1.png")} alt="user" />
          )}
        </div>

        <div className="aside-user-info flex-row-fluid flex-wrap ms-5">
          <div className="d-flex">
            <div className="flex-grow-1 me-2">
              <span
                className="fs-6 fw-bold user-name cursor-pointer"
                onClick={() =>
                  navigate(
                    get_role_name() +
                      `/settings/users/${currentUser.id}/general-information`
                  )
                }
              >
                {currentUser.name}
              </span>

              <br />
              <span className="user-role">{currentRole.name_display}</span>
              <br />
              <small>{currentUser?.organization?.name_limit}</small>
              {currentUser.email_verified_at === null && (
                <span className=" ">(Unverified)</span>
              )}
              <br />
              {currentUser.email_verified_at === null && (
                <a
                  className="text-danger fw-400"
                  onClick={() => {
                    resendEmailVerification();
                  }}
                >
                  Resend email verification
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="ec-navigation" data-simplebar>
        {/* <!-- sidebar menu --> */}
        <ul className="nav sidebar-inner" id="sidebar-menu">
          {/* <!-- Menu --> */}
          <li className={`${menu === "dashboard" ? "active" : ""}`}>
            <Link
              id="dashboard-link"
              className="sidenav-item-link"
              to={`${get_role_name()}`}
              onClick={() => setMenu("dashboard")}
            >
              <Icon
                className={`${menu !== "dashboard" ? "dashboard" : ""}`}
                path={mdiViewDashboardOutline}
                size={1}
              />
              <span className="nav-text ">Dashboard</span>
            </Link>
          </li>
          <li className={`${menu === "tasks" ? "active" : ""}`}>
            <Link
              id="tasks-link"
              className="sidenav-item-link"
              to={`${get_role_name()}/tasks`}
              // onClick={() => setMenu("tasks")}
            >
              <div
                className={`side-menu-icon task-icon${
                  menu !== "tasks" ? "-s" : ""
                }`}
              />

              <span className="nav-text">Tasks</span>
            </Link>
          </li>

          {hasViewEnqueryPermission(currentUser) && (
            <li className={`${menu === "enquiries" ? "active" : ""}`}>
              <Link
                id="enquiries-link"
                className="sidenav-item-link"
                to={`${get_role_name()}/enquiries`}
                onClick={() => setMenu("enquiries")}
              >
                <div
                  className={`side-menu-icon enquiries-icon${
                    menu !== "enquiries" ? "-s" : ""
                  }`}
                />
                <span className="nav-text">Enquiries</span>
              </Link>
            </li>
          )}

          <li className={`${menu === "notifications" ? "active" : ""} `}>
            <Link
              id="notification-link"
              className="sidenav-item-link"
              to={`${get_role_name()}/notifications`}
              onClick={() => setMenu("notifications")}
            >
              <div
                className={`side-menu-icon notifications-icon${
                  menu === "notifications" ? "-s" : ""
                }`}
              />
              <span className="nav-text">Notifications</span>
            </Link>
          </li>
          <li className={`${menu === "settings" ? "active" : ""}`}>
            <Link
              id="settings-link"
              className="sidenav-item-link"
              to={`${get_role_name()}${routesPaths.SETTINGS_USER}/${
                currentUser.id
              }/general-information`}
              onClick={() => setMenu("settings")}
            >
              <div
                className={`side-menu-icon settings-icon${
                  menu !== "settings" ? "-s" : ""
                }`}
              />
              <span className="nav-text">Settings</span>
            </Link>
          </li>

          {hasViewFinancePermission(currentUser) && (
            <li className={`${menu === "finance" ? "active" : ""}`}>
              <Link
                id="finance-link"
                className="sidenav-item-link"
                to={`${get_role_name()}${routesPaths.FINANCE}/${
                  currentUser.id
                }/subscription-management`}
                onClick={() => setMenu("finance")}
              >
                <div
                  className={`side-menu-icon finance-icon${
                    menu !== "finance" ? "-s" : ""
                  }`}
                />
                <span className="nav-text">Finance</span>
              </Link>
            </li>
          )}
          {/*
          <li className={`${menu === "roles" && "active"}`}>
            <hr className={"border"} />
            <Link
              id="change-role-link"
              className="sidenav-item-link"
              to={`${get_role_name()}${routesPaths.ROLE_CHANGE}`}
            >
              <Icon
                path={mdiNaturePeople}
                size={1}
                color={`${menu === "roles" ? "#fff" : "#C2CFE0"}`}
              />
              <span className="nav-text">Change Role</span>
            </Link>
          </li>
          */}
          <li style={{ marginLeft: "25px", marginTop: "10px" }}>
            <button
              id="logout-btn"
              className="sidenav-item-link btn-logout"
              onClick={() => submitHandler()}
            >
              <div className="side-menu-icon logout-icon-s" />
              <span className="nav-text">Log Out</span>
            </button>
          </li>
        </ul>
      </div>
    </CommonSidebar>
  );
};

export default ProfileMenu;

/**
 * <Link className="sidenav-item-link" to={`${get_role_name()}/settings/`} onClick={()=>setMenu('settings')}>
 */

/**
 * to={`${get_role_name()}${routesPaths.SETTINGS_USER}/${
                currentUser.id
              }`}
 */
