import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";
import service from "./healthSetupService";

export const healthSetupShow = createAsyncThunk(
  "health-setups/index",
  async (args, thunkAPI) => {
    try {
      return await service.healthSetupShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const healthSetupUpdate = createAsyncThunk(
  "health-setups/index",
  async (args, thunkAPI) => {
    try {
      return await service.healthSetupUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},

  health_setups: {
    data: [],
  },

  health_setup: {},

  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const healthSetupSlice = createSlice({
  name: "healthSetup",
  initialState,
  reducers: {
    updateHealthSetup(state, action) {
      const { status, indexCategory, indexHealthSetup, remark } =
        action.payload;

      let healthSetup = {
        ...state.health_setup.details[indexCategory].health_setups[
          indexHealthSetup
        ],
      };
      // TODO: Refactor
      if (status !== undefined) {
        healthSetup.status = status;
      }
      if (remark !== undefined) {
        healthSetup.remark = remark;
      }

      state.health_setup.details[indexCategory].health_setups[
        indexHealthSetup
      ] = healthSetup;
      state.dirty = true;
    },
  },
  extraReducers: {
    [healthSetupShow.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [healthSetupShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.health_setup = payload.data.health_setup;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [healthSetupShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateHealthSetup } = healthSetupSlice.actions;

export default healthSetupSlice;
