import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiBell } from "@mdi/js";
import BellNotification from "pages/notification/BellNotification";
// import { useState } from "react";

import {
  get_role_name,
  get_role_name_plain_text,
} from "../../../support/support_url";

import styling from "./DashboardHeader.scss";
import { machineIndex } from "features/machine/machineSlice";
import { useNavigate } from "react-router-dom";
import STATUS from "constants/machine";
const DashboardHeader = () => {
  const [show, setShow] = useState(false);
  const [bgColor, setBgColor] = useState(false);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [isToSearch, setIsToSearch] = useState(false);
  const selectedTabItem = window.location.pathname.split("/")[3];
  const reduxUserNotification = useSelector(
    (state) => state.adminUser.data?.actions
  );

  const [search, setSearch] = useState("");

  const closeNotification = (e) => {
    setShow(false);
  };

  useEffect(() => {
    if (isToSearch) {
      const getData = setTimeout(() => {
        dispatch(
          machineIndex({
            limit: limit,
            page: 0,
            keyword: search,
            tab: "search",
          })
        ).then((res) => {
          navigate(`${get_role_name()}/machines/search`);
        });
      }, 1000);

      return () => clearTimeout(getData);
    }
  }, [search]);

  useEffect(() => {
    if (get_role_name_plain_text() === "line-manager") {
      setBgColor("header_line_manager");
    } else {
      setBgColor("header_" + get_role_name_plain_text());
    }
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    setIsToSearch(true);
    setSearch(value);
  };

  return (
    <>
      <header className="ec-main-header">
        <nav
          className={`navbar navbar-static-top navbar-expand-lg d-flex justify-content-end ${bgColor}`}
        >
          {/* <button id="sidebar-toggler" className="sidebar-toggle">
            <i className="mdi mdi-toggle-switch-outline"></i>
          </button> */}
          <div className="d-flex position-relative">
            {(selectedTabItem === STATUS.NEW ||
              selectedTabItem === STATUS.INPROGRESS ||
              selectedTabItem === STATUS.DECLINE ||
              selectedTabItem === STATUS.ACTIVE ||
              selectedTabItem === STATUS.NOTIFICATION ||
              selectedTabItem === STATUS.SEARCH ||
              (window.location.pathname.split("/")[1] === "commissioner" &&
                window.location.pathname.split("/").length == 2)) && (
              <div className="search-product">
                <div
                  className="input-group"
                  style={{ marginTop: "0px", marginBottom: "0px" }}
                >
                  <span className="input-group-append">
                    <button
                      className="btn btn-outline-secondary btn-search dashboard-search-btn"
                      type="button"
                    >
                      <i className="ecicon eci-search"></i>
                    </button>
                  </span>
                  <input
                    className="form-control search-form"
                    type="search"
                    id="input-txt-search-user"
                    placeholder="Search"
                    onChange={(e) => handleSearch(e)}
                    style={{ backgroundColor: "#fff" }}
                  />
                </div>
              </div>
            )}

            <span
              id="btn-nofification-bell"
              className="ml-2 mr-4 cursor-pointer"
              onClick={() => setShow(true)}
            >
              <Icon path={mdiBell} size={1.4} color="white" />
              {reduxUserNotification?.unread_notification === 0 ||
              !reduxUserNotification?.unread_notification ? null : (
                <span className="position-absolute rounded-circle bell-dot">
                  <p>{reduxUserNotification?.unread_notification}</p>
                </span>
              )}
            </span>
          </div>
        </nav>
      </header>
      {show ? <BellNotification closeNotification={closeNotification} /> : null}
    </>
  );
};

export default DashboardHeader;
