import api from "../../../support/support_api";

const requestSettings = async (params) => {
  const response = await api.axiosGet("/settings/basics", params);

  return response.data;
};

const settingService = {
  requestSettings,
};

export default settingService;
