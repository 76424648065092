import { configureStore } from "@reduxjs/toolkit";

import authSlice from "../features/auth/authSlice";
import machineSlice from "../features/machine/machineSlice";
import employeeSlice from "../features/employee/employeeSlice";
import fileSlice from "../features/file/fileSlice";
import toolSlice from "../features/tool/toolSlice";
import activitySlice from "../features/activity/activitySlice";
import assignmentSlice from "../features/assignment/assignmentSlice";
import ballscrewSupportSlice from "../features/form/ballscrew-support/ballscrewSupportSlice";
import costBenefitSlice from "../features/form/cost-benefit-analysis/costBenefitSlice";
import dailyActivitySlice from "../features/form/daily-activity/dailyActivitySlice";
import staticAccuracySlice from "../features/form/static-accuracy/staticAccuracySlice";
import issueFinderGuideDetailSlice from "../features/form/issue-finder-detail/issueFinderDetailSlice";
import tabSlice from "../features/tabs/tabSlice";
import completeSlice from "../features/complete/completeSlice";
import afterPhotoSlice from "../features/after-photo/afterPhotoSlice";
import orderSlice from "../features/order/orderSlice";
import orderDetailSlice from "../features/order-detail/orderDetailSlice";
import leasingSlice from "../features/leasing/leasingSlice";
import trainingAttendanceSlice from "../features/form/training-attendance/trainingAttendanceSlice";
import checkSlice from "../features/check/checkSlice";
import notificationSlice from "../features/notification/notificationSlice";
import adminUserSlice from "../features/admin/user/userSlice";
import adminOrganizationSlice from "../features/admin/organization/organizationSlice";
import adminWebsiteSlice from "../features/admin/website/websiteSlice";
import adminAddonSlice from "../features/admin/addon/addonSlice";
import adminBookingParameterSlice from "../features/admin/booking-parameter/bookingParameterSlice";
import adminPlanSlice from "../features/admin/plan/planSlice";
import activityUpgradeSlice from "../features/activity-upgrade/activityUpgradeSlice";
import healthSetupSlice from "../features/health-setup/healthSetupSlice";
import customerSurveySlice from "../features/customer-survey/customerSurveySlice";
import deliveryInspectionSlice from "../features/delivery-inspection/deliveryInspectionSlice";
import adminPermissionSlice from "../features/admin/permission/permissionSlice";
import adminRoleSlice from "../features/admin/role/roleSlice";

import iotSlice from "../features/iot/iotSlice";
import issueFinderSlice from "features/form/issue-finder/issueFinderSlice";
import visualCheckSheetSlice from "features/form/visual-check/visualCheckSlice";
import alertSlice from "features/alert/alertSlice";
import toastSlice from "features/alert/toastSlice";
import planSlice from "features/plan/planSlice";
import thunk from "redux-thunk";
import logger from "redux-logger";
import addonSlice from "features/addon/addonSlice";
import registerSlice from "features/auth/registerSlice";
import accountSlice from "features/auth/accountPasswordSlice";
import { promotionSlice } from "features/admin/promotion/promotionSlice";
import paymentSlice from "features/payment/paymentSlice";
import settingSlice from "features/admin/setting/settingSlice";
import contactSlice from "../features/contact/contactSlice";
import taskSlice from "features/task/taskSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    order: orderSlice.reducer,
    trainingAttendance: trainingAttendanceSlice.reducer,
    // trainingAttendanceDetailSlice : trainingAttendanceDetailSlice.reducer,
    leasing: leasingSlice.reducer,
    orderDetail: orderDetailSlice.reducer,
    machine: machineSlice.reducer,
    task: taskSlice.reducer,
    employee: employeeSlice.reducer,
    tool: toolSlice.reducer,
    activity: activitySlice.reducer,
    assignment: assignmentSlice.reducer,
    file: fileSlice.reducer,
    costBenefitAnalysis: costBenefitSlice.reducer,
    ballscrewSupport: ballscrewSupportSlice.reducer,
    issueFinderGuideDetail: issueFinderGuideDetailSlice.reducer,
    issueFinder: issueFinderSlice.reducer,
    staticAccuracy: staticAccuracySlice.reducer,
    visualCheckSheet: visualCheckSheetSlice.reducer,
    dailyActivity: dailyActivitySlice.reducer,
    check: checkSlice.reducer,
    tab: tabSlice.reducer,
    complete: completeSlice.reducer,
    notification: notificationSlice.reducer,
    afterPhoto: afterPhotoSlice.reducer,
    activityUpgrade: activityUpgradeSlice.reducer,
    healthSetup: healthSetupSlice.reducer,
    customerSurvey: customerSurveySlice.reducer,
    deliveryInspection: deliveryInspectionSlice.reducer,
    iot: iotSlice.reducer,
    register: registerSlice.reducer,

    // admin redux
    adminPermission: adminPermissionSlice.reducer,
    adminRole: adminRoleSlice.reducer,
    adminUser: adminUserSlice.reducer,
    adminOrganization: adminOrganizationSlice.reducer,
    adminWebsite: adminWebsiteSlice.reducer,
    adminPlan: adminPlanSlice.reducer,
    adminAddon: adminAddonSlice.reducer,
    adminBookingParameter: adminBookingParameterSlice.reducer,
    alert: alertSlice.reducer,
    toast: toastSlice.reducer,
    plan: planSlice.reducer,
    addon: addonSlice.reducer,
    account: accountSlice.reducer,
    promotion: promotionSlice.reducer,
    payment: paymentSlice.reducer,
    setting: settingSlice.reducer,
    contact: contactSlice.reducer,
    devTools: true,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  // middleware: [thunk, logger],
});
