import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";
import machineService from "./ballscrewSupportService";

export const ballscrewSupportUpdate = createAsyncThunk(
  "ballscrew-supports/show-or-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.ballscrewSupportUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const ballscrewSupportShow = createAsyncThunk(
  "ballscrew-supports/show-or-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.ballscrewSupportShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  // employee: {},
  // employees: {
  //     data: [ ]
  // },
  ballscrew_support: {},
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const ballscrewSupportSlice = createSlice({
  name: "ballscrewSupportBearingClearance",
  initialState,
  reducers: {
    updateBallScrewSupportStore(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.ballscrew_support[key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [ballscrewSupportUpdate.pending]: (state, { payload }) => {
      // state.ballscrew_support = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [ballscrewSupportUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.ballscrew_support = payload.data.ballscrew_support;
      // state.employees = payload.data.employees;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [ballscrewSupportUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateBallScrewSupportStore } = ballscrewSupportSlice.actions;

export default ballscrewSupportSlice;
