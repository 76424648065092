import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";

import promotionService from "./promotionService";

export const adminPromotionIndex = createAsyncThunk(
  "adminPromotionIndex",
  async (args, thunkAPI) => {
    try {
      return await promotionService.adminPromotionIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminPromotionStore = createAsyncThunk(
  "adminPromotionStore",
  async (args, thunkAPI) => {
    try {
      return await promotionService.adminPromotionStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminPromotionShow = createAsyncThunk(
  "adminPromotionShow",
  async (args, thunkAPI) => {
    try {
      return await promotionService.adminPromotionShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminPromotionUpdate = createAsyncThunk(
  "adminPromotionUpdate",
  async (args, thunkAPI) => {
    try {
      return await promotionService.adminPromotionUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminPromotionDelete = createAsyncThunk(
  "promotion/index",
  async (args, thunkAPI) => {
    try {
      return await promotionService.adminPromotionDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  promotion: [],
  loading: false,
  isSuccess: false,
  updating: false,
};

export const promotionSlice = createSlice({
  name: "promotionSlice",
  initialState,
  reducers: {
    //
  },
  extraReducers: {
    [adminPromotionIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [adminPromotionIndex.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: false,
        promotion: payload.data.promotions.data,
      };
    },
    [adminPromotionIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = promotionSlice.actions;

export default promotionSlice;
