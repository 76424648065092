import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import support_response from "../../support/support_response";
import addonService from "./addonService";

export const addonIndex = createAsyncThunk(
  "addons/index",
  async (args, thunkAPI) => {
    try {
      return await addonService.addonIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const addonUpdateParams = createAsyncThunk(
  "plans/index",
  async (args, thunkAPI) => {
    try {
      return await addonService.addonUpdateParams(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  addons: {
    data: [],
  },
  addon: {},

  errors: {},

  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const addonSlice = createSlice({
  name: "addons",
  initialState,
  reducers: {
    reduxSetErrors(state, action) {
      state.errors = action.payload.errors;
    },
  },
  extraReducers: {
    [addonIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [addonIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.addons) {
        state.addons = payload.data.addons;
      }

      if (payload.data?.addon) {
        state.addons = payload.data.addon;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [addonIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { reduxSetErrors } = addonSlice.actions;

export default addonSlice;
