import React from "react";

const CommonButtonSecondaryWhite = ({
  label,
  onclick,
  id,
  type,
  disabled,
  className,
  style,
}) => {
  id = `btn-${
    id?.toLowerCase().replaceAll(" ", "-") ||
    label.toLowerCase().replaceAll(" ", "-")
  }`;
  return (
    <button
      style={style}
      disabled={disabled ? disabled : false}
      type={type || "button"}
      className={`btn ${
        className ? className : "btn-primary-white"
      } btn-block p-0`}
      onClick={onclick}
      id={id}
    >
      {label}
    </button>
  );
};

export default CommonButtonSecondaryWhite;
