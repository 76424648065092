import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UNSAFE_DataRouterStateContext } from "react-router-dom";

import support_response from "../../../support/support_response";
import machineService from "./dailyActivityService";

export const dailyActivityUpdate = createAsyncThunk(
  "visual-checks/add-or-update-or-show",
  async (args, thunkAPI) => {
    try {
      return await machineService.dailyActivityUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const dailyActivityShow = createAsyncThunk(
  "visual-checks/add-or-update-or-show",
  async (args, thunkAPI) => {
    try {
      return await machineService.dailyActivityShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const dailyActivitySectionDetailAdd = createAsyncThunk(
  "visual-checks/add-or-update-or-show",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.dailyActivitySectionDetailAdd(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const dailyActivitySectionAdd = createAsyncThunk(
  "visual-checks/add-or-update-or-show",
  async (args, thunkAPI) => {
    try {
      return await machineService.dailyActivitySectionAdd(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const dailyActivityDetailUpdate = createAsyncThunk(
  "daily-activity-details/update",
  async (args, thunkAPI) => {
    try {
      return await machineService.dailyActivityDetailUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const dailyActivityDetailDelete = createAsyncThunk(
  "daily-activity-details/delete",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.dailyActivityDetailDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  daily_activity: {},

  errors: {},
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const dailyActivitySheetSlice = createSlice({
  name: "dailyActivitySheet",
  initialState,
  reducers: {
    resetDailyActivityStore(state, action) {
      const { sectionId, id } = action.payload;
      state.daily_activity.details.map((detail) => {
        if (detail.id === sectionId) {
          detail.details.map((d) => {
            if (d.id === id) {
              delete d.dirty;
            }
          });
        }
      });
    },
    updateDailyActivityStore(state, action) {
      const { sectionId, id, key, value } = action.payload;
      state.daily_activity.details.map((detail) => {
        if (detail.id === sectionId) {
          detail.details.map((d) => {
            if (d.id === id) {
              d[key] = value;
              d.dirty = true;
            }
          });
        }
      });
      state.dirty = true;
    },
  },
  extraReducers: {
    [dailyActivityUpdate.pending]: (state, { payload }) => {
      return {
        ...state,
        updating: true,
        loading: true,
        isSuccess: false,
        // daily_activity: {},
      };
    },
    [dailyActivityUpdate.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        daily_activity: payload.data.daily_activity,
      };
    },
    [dailyActivityUpdate.rejected]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
      };
    },
    // details
    [dailyActivityDetailUpdate.pending]: (state, { payload }) => {
      return {
        ...state,
        updating: true,
        loading: true,
        isSuccess: false,
      };
    },
    [dailyActivityDetailUpdate.fulfilled]: (state, { payload }) => {
      state.daily_activity.details.filter((detail) => {
        detail.details.filter((d) => {
          if (d.id === payload.data.daily_activity_detail.id) {
            d = payload.data.daily_activity_detail;
          }
        });
      });

      state.update = false;
      state.loading = false;
      state.isSuccess = true;

      state.dirty = false;
    },
    [dailyActivityDetailUpdate.rejected]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        daily_activity: {},
      };
    },
  },
});

export const {
  updateDailyActivityStore,
  updateDailyActivityDetailStore,
  resetDailyActivityStore,
} = dailyActivitySheetSlice.actions;

export default dailyActivitySheetSlice;
