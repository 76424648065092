import api from "../../support/support_api";

const deliveryInspectionUpdateDetail = async (params) => {
    const response = await api.axiosPatch(`/delivery-inspections/details/${params.delivery_inspection_detail_id}`, params);

    return response.data;
}

const deliveryInspectionAddDetail = async (params) => {
    const response = await api.axiosPost(`/delivery-inspections/${params.delivery_inspection_id}/categories/${params.category_id}/add-detail`, params);

    return response.data;
}

const deliveryInspectionShow = async (params) => {
    const response = await api.axiosGet(`/delivery-inspections/${params.delivery_inspection_id}`, params);

    return response.data;
}

const deliveryInspectionAddDetailDetail = async (params) => {
    const response = await api.axiosPost(`/delivery-inspections/details/${params.delivery_inspection_detail_id}/add-detail`, params);

    return response.data;
}

const deliveryInspectionUpdateDetailDetail = async (params) => {
    const response = await api.axiosPatch(`/delivery-inspections/details/update-detail/${params.delivery_inspection_detail_sub_id}`, params);

    return response.data;
}

const deliveryInspectionUpdateParams = async (params) => {
    const response = await api.axiosPatch(`/delivery-inspections/${params.delivery_inspection_id}/update-params`, params);

    return response.data;
}


const deliveryInspectionService = {
    deliveryInspectionUpdateDetail,
    deliveryInspectionAddDetail,
    deliveryInspectionShow,
    deliveryInspectionAddDetailDetail,
    deliveryInspectionUpdateDetailDetail,
    deliveryInspectionUpdateParams,
}

export default deliveryInspectionService;