import LandingPage from "../../components/landing/LandingPage";
import "pages/home/HomePage.module.css";
import WebsiteFooter from "components/footers/website/WebsiteFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyEmail } from "features/auth/accountPasswordSlice";
import { showAlertToast } from "features/alert/toastSlice";
import React, { useEffect } from "react";
import { get_role_name } from "support/support_url";
import secureLocalStorage from "react-secure-storage";
const HomePage = () => {
  const params = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const signature = urlParams.get("signature");
  const expires = urlParams.get("expires");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.action === "verify") {
      dispatch(
        verifyEmail({
          signature: signature,
          expires: expires,
          hash: params?.hash,
          id: params?.id,
        })
      ).then(function (response) {
        let status,
          message = "";
        if (response.error && response.error.message === "Rejected") {
          status = "error";
          message = response.payload;
        } else {
          status = "success";
          message = response.payload.message;
          let user = JSON.parse(secureLocalStorage.getItem("user"));
          user.data.user = response.payload.data.user;
          secureLocalStorage.setItem("user", JSON.stringify(user));
          setTimeout(() => {
            navigate(`${get_role_name()}/machines`);
          }, 1000);
        }
        dispatch(
          showAlertToast({
            status: status,
            message: message,
          })
        );
      });
    }
    // http://localhost:3000/email/verify/33/e5dd4d22be86bccb287a5746c26fa6a367484513?expires=1676356364&signature=e69998ad828a0288d5c57eca061a8b81fab13ff226ba1f515dee2e80a6cb64f4
  }, []);
  return (
    <React.Fragment>
      <LandingPage></LandingPage>
      <WebsiteFooter />
    </React.Fragment>
  );
};

export default HomePage;
