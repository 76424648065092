import api from "../../../support/support_api";

const adminAddonIndex = async (params) => {
	const response = await api.axiosGet(`/admin/addons`, params);

	return response.data;
};
const adminAddonStore = async (params) => {
	const response = await api.axiosPost(`/admin/addons`, params);

	return response.data;
};
const adminAddonShow = async (params) => {
	const response = await api.axiosShow(`/admin/addons/${params.id}`, params);

	return response.data;
};
const adminAddonUpdate = async (params) => {
	const response = await api.axiosPatch(`/admin/addons/${params.id}`, params);

	return response.data;
};
const adminAddonDelete = async (params) => {
	const response = await api.axiosDelete(`/admin/addons/${params.id}`, params);

	return response.data;
};

const adminAddonService = {
	adminAddonIndex,
	adminAddonStore,
	adminAddonShow,
	adminAddonUpdate,
	adminAddonDelete,
};

export default adminAddonService;
