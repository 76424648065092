import React from "react";

const CommonSpinner = () => {
  return (
    <div className="fade modal-backdrop show">
      <div
        className="d-flex justify-content-center"
        style={{ position: "absolute", left: "50%", top: "50%" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default CommonSpinner;
