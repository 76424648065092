import api from "../../support/support_api";

const healthSetupShow = async (params) => {
    const response  = await api.axiosGet(`/health-setups/${params.health_setup_id}`, params);

    return response.data;
}

const healthSetupUpdate = async (params) => {
    const response  = await api.axiosPatch(`/health-setups/${params.health_setup_id}`, params);

    return response.data;
}

const catalogService = {
    healthSetupShow,
    healthSetupUpdate,
}

export default catalogService;