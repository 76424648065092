import api from "../../../support/support_api";

const bookingParameterIndex = async (params) => {
    const response  = await  api.axiosGet('/admin/booking-parameters', params);

    return response.data;
}

const bookingParameterUpdateMultiple = async (params) => {
    const response  = await  api.axiosPatch('/admin/booking-parameters/update-multiple', params);

    return response.data;
}

const adminBookingParameterService = {
    bookingParameterIndex,
    bookingParameterUpdateMultiple,
}

export default adminBookingParameterService;