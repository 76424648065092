export const imagePaths = {
    SUCCESS : require("assets/img/icons/success.png"),
    INFO : require("assets/img/icons/reuse.png"),
    CALENDAR: require("assets/img/icons/calendar-lg.png"),
    ERROR: require("assets/img/icons/incomplete.svg").default,
    QRCODE: require("assets/img/icons/qrcode.png"),
    QR_CODE: require("assets/img/icons/qr-code.png"),
    BLOCKCHAIN: require("assets/img/icons/blockchain.png"),
    BELL: require("assets/img/icons/bell.png"),
    OTHER: ''
}