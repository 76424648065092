import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { axios } from "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";

import support_response from "../../../support/support_response";

import service from "./organizationService";
import api from "../../../support/support_api";

export const adminOrganizationIndex = createAsyncThunk(
    "admin/organizations/index",
    async (args, thunkAPI) => {
        try {
            return await service.adminOrganizationIndex(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminOrganizationStore = createAsyncThunk(
    "admin/organizations/index",
    async (args, thunkAPI) => {
        try {
            return await service.adminOrganizationStore(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminOrganizationShow = createAsyncThunk(
    "admin/organizations/index",
    async (args, thunkAPI) => {
        try {
            return await service.adminOrganizationShow(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminOrganizationUpdate = createAsyncThunk(
    "admin/organizations/index",
    async (args, thunkAPI) => {
        try {
            return await service.adminOrganizationUpdate(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminOrganizationDelete = createAsyncThunk(
    "admin/organizations/index",
    async (args, thunkAPI) => {
        try {
            return await service.adminOrganizationDelete(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)



const initialState = {
    data: {},
    organization: { },
    organizations: {
        data: [

        ]
    },

    loading: false,
    isSuccess: false,
    updating: false,
}

export const adminOrganizationSlice = createSlice({
    name: 'adminOrganization',
    initialState,
    reducers: {

    },
    extraReducers: {
        [adminOrganizationIndex.pending]: (state, {payload}) => {
            // state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [adminOrganizationIndex.fulfilled]: (state, {payload}) => {
            state.data = payload;
            state.updating = false;

            if(payload?.data?.organization) {
                state.organization = payload.data.organization;
            }

            if(payload?.data?.organizations) {
                state.organizations       = payload.data.organizations;
            }

            state.loading   = false;
            state.isSuccess = true;
        },
        [adminOrganizationIndex.rejected]: (state, {payload}) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }

})

export const {
    //

} = adminOrganizationSlice.actions

export default adminOrganizationSlice;