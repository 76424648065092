import { useContext } from "react";
import SnackbarContext from "_helpers/snackbar-context";
//

const getErrorBag = (error) => {
  const myArray = error.response.data.message.split(" (and ");
  // snackbarCtx.displayMsg("test", "failure")

  error.response.data.message = myArray[0];
  let data = {
    errors: error.response.data.errors,
    message: error.response.data.message,
    // data:error.response.data,
    status: error.response.status,
    statusText: error.response.statusText,
  };

  if (data.status === 422) {
  }

  return data;
};
const convertToWritableObject = (response) => {
  let myJSON = JSON.stringify(response);
  let obj = JSON.parse(myJSON);

  return obj;
};
const cleanUp = (model) => {
  // const { complete , ...newModel} = model; // remove un related keys for request

  const { complete, checked_by_user, newRequest, machine, ...newModel } = model; // remove un related keys for request

  return newModel;
  // let myJSON = JSON.stringify(response);
  // let obj = JSON.parse(myJSON);
  //
  // return obj
};

//todo - refactor, might be buggy.
const clearTimeouts = () => {
  (function (_W) {
    var cache = [], // will store all timeouts IDs
      _set = _W.setTimeout, // save original reference
      _clear = _W.clearTimeout; // save original reference

    // Wrap original setTimeout with a function
    _W.setTimeout = function (CB, duration, arg) {
      // also, wrap the callback, so the cache reference will be removed
      // when the timeout has reached (fired the callback)
      var id = _set(
        function () {
          removeCacheItem(id);
          CB.apply(null, arguments);
        },
        duration || 0,
        arg
      );

      cache.push(id); // store reference in the cache array

      // id reference must be returned to be able to clear it
      return id;
    };

    // Wrap original clearTimeout with a function
    _W.clearTimeout = function (id) {
      _clear(id);
      removeCacheItem(id);
    };

    // Add a custom function named "clearTimeouts" to the "window" object
    _W.clearTimeouts = function () {
      cache.forEach((n) => _clear(n));
      cache.length = [];
    };

    // removes a specific id from the cache array
    function removeCacheItem(id) {
      var idx = cache.indexOf(id);

      if (idx > -1) cache = cache.filter((n) => n != id);
    }
  })(window);

  window.clearTimeouts();
};
const support_response = {
  clearTimeouts,
  cleanUp,
  getErrorBag,
  convertToWritableObject,
};

export default support_response;
