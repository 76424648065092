import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";
import machineService from "./employeeService";

export const employeeIndex = createAsyncThunk(
  "employee/index",
  async (args, thunkAPI) => {
    try {
      return await machineService.employeeIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  employee: {},
  errors: {},
  loading: false,
  isSuccess: false,
  updating: false,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    employeeSetErrors(state, action) {
      state.errors = action.payload.errors;
    },
  },
  extraReducers: {
    [employeeIndex.pending]: (state, { payload }) => {
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [employeeIndex.fulfilled]: (state, { payload }) => {
      state.employee = payload.data.employees;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [employeeIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { employeeSetErrors } = employeeSlice.actions;

export default employeeSlice;
