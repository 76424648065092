import api from "../../../support/support_api";

// const subscriptionPlanIndex = async (params) => {
//     const response = await api.axiosGet(`/plans`, params);
//
//     return response.data;
// }
//
// const subscriptionPlanAddMachine = async (params) => {
//     const response = await api.axiosPost(`/plans/machine-add-plan/${params.subscription_plan_id}/${params.machine_id}/`);
//
//     return response.data;
// }

const adminPlanIndex = async (params) => {
	const response = await api.axiosGet(`/admin/plans`, params);

	return response.data;
};
const adminPlanStore = async (params) => {
	const response = await api.axiosPost(`/admin/plans`, params);

	return response.data;
};
const adminPlanShow = async (params) => {
	const response = await api.axiosShow(`/admin/plans/${params.id}`, params);

	return response.data;
};
const adminPlanUpdate = async (params) => {
	const response = await api.axiosPatch(`/admin/plans/${params.id}`, params);

	return response.data;
};
const adminPlanDelete = async (params) => {
	const response = await api.axiosDelete(`/admin/plans/${params.id}`, params);

	return response.data;
};

const adminPlanService = {
	adminPlanIndex,
	adminPlanStore,
	adminPlanShow,
	adminPlanUpdate,
	adminPlanDelete,
};

export default adminPlanService;
