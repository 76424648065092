import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";
import machineService from "./visualCheckService";

export const visualCheckUpdate = createAsyncThunk(
  "visual-checks/visualCheckUpdate",
  async (args, thunkAPI) => {
    try {
      return await machineService.visualCheckUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const visualCheckDetailUpdate = createAsyncThunk(
  "visual-check-details/visualCheckDetailUpdate",
  async (args, thunkAPI) => {
    try {
      return await machineService.visualCheckDetailUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const visualCheckShow = createAsyncThunk(
  "visual-checks/visualCheckShow",
  async (args, thunkAPI) => {
    try {
      return await machineService.visualCheckShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const visualCheckDetailAdd = createAsyncThunk(
  "visual-checks/visualCheckDetailAdd",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.visualCheckDetailAdd(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const visualCheckDetailDelete = createAsyncThunk(
  "visual-checks/visualCheckDetailDelete",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.visualCheckDetailDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  visual_check: {},
  errors: {},
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const visualCheckSheetSlice = createSlice({
  name: "visualCheckSheet",
  initialState,
  reducers: {
    updateVisualCheckStore(state, action) {
      const { id, key, value } = action.payload;
      state.visual_check.details.filter((x) => {
        if (x.id === id) {
          x[key] = value;
          x["dirty"] = true;
        }
      });
      state.dirty = true;
    },
    updateVisualCheckDetailStore(state, action) {
      const { id, key, value } = action.payload;
      state.visual_check[key] = value;
      state.dirty = true;
    },
  },
  extraReducers: {
    [visualCheckShow.pending]: (state, { payload }) => {
      // state.visual_check = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [visualCheckShow.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.visual_check = payload.data.visual_check;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [visualCheckUpdate.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.visual_check = payload.data.visual_check;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [visualCheckShow.rejected]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [visualCheckDetailUpdate.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.visual_check.dirty = false;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [visualCheckShow.rejected]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    // visualCheckDetailAdd
    [visualCheckDetailAdd.rejected]: (state, { payload }) => {
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [visualCheckDetailAdd.fulfilled]: (state, { payload }) => {
      state.updating = false;
      state.visual_check.dirty = false;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [visualCheckShow.rejected]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  // employeeSetErrors
  updateVisualCheckStore,
  updateVisualCheckDetailStore,
  deleteVisualCheckDetailStore,
} = visualCheckSheetSlice.actions;

export default visualCheckSheetSlice;
