import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import support_response from "../../support/support_response";
import paymentService from "./paymentService";

export const getBanks = createAsyncThunk("getBanks", async (args, thunkAPI) => {
  try {
    return await paymentService.getBanks(args);
  } catch (error) {
    return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
  }
});

export const processPayment = createAsyncThunk(
  "proceedPaymentx",
  async (args, thunkAPI) => {
    try {
      return await paymentService.processPayment(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const getOperatorPayments = createAsyncThunk(
  "getOperatorPayments",
  async (args, thunkAPI) => {
    try {
      return await paymentService.getOperatorPayments(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const getCustomerPayments = createAsyncThunk(
  "getOperatorPayments",
  async (args, thunkAPI) => {
    try {
      return await paymentService.getCustomerPayments(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  banks: {},
  payments: {},
  payment_details: {},
  payment: {},
  calculationSubscriptions: [],
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: {
    [processPayment.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [processPayment.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: true,
        loading: false,
        isSuccess: true,
        dirty: false,
        data: payload.data,
      };
    },
    [processPayment.rejected]: (state, { payload }) => {
      state.data = payload.data;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [getBanks.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.dirty = true;
    },
    [getBanks.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        dirty: false,
        banks: payload.data.banks,
      };
    },
    [getBanks.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [getOperatorPayments.pending]: (state, { payload }) => {
      state.payments = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [getOperatorPayments.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: true,
        loading: false,
        isSuccess: true,
        dirty: false,
        payments: payload?.data?.payments,
        payment: payload?.data?.payment,
        payment_details: payload?.data?.payment_details,
        calculationSubscriptions: payload?.data?.calculationSubscriptions,
      };
    },
    [getOperatorPayments.rejected]: (state, { payload }) => {
      state.payments = payload.data.payments;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {} = paymentSlice.actions;

export default paymentSlice;
