import api from "../../support/support_api";

const subscriptionPlanIndex = async (params) => {
    const response = await api.axiosGet(`/plans`, params);

    return response.data;
}

const subscriptionPlanUpdateParams = async (params) => {
    const response = await api.axiosPatch(`/plans/update-params/machines/${params.machine_id}`, params);

    return response.data;
}

const subscriptionPlanAddMachine = async (params) => {
    const response = await api.axiosPost(`/plans/machine-add-plan/${params.subscription_plan_id}/${params.machine_id}/`);

    return response.data;
}


const planService = {
    subscriptionPlanIndex,
    subscriptionPlanUpdateParams,
    subscriptionPlanAddMachine,
}

export default planService;