import PERMISSIONS from "constants/permissions";
export function hasPermissionToViewMachine(currentRole, currentUser) {
  return hasViewPermission(currentUser);
}

export function viewOnly(currentUser, machine) {
  return (
    (hasViewPermission(currentUser) &&
      machine.process_key !== "EVALUATION_NEW" &&
      machine.process_key !== "PROCUREMENT_NEW" &&
      machine.process_key !== "MACHINE_REFURBISHMENT_NEW" &&
      machine.process_key !== "LEASING_NEW" &&
      machine.process_key !== "MACHINE_HEALTH_SETUP(IOT)_NEW" &&
      machine.process_key !== "MACHINE_HEALTH_SETUP_(IOT)_NEW" &&
      machine.process_key !== "MACHINE_RETOOLING_NEW" &&
      machine.process_key_step !== "MACHINE_QUALIFICATION_NEW_THREE" &&
      machine.process_key_step !== "MACHINE_QUALIFICATION_NEW_TWO" &&
      machine.process_key_step !== "PRE-DELIVERY_INSPECTION_NEW_TWO" &&
      machine.process_key_step !== "PRE-DELIVERY_INSPECTION_NEW_THREE" &&
      machine.process_key_step !== "HANDOVER_NEW_ONE" &&
      // machine.process_key_step  !== 'HANDOVER_NEW_ONE' ||

      machine.process_key !== "COMMISSIONING_NEW") ||
    isOperator(currentUser) || // operator can always view the machine but can't assign, re-assign
    isCustomer(currentUser) || // operator can always view the machine but can't assign, re-assign
    machine.process_status_text === "Declined"
  );
}

export function hasPermissionToReAssignMachine(
  currentRole,
  currentUser,
  machine
) {
  return (
    currentUser.permission_can_re_assign_machine &&
    (machine.process_key === "EVALUATION_NEW" ||
      machine.process_key === "PROCUREMENT_NEW" ||
      machine.process_key === "MACHINE_REFURBISHMENT_NEW" ||
      machine.process_key === "LEASING_NEW" ||
      machine.process_key === "MACHINE_HEALTH_SETUP(IOT)_NEW" ||
      machine.process_key === "MACHINE_HEALTH_SETUP_(IOT)_NEW" ||
      machine.process_key === "MACHINE_RETOOLING_NEW" ||
      machine.process_key_step === "MACHINE_QUALIFICATION_NEW_TWO" ||
      machine.process_key_step === "MACHINE_QUALIFICATION_NEW_THREE" ||
      machine.process_key_step === "PRE-DELIVERY_INSPECTION_NEW_TWO" ||
      machine.process_key_step === "PRE-DELIVERY_INSPECTION_NEW_THREE" ||
      machine.process_key_step === "HANDOVER_NEW_ONE" ||
      machine.process_key === "COMMISSIONING_NEW") &&
    machine.process_assigned_assigned === true &&
    machine.process_status_text !== "Declined"
  );
}

export function hasPermissionToAssignMachine(
  currentRole,
  currentUser,
  machine
) {
  return (
    currentUser.permission_can_assign_machine &&
    (machine.process_key === "EVALUATION_NEW" ||
      machine.process_key === "PROCUREMENT_NEW" ||
      // machine.process_key       === 'PROCUREMENT_ACCEPTANCE_NEW' ||
      machine.process_key === "MACHINE_REFURBISHMENT_NEW" ||
      machine.process_key === "LEASING_NEW" ||
      machine.process_key === "MACHINE_HEALTH_SETUP(IOT)_NEW" ||
      machine.process_key === "MACHINE_HEALTH_SETUP_(IOT)_NEW" ||
      machine.process_key === "MACHINE_RETOOLING_NEW" ||
      machine.process_key_step === "MACHINE_QUALIFICATION_NEW_TWO" ||
      machine.process_key_step === "MACHINE_QUALIFICATION_NEW_THREE" ||
      machine.process_key_step === "PRE-DELIVERY_INSPECTION_NEW_TWO" ||
      machine.process_key_step === "PRE-DELIVERY_INSPECTION_NEW_THREE" ||
      machine.process_key_step === "HANDOVER_NEW_ONE" ||
      machine.process_key === "COMMISSIONING_NEW") &&
    machine.process_assigned_assigned === false &&
    machine.process_status_text !== "Declined"
  );
}

export function hasAssignPermission(currentUser) {
  return currentUser.permission_can_approve_assign_refurbisher_machine;
}

export function hasViewPermission(currentUser) {
  return currentUser.permission_can_view_machine;
}

export function canViewMachine(currentRole, currentUser, machine) {
  return hasPermissionToViewMachine(currentRole, currentUser);

  //
  // if (isOperator(currentUser)){
  //     return hasPermissionToViewMachine(currentRole, currentUser)
  // }
  // else if (isLineManager(currentUser)) {
  //     return hasPermissionToViewMachine(currentRole, currentUser)
  // }
  // else if (isEvaluator(currentUser)) {
  //     return machine.process_text === 'evaluation';
  // }
  // else if (isRefurbisher(currentUser)) {
  //     return machine.process_text === 'machine-refurbishment';
  // }
  // else if (isCommissioner(currentUser)) {
  //     return hasPermissionToViewMachine(currentRole, currentUser) &&
  //         (machine.process_text === 'commissioning' ||
  //             machine.process_text === 'handover' ||
  //             machine.process_text ===  'machine-qualification' ||
  //             machine.process_text ===  'machine-retooling'||
  //             machine.process_text ===  'machine-health-setup-(iot)' ||
  //             machine.process_text ===  'pre-delivery-inspection'
  //             // machine.process_text ===  ''
  //         )
  // }
  // else if (isCustomer(currentUser)) {
  //     return hasPermissionToViewMachine(currentRole, currentUser) &&
  //         (machine.process_text === 'commissioning' ||
  //             machine.process_text === 'handover' ||
  //             machine.process_text ===  'machine-qualification' ||
  //             machine.process_text ===  'machine-retooling'||
  //             machine.process_text ===  'machine-health-setup-(iot)' ||
  //             machine.process_text ===  'pre-delivery-inspection'
  //             // machine.process_text ===  ''
  //         )
  // }
}

export function canReAssignMachine(currentRole, currentUser, machine) {
  return hasPermissionToReAssignMachine(currentRole, currentUser, machine);
}

export function canAssignMachine(currentRole, currentUser, machine) {
  return hasPermissionToAssignMachine(currentRole, currentUser, machine);
}

export function canBookADate(currentRole, currentUser, machine) {
  return (
    isCustomer(currentUser) &&
    (machine.process_key_step === "MACHINE_QUALIFICATION_NEW_ONE" ||
      machine.process_key_step === "PRE-DELIVERY_INSPECTION_NEW_ONE")
  ); // only machine qualificaition start and pre-inspection delivery start booking occur for customer
}

export function hasApproveAssignPermission(currentUser) {
  return currentUser.permission_can_approve_assign_refurbisher_machine;
}

export function isCommissioner(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_COMMISSION);
}

export function isEvaluator(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_EVALUATE);
}

export function isLineManager(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_MANAGE);
}

export function isOperator(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_OPERATE);
}

export function isCustomer(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_CUSTOMER);
}

export function isRefurbisher(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_REFURBISH);
}
export function isSuperAdmin(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_SUPERADMIN);
}

export function isAdmin(currentUser) {
  return hasPermission(currentUser, PERMISSIONS.PERMISSION_CAN_DO_ADMIN);
}
// new permissions
export function hasViewFinancePermission(currentUser) {
  return currentUser.permission_can_view_finance;
}
export function hasViewEnqueryPermission(currentUser) {
  // console.log("currentUser ", currentUser);
  return currentUser.permission_can_view_enquiries;
}
export function hasPermission(currentUser, permissionName) {
  // console.log(currentUser);
  // console.log(" hasPermission ", {
  //    permissionName: permissionName,
  //    currentUser:currentUser
  // })

  // if (currentUser[permissionName] === true) {
  //  // console.log(" Permission found. ");
  //  // console.log("Permission name: ", permissionName)
  // }

  return currentUser[permissionName];
}
