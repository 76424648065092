import api from "../../../support/support_api";

const adminOrganizationIndex = async (params) => {
	const response = await api.axiosGet("/admin/organizations", params);

	return response.data;
};

const adminOrganizationStore = async (params) => {
	const response = await api.axiosPost("/admin/organizations", params);

	return response.data;
};

const adminOrganizationShow = async (params) => {
	const response = await api.axiosGet(`/admin/organizations/${params.id}`, params, false);

	return response.data;
};

const adminOrganizationUpdate = async (params) => {
	const response = await api.axiosPatch(`/admin/organizations/${params.id}`, params);

	return response.data;
};

const adminOrganizationDelete = async (params) => {
	const response = await api.axiosDelete(`/admin/organizations/${params.id}`, params);

	return response.data;
};

const adminOrganizationService = {
	adminOrganizationIndex,
	adminOrganizationStore,
	adminOrganizationShow,
	adminOrganizationUpdate,
	adminOrganizationDelete,
};

export default adminOrganizationService;
