import api from "../../../support/support_api";

const visualCheckUpdate = async (params) => {
  const response = await api.axiosPatch(`/visual-checks/${params.id}`, params);

  return response.data;
};

const visualCheckShow = async (params) => {
  const response = await api.axiosGet(
    `/visual-checks/${params.id}`,
    params,
    false
  );

  return response.data;
};

const visualCheckDetailAdd = async (params) => {
  const response = await api.axiosPost(
    `/visual-checks/${params.id}/detail-add`,
    params,
    false
  );

  return response.data;
};

const visualCheckDetailUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/visual-check-details/${params.id}`,
    params
  );

  return response.data;
};
const visualCheckDetailDelete = async (params) => {
  const response = await api.axiosDelete(
    `/visual-check-details/${params.id}`,
    params
  );

  return response.data;
};

const visualCheckService = {
  visualCheckUpdate,
  visualCheckShow,
  visualCheckDetailAdd,
  visualCheckDetailUpdate,
  visualCheckDetailDelete,
};

export default visualCheckService;
