import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";

import machineService from "./machineService";

export const machineUpdateAddon = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateAddon(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdatePlan = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdatePlan(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateRoleToRefurbisher = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateRoleToRefurbisher(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineEvaluationCompleted = createAsyncThunk(
  "machines/evaluation-finished",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineEvaluationCompleted(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const machineIndex = createAsyncThunk(
  "machineIndex",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineSearch = createAsyncThunk(
  "machineIndex",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineSearch(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineAddOrRetrieve = createAsyncThunk(
  "machineAddOrRetrieve",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineAddOrRetrieve(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdate = createAsyncThunk(
  "machines/update",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateDetail = createAsyncThunk(
  "machines/update-detail",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateDetail(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateCompany = createAsyncThunk(
  "machines/update-company",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateCompany(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateField = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateField(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateAdditionalInformation = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateAdditionalInformation(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineShow = createAsyncThunk(
  "machineShow",
  async (args, thunkAPI) => {
    try {
      const selectedTabItem =
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 4
        ];
      const tabName =
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 1
        ];
      const machineProcess =
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 2
        ];

      if (
        machineProcess === "employee" ||
        machineProcess === "activities-and-tools"
      ) {
      } else if (
        machineProcess === "evaluation-overview" ||
        machineProcess === "evaluation"
      ) {
        args.page_role_name = "evaluator";
      } else if (machineProcess === "refurbishment") {
        args.page_role_name = "refurbisher";
      }

      return await machineService.machineShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineReuseInformation = createAsyncThunk(
  "machineReuseInformation",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineReuseInformation(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineCatalog = createAsyncThunk(
  "machineCatalog",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineCatalog(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineCatalogs = createAsyncThunk(
  "machineCatalogs",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineCatalogs(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineFilters = createAsyncThunk(
  "machineFilters",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineFilters(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

//Newly created
export const machineAddToCatalog = createAsyncThunk(
  "machines/add-to-catalogue/add",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineAddToCatalog(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const machineAddOrRemoveCatalog = createAsyncThunk(
  "machines/add-to-catalogue/add-remove",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.machineAddOrRemoveCatalog(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const machineVisibleOnWebsite = createAsyncThunk(
  "machines/add-to-catalogue/visible-website",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineVisibleOnWebsite(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineShowForm = createAsyncThunk(
  "machines/show-form",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineShowForm(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineReuseForms = createAsyncThunk(
  "machines/show-form",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineReuseForms(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateModuleProcess = createAsyncThunk(
  "machines/show-form",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.machineUpdateModuleProcess(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateSupport = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateSupport(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateFeature = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateFeature(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machineUpdateFailure = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machineUpdateFailure(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const machinePassportUpdate = createAsyncThunk(
  "machines/show",
  async (args, thunkAPI) => {
    try {
      return await machineService.machinePassportUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
const initialState = {
  data: {},
  query: {},
  machines: {
    data: [],
  },
  machine: {
    company: {
      dirty: false,
    },
    technical_background: {},
    technical_specification: {},
    technical_spindle: {},
    technical_workable: {},
    technical_additional_information: {},
    dirty: false,
  },

  errors: {},

  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
  dirty: false,
  filter: {},
};

export const machineSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    reduxSetErrors(state, action) {
      state.errors = action.payload.errors;
    },
    updateHeaderMachine(state, action) {
      const { key, value: properties } = action.payload;
      const { name, value } = properties;
      state.machine[key].check[name] = value;
      state.machine[key].check.dirty = true;
    },
    updateCompany(state, action) {
      //   const { key, value } = action.payload;
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine.company[key] = value;
      state.machine.company.dirty = true;
    },
    updateTechnicalBackground(state, action) {
      // const key = Object.keys(action.payload)[0];
      // const value = Object.values(action.payload)[0];
      // state.machine.technical_background[key] = value;
      // state.machine.dirty = true;
      return {
        ...state,
        machine: {
          ...state.machine,
          technical_background: {
            ...state.machine.technical_background,
            ...action.payload,
          },
          dirty: true,
        },
      };
    },

    updateAssignment(state, action) {
      // const key = Object.keys(action.payload)[0];
      // const value = Object.values(action.payload)[0];
      // state.machine.assignment[key] = value;
      // state.machine.assignment.dirty = true;

      return {
        ...state,
        machine: {
          ...state.machine,
          assignment: {
            ...state.machine.assignment,
            ...action.payload,
            dirty: true,
          },
        },
      };
    },
    updateMachine(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine[key] = value;
      state.machine.dirty = true;
    },
    updateTechnicalSpecification(state, action) {
      // const key = Object.keys(action.payload)[0];
      // const value = Object.values(action.payload)[0];
      // state.machine.technical_specification[key] = value;
      // state.machine.dirty = true;
      return {
        ...state,
        machine: {
          ...state.machine,
          technical_specification: {
            ...state.machine.technical_specification,
            ...action.payload,
          },
          dirty: true,
        },
      };
    },
    updateTechnicalWorkable(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine.technical_workable[key] = value;
      state.machine.dirty = true;
    },
    updateTechnicalSpindle(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine.technical_spindle[key] = value;
      state.machine.dirty = true;
    },
    updateTechnicalToolSystem(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine.technical_tool_system[key] = value;
      state.machine.dirty = true;
    },
    updateTechnicalAdditionalInformation(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine.technical_additional_information[key] = value;
      state.machine.dirty = true;
    },
    updateDocumentComment(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.machine.technical_background[key] = value;
      state.machine.dirty = true;
    },

    updateQuery(state, action) {
      // const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state.query = value;
      state.query.dirty = true;
    },

    updateFeatureStore(state, action) {
      const { feature } = action.payload;

      state.machine.feature.description = feature;
      state.machine.feature.dirty = true;
    },

    updateSupportStore(state, action) {
      const { support } = action.payload;

      state.machine.support.description = support;
      state.machine.support.dirty = true;
    },
    updatePlanStore(state, action) {
      const { id, key, value } = action.payload;
      if (id === undefined) {
        state.machine.plans.map((p) => {
          p[key] = value;
          p.dirty = true;
        });
      } else {
        state.machine.plans.filter((p) => {
          if (p.id === id) {
            p[key] = value;
            p.dirty = true;
          }
        });
      }
    },
    updateAddOnStore(state, action) {
      const { id, key, value } = action.payload;
      if (id === undefined) {
        state.machine.addons.map((p) => {
          p[key] = value;
          p.dirty = true;
        });
      } else {
        state.machine.addons.filter((p) => {
          if (p.id === id) {
            p[key] = value;
            p.dirty = true;
          }
        });
      }
    },
    updateCompleted(state, action) {
      const { model_type, complete, form } = action.payload;
      if (model_type === "tool_list") {
        if (form === "main-form") {
          state.machine.assignment.complete.tool.by_auth = complete;
        } else {
          state.machine.assignment_role.evaluator.complete.tool.by_auth =
            complete;
        }
      } else if (model_type === "activity_list") {
        if (form === "summary" || form === "refurb") {
          state.machine.assignment.complete.activity.by_auth = complete;
        } else {
          state.machine.assignment_role.evaluator.complete.activity.by_auth =
            complete;
        }
      } else if (model_type === "machine") {
        state.machine.complete.by_auth = complete;
      } else {
        if (form === "commission") {
          if (model_type === "visual_check") {
            state.machine.visual_check_role.commissioner.complete.by_auth =
              complete;
          } else if (model_type === "static_accuracy") {
            state.machine.static_accuracy_role.commissioner.complete.by_auth =
              complete;
          } else {
            state.machine[model_type].complete.by_auth = complete;
          }
        } else {
          state.machine[model_type].complete.by_auth = complete;
        }
      }
    },
    updateFailure(state, action) {
      const { value } = action.payload;
      state.machine["failure"] = value;
    },
    resetMachinePlanStore(state, action) {
      state.machine.plans.map((p) => {
        p.dirty = false;
      });
    },
    resetMachineAddOnStore(state, action) {
      state.machine.addons.map((p) => {
        p.dirty = false;
      });
    },
    updateFileStore(state, action) {
      const { data, key } = action.payload;

      state.machine[key] = data.data.file;
    },

    updateDocumentStore(state, action) {
      const { data, file_id } = action.payload;
      if (data.data.is_deleted) {
        const filtered = state.machine.document.files.filter((file) => {
          return file.id !== file_id;
        });

        state.machine.document.files = filtered;
      } else {
        if (file_id) {
          const updated = state.machine.document.files.map((file) => {
            if (file.id === file_id) {
              return data.data.file;
            } else {
              return file;
            }
          });
          state.machine.document.files = updated;
        } else {
          state.machine.document.files.push(data.data.file);
        }
      }
    },
  },
  extraReducers: {
    [machineAddOrRetrieve.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.machines.data = []; //This will clear the list on the machine index but this affect the functionality of other forms
      // state.machine.dirty = true;
    },
    [machineAddOrRetrieve.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }

      if (payload.data?.machines) {
        state.machines = payload.data.machines;
      }

      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = true;
    },
    [machineAddOrRetrieve.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [machineIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.machines.data = [];
    },
    [machineIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }

      if (payload.data?.machines) {
        state.machines = payload.data.machines;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [machineIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [machineShow.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.machines = {};
      // state.machines.data = [];
    },
    [machineShow.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }

      if (payload.data?.machines) {
        state.machines = payload.data.machines;
      }
      // state.machines = payload.data.machines;
      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = false;
    },
    [machineShow.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [machineCatalog.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineCatalog.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }

      if (payload.data?.machines) {
        state.machines = payload.data.machines;
      }
      // state.machines = payload.data.machines;
      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = false;
    },
    [machineCatalog.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [machineCatalogs.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineCatalogs.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.machines = payload.data.machines;
      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = false;
      state.query.dirty = false;
    },
    [machineCatalogs.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [machineShowForm.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineShowForm.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      if (payload.data?.model) {
        state.model = payload.data.model;
      }

      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }
      // state.machines = payload.data.machines;
      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = false;
    },
    [machineShowForm.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [machineUpdateCompany.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineUpdateCompany.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        // dirty: false,
        machine: {
          ...state.machine,
          ...payload.data.machine,
          company: {
            ...state.machine.company,
            dirty: false,
          },
        },
      };
    },
    [machineUpdateCompany.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [machineUpdateDetail.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineUpdateDetail.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        dirty: false,
        machine: payload.data.machine,
      };
    },
    [machineUpdateDetail.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [machineAddOrRemoveCatalog.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineAddOrRemoveCatalog.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }
      // state.machines = payload.data.machines;
      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = false;
    },
    [machineAddOrRemoveCatalog.rejected]: (state, { payload }) => {
      // state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [machineEvaluationCompleted.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [machineEvaluationCompleted.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        dirty: false,
        machine: {
          ...state.machine,
          ...payload.data.machine,
        },
      };
    },
    [machineEvaluationCompleted.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    // machineFilters
    [machineFilters.pending]: (state, { payload }) => {
      state.filters = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [machineFilters.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updating: false,
        loading: false,
        isSuccess: true,
        filter: payload.data.filters,
      };
    },
    [machineFilters.rejected]: (state, { payload }) => {
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [machineReuseInformation.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.machines = {};
      // state.machines.data = [];
    },
    [machineReuseInformation.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.machine) {
        state.machine = payload.data.machine;
      }

      state.loading = false;
      state.isSuccess = true;
      state.machine.dirty = true;
    },
    [machineReuseInformation.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  updateCompany,
  updateMachine,
  updateTechnicalBackground,
  updateTechnicalSpecification,
  updateTechnicalWorkable,
  updateTechnicalSpindle,
  updateTechnicalAdditionalInformation,
  updateTechnicalToolSystem,
  updateFeatureStore,
  updateSupportStore,
  updatePlanStore,
  updateAddOnStore,
  updateCompleted,
  reduxSetErrors,
  updateFailure,
  updateAssignment,
  resetMachinePlanStore,
  resetMachineAddOnStore,
  updateFileStore,
  updateQuery,
  updateDocumentStore,
  updateDocumentComment,
  updateHeaderMachine,
} = machineSlice.actions;

export default machineSlice;
