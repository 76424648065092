import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import support_response from "../../support/support_response";

import service from "./contactService";

export const contactStore = createAsyncThunk(
  "contacts/index",
  async (args, thunkAPI) => {
    try {
      return await service.contactStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const contactSupplier = createAsyncThunk(
  "contacts/index",
  async (args, thunkAPI) => {
    try {
      return await service.contactSupplier(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  contacts: {
    data: [],
  },
  contact: {},
  loading: false,
  isSuccess: false,
  updating: false,
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
      extraReducers: {
          [contactStore.pending]: (state, {payload}) => {
              state.updating = true;
              state.loading = true;
              state.isSuccess = false;
          },
          [contactStore.fulfilled]: (state, {payload}) => {
              state.data = payload;
              state.updating = false;

              if (payload?.data?.contact) {
                  state.contact = payload.data.contact;
              }

              if (payload?.data?.contacts) {
                  state.contacts = payload.data.contacts;
              }

              state.loading = false;
              state.isSuccess = true;
          },
          [contactStore.rejected]: (state, {payload}) => {
              state.data = payload;
              state.updating = false;
              state.loading = false;
              state.isSuccess = true;
          },
      },
  }
});

export const {



} = contactSlice.actions;

export default contactSlice;
