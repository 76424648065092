import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../../support/support_response";

import service from "./userService";

export const adminUserIndex = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminUserIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminUserStore = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminUserStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminUserShow = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminUserShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminUserUpdate = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminUserUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminUserDelete = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminUserDelete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const adminUserUpdatePassword = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.adminUserUpdatePassword(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const userUpdatePassword = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.userUpdatePassword(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const userUpdate = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.userUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const retrieveAuth = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.retrieveAuth(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const showActions = createAsyncThunk(
  "admin/users/index",
  async (args, thunkAPI) => {
    try {
      return await service.showActions(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "api/users",
  async (args, thunkAPI) => {
    try {
      return await service.updateNotifications(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

// GET|HEAD        api/admin/users ........................................................................................................... users.index › Api\Admin\UserController@index
// POST            api/admin/users ........................................................................................................... users.store › Api\Admin\UserController@store
// POST            api/admin/users/update-password .............................................................. api.admin.users.update-password › Api\Admin\UserController@updatePassword
// GET|HEAD        api/admin/users/{user} ...................................................................................................... users.show › Api\Admin\UserController@show
// PUT|PATCH       api/admin/users/{user} .................................................................................................. users.update › Api\Admin\UserController@update
// DELETE          api/admin/users/{user} ................................................................................................ users.destroy › Api\Admin\UserController@destroy

const initialState = {
  data: {},
  user: {},
  actions: [],
  users: {
    data: [],
  },
  loading: false,
  isSuccess: false,
  updating: false,
};

export const adminUserSlice = createSlice({
  name: "adminUser",
  initialState,
  reducers: {},
  extraReducers: {
    [adminUserIndex.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [adminUserIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.users) {
        state.users = payload.data.users;
      }

      if (payload?.data?.user) {
        state.user = payload.data.user;
      }

      if (payload?.data?.actions) {
        state.actions = payload.data.actions;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [adminUserIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    [showActions.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [showActions.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.updating = false;

      state.loading = false;
      state.isSuccess = true;
    },
    [showActions.rejected]: (state, { payload }) => {
      state.data = payload.data;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = adminUserSlice.actions;

export default adminUserSlice;
