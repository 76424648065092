import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";

import service from "./orderDetailService";

export const orderDetailIndex = createAsyncThunk(
  "order-details/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderDetailIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderDetailStore = createAsyncThunk(
  "orderDetailStore",
  async (args, thunkAPI) => {
    try {
      return await service.orderDetailStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderDetailShow = createAsyncThunk(
  "order-details/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderDetailShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderDetailUpdate = createAsyncThunk(
  "order-details/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderDetailUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderDetailDestroy = createAsyncThunk(
  "order-details/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderDetailDestroy(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},

  order_details: {
    data: [],
  },

  machine: {},
  order_detail: {},
  order: {},

  loading: false,
  isSuccess: false,
  updating: false,
  showCartOverlay: false,
};

export const orderDetailSlice = createSlice({
  name: "order-detail",
  initialState,
  reducers: {
    updateOrderStore(state, action) {
      const key = Object.keys(action.payload)[0];
      const value = Object.values(action.payload)[0];
      state[key] = value;
    },
  },
  extraReducers: {
    [orderDetailIndex.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [orderDetailIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.order_detail) {
        state.order_detail = payload.data.order_detail;
      }

      if (payload?.data?.order_details) {
        state.order_details = payload.data.order_details;
      }

      if (payload?.data?.machine) {
        state.machine = payload.data.machine;
      }

      if (payload?.data?.order) {
        state.order = payload.data.order;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [orderDetailIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
    //
    [orderDetailStore.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [orderDetailStore.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.order_detail) {
        state.order_detail = payload.data.order_detail;
      }

      if (payload?.data?.order_details) {
        state.order_details = payload.data.order_details;
      }

      if (payload?.data?.machine) {
        state.machine = payload.data.machine;
      }

      if (payload?.data?.order) {
        state.order = payload.data.order;
      }

      // state.showCartOverlay = true;

      state.loading = false;
      state.isSuccess = true;
    },
    [orderDetailStore.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { updateOrderStore } = orderDetailSlice.actions;

export default orderDetailSlice;
