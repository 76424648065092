import { hideAlert } from "features/alert/alertSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Alert from "./alerts/Alert";

const AlertContainer = () => {
  const alert = useSelector((state) => state.alert);
  const {
    show,
    hideCloseButton,
    hideNoButton,
    icon,
    customBackground,
    title,
    message,
    customActionButtons,
    customBody,
    headerTitle,
    imageAlt,
    secondaryButtonText,
    primaryButtonText,
    secondaryButtonFunction,
    primaryButtonFunction,
    status,
    addressCopyAlert
  } = alert;
  const dispatch = useDispatch();
  const hide = () => {
    dispatch(hideAlert());
  };
  return (
    <>
      <Alert
        show={show}
        icon={icon}
        callback={() => {
          hide();
        }}
        headerTitle={headerTitle}
        imageAlt={imageAlt}
        status={status}
        customActionButtons={customActionButtons}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        primaryButtonFunction={() => {
          hide();
          primaryButtonFunction !== undefined && primaryButtonFunction();
        }}
        secondaryButtonFunction={() => {
          hide();
          secondaryButtonFunction !== undefined && secondaryButtonFunction();
        }}
        title={title}
        hideCloseButton={hideCloseButton}
        hideNoButton={hideNoButton}
        message={message}
        customBody={customBody}
        customBackground={customBackground}
        addressCopyAlert={addressCopyAlert}
      />
    </>
  );
};

export default AlertContainer;
