import React from "react";
import { Link } from "react-router-dom";

const CommonSidebar = ({ children }) => {
  return (
    <div className="ec-left-sidebar ec-bg-sidebar bg-white" id="sidebarnav">
      <div id="sidebar" className="sidebar ec-sidebar-footer">
        <div className="ec-brand">
          <Link to={"/"} id={`link-logo`}>
            <img
              src={require("assets/img/logo/logo-eaas.png")}
              alt="eaas logo"
              style={{
                width: "157px",
                height: "58px",
                marginTop: "-6px",
                marginLeft: "-4px"
              }}
            />
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};

export default CommonSidebar;
