import React from 'react'
const CommonCardWrapperContent = ({ children }) => {

  return (  
    <div className="ec-content-wrapper mt-2">
       <div className="app-content content">
        <div className='mt-10'>
          { children }
        </div>
      </div>
    </div>
  );
};

export default CommonCardWrapperContent;
 