import "./CustomerLogin.css";

import darkLogoEaas from "assets/images/logo/dark-logo-eaas.png";
import { loginCustomer } from "../../features/auth/loginSlice";
import { useDispatch } from "react-redux/es/index";
import support_device from "../../support/support_device";

import React, { useState } from "react";
import { get_role_name } from "../../support/support_url";
import { showAlertToast } from "features/alert/toastSlice";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CustomerLoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = (e) => {
    e.preventDefault();

    dispatch(
      loginCustomer({
        email: email,
        password: password,
        device_id: support_device.getBrowserId(),
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        dispatch(
          showAlertToast({
            status: "error",
            message: response.payload,
          })
        );
      } else {
        if (response.payload?.data?.order_details_count > 0) {
          window.location = `${get_role_name()}/shopping-cart`;
        } else {
          window.location = `${get_role_name()}`;
        }
      }
    });
  };

  return (
    <div className="background-customer mobile-height">
      {/* Logo */}
      <div className="ec-header-login">
        <div className="header-logo ml-6">
          <a href="/" id="a-logo">
            <img
              src={darkLogoEaas}
              alt="Site Logo"
              style={{
                width: "156px",
                height: "57px",
              }}
            />
            <img
              className="dark-logo"
              src={darkLogoEaas}
              alt="Site Logo"
              style={{ display: "none", width: "143px", height: "45px" }}
            />
          </a>
        </div>
      </div>
      <div className="ec-page-content section-space-ptb-100">
        <Container>
          <Row className="mobile-layout">
            <Col>
              <div className="section-title">
                <h2 className="ec-title">Welcome.</h2>
                <p className="sub-title mobile-hide">
                  This is a portal for{" "}
                  <label className="text-orange mr-2">EAASE </label>
                  customers to register <br />
                  and manage machines in the platform.
                </p>

                <p className="sub-title mobile-text">
                  This is a portal for{" "}
                  <span className="text-orange mr-2">EAASE </span>
                  customers to register and manage machines in the platform.
                </p>
              </div>
            </Col>
            <Col>
              <div className="ec-login-wrapper">
                <div className="ec-login-container">
                  <div className="ec-login-form">
                    <form onSubmit={login} method="post">
                      <span className="ec-login-wrap">
                        <label>Username</label>
                        <input
                          type="text"
                          name="email"
                          id="input-text-email"
                          className="form-control mb-0"
                          placeholder="Enter your email..."
                          onChange={({ target }) => {
                            setEmail(target.value);
                          }}
                        />
                      </span>

                      <span className="ec-login-wrap mt-3">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          id="input-text-password"
                          className="form-control"
                          placeholder="Enter your password"
                          required=""
                          onChange={({ target }) => {
                            setPassword(target.value);
                          }}
                        />
                      </span>
                      <span className="ec-login-wrap ec-login-fp">
                        <label>
                          <Link
                            to="/reset-password-customer"
                            id="a-forgot-passowrd"
                            className="text-decoration-underline"
                          >
                            Forgot Password?
                          </Link>
                        </label>
                      </span>
                      <span className="ec-login-wrap ec-login-btn margin-top-20">
                        <button
                          id="btn-login"
                          className="btn btn-primary btn-block btn-large"
                          type="submit"
                        >
                          Login
                        </button>
                      </span>

                      <span className="ec-login-wrap ec-login-fp margin-top-20">
                        <label className="fw-400">
                          Don’t have an account?{" "}
                          <a
                            href="/register"
                            id="a-sign-up"
                            className="text-decoration-underline"
                          >
                            Get more info.
                          </a>
                        </label>
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CustomerLoginPage;
