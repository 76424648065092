import api from "../../support/support_api";

const employeeIndex = async (params) => {
    const response  = await api.axiosGet(`/employees`, params);

    return response.data;
}

const catalogService = {
    employeeIndex,
}

export default catalogService;