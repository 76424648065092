import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import accountService from "./accountService";

export const sendEmail = createAsyncThunk(
  "sendEmail",
  async (args, thunkAPI) => {
    try {
      return await accountService.sendEmail(args);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (args, thunkAPI) => {
    try {
      return await accountService.changePassword(args);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (args, thunkAPI) => {
    try {
      return await accountService.resetPassword(args);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resendVerification = createAsyncThunk(
  "resendVerification",
  async (args, thunkAPI) => {
    try {
      return await accountService.resendVerification(args);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "verifyEmail",
  async (args, thunkAPI) => {
    try {
      return await accountService.verifyEmail(args);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  // isLoggedIn:true,
  data: {},
  loading: false,
  isSuccess: false,
};

export const accountSlice = createSlice({
  name: "forgotpass",
  initialState,
  reducers: {},
  extraReducers: {
    [sendEmail.pending]: (state, { payload }) => {
      state.data = {};
      state.loading = true;
      state.isSuccess = false;
    },
    [sendEmail.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
    [sendEmail.rejected]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {} = accountSlice.actions;

export default accountSlice;
