import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import service from "./leasingService";

export const leasingUpdate = createAsyncThunk(
  "orders/update",
  async (args, thunkAPI) => {
    try {
      return await service.leasingUpdate(args);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
const initialState = {
  data: {},

  order: {},

  leasing: {},

  loading: false,
  isSuccess: false,
  updating: false,
  prompt: true,
};

export const leasingSlice = createSlice({
  name: "leasing",
  initialState,
  reducers: {},
  extraReducers: {
    [leasingUpdate.pending]: (state, { payload }) => {
      // state.data = {};
      state.updating = true;
      state.loading = false;
      state.isSuccess = false;
      state.prompt = false;
    },
    [leasingUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.leasing) {
        state.leasing = payload.data.leasing;
      }

      if (payload?.data?.order) {
        state.order = payload.data.order;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [leasingUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
} = leasingSlice.actions;

export default leasingSlice;
