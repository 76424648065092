import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./WebsiteHeaderMobile.scss";
import darkLogoEaas from "assets/images/logo/dark-logo-eaas.png";

export const WebsiteHeaderMobile = () => {
  const [clickedMenu, setClickedMenu] = useState(false);
  return (
    <header className="mobile-header">
      <button
        className={`hamburger ${clickedMenu ? "active" : ""}`}
        onClick={() => setClickedMenu(!clickedMenu)}
        data-dropdown-hamburger-button
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
      <div className="mobile-logo">
        <a href="/" id="a-logo">
          <img
            src={darkLogoEaas}
            alt="Site Logo"
            style={{
              width: "143px",
              height: "40px",
            }}
          />
        </a>
      </div>

      <nav className={` main-nav_hamburger ${clickedMenu ? "set-open" : ""}`}>
        <ul
          className="main-nav_links_ul_hamburger"
          onClick={(e) => {
            if (e.target.dataset.id) {
              setClickedMenu(false);
              return;
            }
          }}
        >
          <Link className="nav-link-hamburger" data-id={"link"} to="/products">
            Browse
          </Link>
          <Link className="nav-link-hamburger" data-id={"link"} to="/suppliers">
            Suppliers
          </Link>
          <Link
            className="nav-link-hamburger"
            data-id={"link"}
            to="/how-it-works"
          >
            How It Works
          </Link>
          <Link
            className="nav-link-hamburger"
            data-id={"link"}
            to="/contact-us"
          >
            Contact
          </Link>
          <Link
            className="nav-link-hamburger"
            data-id={"link"}
            to="/login-selection"
          >
            Login
          </Link>
        </ul>
      </nav>
    </header>
  );
};
