import LoginSelection from "components/login/selection/SelectionLogin";
const LoginPage = () => {
  return (
    <div className="container">
      <LoginSelection />
    </div>
  );
};

export default LoginPage;
