import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "axios";
// import { AxiosResponse } from "axios";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
// import {greet} from "support/api";

import support_response from "../../support/support_response";
import machineService from "./toolService";

export const toolIndex = createAsyncThunk(
  "tools/index",
  async (args, thunkAPI) => {
    try {
      return await machineService.toolIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const toolByCategory = createAsyncThunk(
  "tools/by-category",
  async (args, thunkAPI) => {
    try {
      return await machineService.toolByCategory(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  tool: {},

  category_tools: {
    data: [],
  },
  category_inspection_tools: {
    data: [],
  },

  categories: {
    data: [],
  },

  params: {
    tools: [
      {
        id: 0,
        quantity: 0,
      },
    ],
  },
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,
};

export const toolSlice = createSlice({
  name: "tool",
  initialState,
  reducers: {
    toolSetErrors(state, action) {
      state.errors = action.payload.errors;
    },
    incrementDecrement(state, action) {
      // note: deep nested update works in reactjs toolki!!! it takes me several hours to figure out this!
      const { categoryIndex, toolIndex, execute, id } = action.payload;

      let toolselected = {
        ...state.categories.data[categoryIndex].tools.data[toolIndex],
      };

      if (execute === "increment") {
        toolselected.quantity = toolselected.quantity + 1;
      } else {
        if (toolselected.quantity > 0) {
          toolselected.quantity = toolselected.quantity + -1;
        }
      }

      state.categories.data[categoryIndex].tools.data[toolIndex] = toolselected;

      // append data to redux toolkit
      state.params.tools = state.params.tools.filter(
        (arrow) => arrow.id !== id
      );
      state.params.tools = state.params.tools.filter((arrow) => arrow.id !== 0);

      state.params.tools.push({
        id: id,
        quantity: toolselected.quantity,
      });
    },

    updateCategoryTool(state, action) {
      let newInfo = action.payload;
      // let existingInfo = {...state.category_tools.data};
      //
      // let updatedInfo = Object.assign(existingInfo, newInfo)
      //
      // state.category_tools.data = updatedInfo;
    },
  },
  extraReducers: {
    [toolByCategory.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [toolByCategory.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      // state.tool = payload.data.tool;
      state.category_tools = payload.data.category_tools;
      state.category_inspection_tools = payload.data.category_inspection_tools;
      state.loading = false;
      state.isSuccess = true;
    },
    [toolByCategory.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [toolIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [toolIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      // state.tool = payload.data.tool;
      state.categories = payload.data.categories;
      state.loading = false;
      state.isSuccess = true;
    },
    [toolIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const { toolSetErrors, updateCategoryTool, incrementDecrement } =
  toolSlice.actions;

export default toolSlice;
