import api from "../../support/support_api";

const leasingUpdate = async (params) => {
    const response  = await  api.axiosPatch(`/leasings/${params.leasing_id}`, params);

    return response.data;
}


const leasingService = {
    leasingUpdate,
}

export default leasingService;