import React from "react";
import styles from "./Tooltip.module.css";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const Tooltip = ({
  translateX,
  translateY,
  textMain,
  textSecondary,
  width,
  website,
  callBack,
}) => {
  return (
    <div
      className={styles.tooltip}
      style={{
        transform: `translateX(${translateX}) translateY(${translateY})`,
        width: `${width}`,
      }}
    >
      {website ? <input value={textMain} readOnly /> : <span>{textMain}</span>}
      {website ? (
        <Icon
          path={mdiClose}
          className={styles.closeButton}
          id="icon-close"
          onClick={callBack}
          size={0.8}
        />
      ) : null}
      <span style={{ color: "#FD9801" }}>{textSecondary}</span>
    </div>
  );
};

export default Tooltip;
