import { useState } from "react";
import { useDispatch } from "react-redux";

import "./CustomerLogin.css";

import logoEaas from "assets/images/logo/logo-eaas.png";
import { login } from "features/auth/loginSlice";
import { get_role_name } from "../../support/support_url";
import { showAlertToast } from "features/alert/toastSlice";
//import {Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SupplierLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const submitHandler = (event) => {
    dispatch(
      login({
        email: username,
        password: password,
      })
    ).then(function (response) {
      if (response.error && response.error.message === "Rejected") {
        dispatch(
          showAlertToast({
            status: "error",
            message: response.payload,
          })
        );
      } else {
        window.location = get_role_name();
      }
    });

    event.preventDefault();
  };

  return (
    <div className="background-supplier mobile-height">
      {/* Logo */}
      <div className="ec-header-login">
        <div className="header-logo" style={{ marginLeft: "2.8rem" }}>
          <a href="/" id="a-logo">
            <img
              src={logoEaas}
              alt="Site Logo"
              style={{
                width: "157px",
                height: "57px",
              }}
            />
            <img
              className="dark-logo"
              alt="Site Logo"
              style={{ display: "none", width: "143px", height: "45px" }}
            />
          </a>
        </div>
      </div>
      {/* content */}
      <div className="ec-page-content section-space-ptb-100">
        <Container>
          <Row className="mobile-layout">
            <Col>
              <div className="section-title">
                <h2 className="ec-title text-blue">Welcome.</h2>
                <p className="sub-title text-grey mobile-hide">
                  This is a portal for{" "}
                  <label className="text-blue mr-2">EAASE </label>
                  suppliers to register <br />
                  and manage machines in the platform.
                </p>

                <p className="sub-title text-grey mobile-text">
                  This is a portal for{" "}
                  <span className="text-blue mr-2">EAASE </span>
                  suppliers to register and manage machines in the platform.
                </p>
              </div>
            </Col>
            <Col>
              <div className="ec-login-wrapper">
                <div className="ec-login-container">
                  <div className="ec-login-form">
                    <form method="post" onSubmit={submitHandler}>
                      <span className="ec-login-wrap">
                        <label className="text-grey">Username</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control mb-0"
                          id="input-text-email"
                          placeholder="Enter your email..."
                          value={username}
                          onChange={({ target }) => setUsername(target.value)}
                        />
                      </span>

                      <span className="ec-login-wrap mt-3">
                        <label className="text-grey">Password</label>
                        <input
                          type="password"
                          name="password"
                          id="input-text-password"
                          className="form-control"
                          placeholder="Enter your password"
                          value={password}
                          onChange={({ target }) => setPassword(target.value)}
                        />
                      </span>
                      <span className="ec-login-wrap ec-login-fp">
                        <label>
                          <Link
                            to="/reset-password-supplier"
                            className="text-grey text-decoration-underline"
                            id="a-forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </label>
                      </span>
                      <span className="ec-login-wrap ec-login-btn margin-top-20">
                        <button
                          id="btn-login"
                          type="submit"
                          className="btn btn-primary btn-block btn-large"
                        >
                          Login
                        </button>
                      </span>

                      <p className="ec-login-wrap ec-login-fp margin-top-20 text-grey">
                        Don't have an account?{" "}
                        <a
                          href="/suppliers"
                          className="text-grey text-decoration-underline"
                          id="a-sign-up"
                        >
                          Get more info.
                        </a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SupplierLogin;
