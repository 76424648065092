import api from "../../support/support_api";

const customerSurveyShow = async (params) => {
    const response  = await api.axiosGet(`/customer-surveys/${params.customer_survey_id}`, params);

    return response.data;
}

const customerSurveyUpdate = async (params) => {
    const response  = await api.axiosPatch(`/customer-surveys/${params.customer_survey_id}`, params);

    return response.data;
}

const customerSurveyService = {
    customerSurveyShow,
    customerSurveyUpdate,
}

export default customerSurveyService;