import api from "../../support/support_api";

const addonIndex = async (params) => {
    const response = await api.axiosGet(`/addons`, params);

    return response.data;
}

const addonUpdateParams = async (params) => {
    const response = await api.axiosPatch(`/addons/update-params/machines/${params.machine_id}`, params);

    return response.data;
}

const addonService = {
    addonIndex,
    addonUpdateParams,
}

export default addonService;