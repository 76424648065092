import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axios } from "axios";
// import { AxiosResponse } from "axios";
import {
  AxiosProvider,
  Request,
  Get,
  Delete,
  Head,
  Post,
  Put,
  Patch,
  withAxios,
} from "react-axios";
// import {greet} from "support/api";

import support_response from "../../support/support_response";

import service from "./orderService";

export const orderIndex = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderIndex(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderGuestPending = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderGuestPending(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderShow = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderGuestSendInquiry = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderGuestSendInquiry(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderPending = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderPending(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderAssignToAdvisor = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderAssignToAdvisor(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderSendInquiry = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderSendInquiry(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderUpdateSelected = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderUpdateSelected(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderLeasingCheckout = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderLeasingCheckout(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderLeasingProceed = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderLeasingProceed(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderLeasingRelease = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderLeasingRelease(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderEnquiryClose = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderEnquiryClose(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const orderAdvisoryComplete = createAsyncThunk(
  "orders/index",
  async (args, thunkAPI) => {
    try {
      return await service.orderAdvisoryComplete(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  orders: {
    data: [],
  },
  order_details: {
    data: [],
  },

  order: {},

  contact: {},

  loading: false,
  isSuccess: false,
  updating: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateSelectedOrderDetails(state, action) {
      const { orderIndex, detailIndex, selected } = action.payload;

      let itemsCloned = {
        ...state.orders.data[orderIndex].details[detailIndex],
      };

      itemsCloned.selected = selected;

      state.orders.data[orderIndex].details[detailIndex] = itemsCloned;
    },
    updateSelectedOrderDetail(state, action) {
      const { detailIndex, selected } = action.payload;

      let itemCloned = { ...state.order.details[detailIndex] };

      itemCloned.selected = selected;

      state.order.details[detailIndex] = itemCloned;
    },
    updateOrderFileStore(state, action) {
      const { data, key } = action.payload;

      state.order.leasing[key] = data.data.file;
    },
  },
  extraReducers: {
    [orderIndex.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [orderIndex.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload?.data?.order_details) {
        state.order_details = payload.data.order_details;
      }

      if (payload?.data?.contact) {
        state.contact = payload.data.contact;
      }

      if (payload?.data?.order) {
        state.order = payload.data.order;
      }

      if (payload?.data?.orders) {
        state.orders = payload.data.orders;
      }

      state.loading = false;
      state.isSuccess = true;
    },
    [orderIndex.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },
  },
});

export const {
  //
  updateSelectedOrderDetails,
  updateSelectedOrderDetail,
  updateOrderFileStore,
} = orderSlice.actions;

export default orderSlice;
