import api from "../../../support/support_api";

const adminUserIndex = async (params) => {
  const response = await api.axiosGet("/admin/users", params);

  return response.data;
};

const adminUserStore = async (params) => {
  const response = await api.axiosPost("/admin/users", params);

  return response.data;
};

const adminUserShow = async (params) => {
  const response = await api.axiosGet(`/admin/users/${params.user_id}`, params);

  return response.data;
};

const adminUserUpdate = async (params) => {
  const response = await api.axiosPatch(
    `/admin/users/${params.user_id}`,
    params
  );

  return response.data;
};

const userUpdate = async (params) => {
  const response = await api.axiosPatch(`/users/${params.user_id}`, params);

  return response.data;
};

const adminUserDelete = async (params) => {
  const response = await api.axiosDelete(
    `/admin/users/${params.user_id}`,
    params
  );

  return response.data;
};

const adminUserUpdatePassword = async (params) => {
  const response = await api.axiosPost(`/admin/users/update-password`, params);

  return response.data;
};

const userUpdatePassword = async (params) => {
  const response = await api.axiosPost(`/users/update-password`, params);

  return response.data;
};

const retrieveAuth = async (params) => {
  const response = await api.axiosPost(`/users/auth`, params);

  return response.data;
};

const showActions = async (params) => {
  const response = await api.axiosGet(
    `/users/${params.user_id}/actions`,
    params
  );

  return response.data;
};

const updateNotifications = async (params) => {
  const response = await api.axiosPatch(`/users/notification-settings`, params);

  return response.data;
};

const adminUserService = {
  adminUserIndex,
  adminUserStore,
  adminUserShow,
  adminUserUpdate,
  adminUserDelete,
  adminUserUpdatePassword,
  userUpdatePassword,
  userUpdate,
  retrieveAuth,
  showActions,
  updateNotifications,
};

export default adminUserService;
