import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import support_response from "../../support/support_response";
import machineService from "./assignmentService";

export const assignmentConfirmed = createAsyncThunk(
  "assignment/retrieve",
  async (args, thunkAPI) => {
    try {
      if (args.actions !== undefined) {
        await args.actions();
      }
      return await machineService.assignmentConfirmed(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);
export const assignmentSaveCustomerBooking = createAsyncThunk(
  "assignment/retrieve",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentSaveCustomerBooking(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentShow = createAsyncThunk(
  "assignment/retrieve",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentShow(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentRetrieve = createAsyncThunk(
  "assignment/retrieve",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentRetrieve(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentUpdate = createAsyncThunk(
  "assignment/update",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentStore = createAsyncThunk(
  "assignment/store",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentStore(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentAddOrUpdate = createAsyncThunk(
  "assignment/store",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentAddOrUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentAcceptOrDecline = createAsyncThunk(
  "re-assignment/request-accept-or-decline",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentAcceptOrDecline(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentToolsUpdateSelectAll = createAsyncThunk(
  "assignmentToolsUpdateSelectAll",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentToolsUpdateSelectAll(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentToolsAddOrUpdate = createAsyncThunk(
  "assignment/tools/add-or-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentToolsAddOrUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentActivitiesAddOrUpdate = createAsyncThunk(
  "assignment/tools/add-or-update",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentActivitiesAddOrUpdate(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentToolsRetrieve = createAsyncThunk(
  "assignment/tools/retrieve",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentToolsRetrieve(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentActivitiesRetrieve = createAsyncThunk(
  "assignment/activities/retrieve",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentActivitiesRetrieve(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

export const assignmentReAssignmentRequest = createAsyncThunk(
  "assignment/update",
  async (args, thunkAPI) => {
    try {
      return await machineService.assignmentReAssignmentRequest(args);
    } catch (error) {
      return thunkAPI.rejectWithValue(support_response.getErrorBag(error));
    }
  }
);

const initialState = {
  data: {},
  assignment: {},
  assignments: {
    data: [],
  },

  categories_tools: [],

  categories_activities: [],
  activity_select_all: false,
  errors: {},
  // updating: false,
  loading: false,
  isSuccess: false,
  updating: false,

  paramTools: [],
  paramActivities: [],

  params: {
    tools: [
      {
        id: 0,
        quantity: 0,
      },
    ],
    activities: [
      {
        id: 0,
        quantity: 0,
        reason: "",
      },
    ],
  },
};

export const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    assignmentSetErrors(state, action) {
      state.errors = action.payload.errors;
    },

    incrementDecrement(state, action) {
      const { categoryIndex, toolIndex, execute } = action.payload;
      let selectAll = [1, 1];
      if (execute === "increment") {
        state.categories_tools[categoryIndex].tools[toolIndex].quantity += 1;
        state.categories_tools[categoryIndex].tools[toolIndex].status = 1;
        state.categories_tools[categoryIndex].tools[toolIndex].dirty = true;
      } else {
        if (
          state.categories_tools[categoryIndex].tools[toolIndex].quantity > 1
        ) {
          state.categories_tools[categoryIndex].tools[toolIndex].status = 1;
          state.categories_tools[categoryIndex].tools[toolIndex].quantity -= 1;
        } else if ( state.categories_tools[categoryIndex].tools[toolIndex].quantity === 1 ) {
          state.categories_tools[categoryIndex].tools[toolIndex].status = 0;
          state.categories_tools[categoryIndex].tools[toolIndex].quantity -= 1;
        }

        state.categories_tools[categoryIndex].tools[toolIndex].dirty = true;
      }
      state.categories_tools.forEach((category, index) => {
        category.tools.forEach((tools) => {
          if (tools.status == "0" || tools.status == undefined) {
            selectAll[index] = 0;
          }
        });
      });
      state.assignment.tools_select_all = selectAll[0];
      state.assignment.tools_select_all_2 = selectAll[1];
      state.dirty = true;
    },

    updateAllActivityListStatus(state, action) {
      const { value } = action.payload;
      state.categories_activities.map((a) => {
        a.activities.map((activity) => {
          activity["status"] = value;
          activity.dirty = true;
        });
      });
      state.activity_select_all = value == 1 ? 1 : 0;

      state.dirty = true;
    },

    updateAllToolListStatus(state, action) {
      const { value, categoryName, index } = action.payload;
      state["categories_tools"].filter((tools) => {
        if (tools.name === categoryName) {
          tools.tools.map((tool) => {
            tool["quantity"] = tool["quantity"] === 0 ? tool["quantity"] + parseInt(value) :
                               tool["quantity"] === 1 && parseInt(value) === -1 ? tool["quantity"] + parseInt(value) :
                               tool["quantity"] === 1 && parseInt(value) === 1 ? tool["quantity"] :
                               tool["quantity"] > 1 && parseInt(value) === 1 ? tool["quantity"] :
                               tool["quantity"] > 1 && parseInt(value) === -1 ? tool["quantity"] + parseInt(value) : null;
            tool["status"] = value == 1 ? 1 : 0;
            tool.dirty = true;
          });
        }
      });
      if (index == 0) {
        state.assignment.tools_select_all = value == 1 ? 1 : 0;
      } else {
        state.assignment.tools_select_all_2 = value == 1 ? 1 : 0;
      }

      state.dirty = true;
    },

    updateStatus(state, action) {
      const { id, key, value } = action.payload;
      let selectAll = true;
      state.categories_activities.map((a) => {
        a.activities.map((activity) => {
          if (activity.id === id) {
            activity[key] = value;
            activity.dirty = true;
          }
        });
      });

      state.categories_activities.forEach((category) => {
        category.activities.forEach((activity) => {
          if (activity.status == 0) {
            selectAll = false;
          }
        });
      });
      state.activity_select_all = selectAll;
      state.dirty = true;
    },
  },
  extraReducers: {
    [assignmentRetrieve.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
      state.assignment = {};
    },
    [assignmentRetrieve.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;

      if (payload.data?.assignment) {
        state.assignment = payload.data.assignment;
      }

      if (payload.data?.assignments) {
        state.assignments = payload.data?.assignments;
      }

      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentRetrieve.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [assignmentToolsRetrieve.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [assignmentToolsRetrieve.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.assignment = payload.data.assignment;
      state.categories_tools = payload.data.categories_tools;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentToolsRetrieve.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [assignmentActivitiesRetrieve.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [assignmentActivitiesRetrieve.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.categories_activities = payload.data.categories_activities;
      let selectAll = true;
      payload.data.categories_activities.forEach((category) => {
        category.activities.forEach((activity) => {
          if (activity.status === 0) {
            selectAll = false;
          }
        });
      });

      state.activity_select_all = selectAll;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentActivitiesRetrieve.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [assignmentStore.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [assignmentStore.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.assignment = payload.data.assignment;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentStore.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [assignmentUpdate.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [assignmentUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.assignment = payload.data.assignment;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
    },

    [assignmentToolsAddOrUpdate.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [assignmentToolsAddOrUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.assignment = payload.data.assignment;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentToolsAddOrUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
      state.params.tools = [];
    },

    [assignmentActivitiesAddOrUpdate.pending]: (state, { payload }) => {
      state.data = {};
      state.updating = true;
      state.loading = true;
      state.isSuccess = false;
    },
    [assignmentActivitiesAddOrUpdate.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.assignment = payload.data.assignment;
      state.loading = false;
      state.isSuccess = true;
      state.dirty = false;
    },
    [assignmentActivitiesAddOrUpdate.rejected]: (state, { payload }) => {
      state.data = payload;
      state.updating = false;
      state.loading = false;
      state.isSuccess = true;
      state.params.activities = [];
    },
  },
});

export const {
  assignmentSetErrors,
  // setParamTools,
  // setParamActivities,
  incrementDecrement,
  updateStatus,
  updateAllActivityListStatus,
  updateAllToolListStatus,
} = assignmentSlice.actions;

export default assignmentSlice;
