import { mdiLock } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import "./NoAccess.scss";
import { useNavigate } from "react-router-dom";
const NoAccess = () => {
  const navigate = useNavigate();
  return (
    <div className="no-access-container">
      <div className="d-flex justify-content-center">
        <Icon path={mdiLock} size={10} color="#fd9801" />
      </div>
      <div className="d-flex justify-content-center">
        <h1> 403 | Forbidden </h1>
      </div>
      <div className="d-flex justify-content-center">
        <p> You don't have permission to access this resource</p>
      </div>
      <div className="d-flex justify-content-center mt-5">
        <button className="btn btn-primary" onClick={() => navigate("/home")}>
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default NoAccess;
