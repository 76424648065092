import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import support_response from "../../../support/support_response";
import planService from './planService';

export const adminPlanIndex = createAsyncThunk(
    "plans/index",
    async (args, thunkAPI) => {
        try {
            return await planService.adminPlanIndex(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminPlanStore = createAsyncThunk(
    "plans/index",
    async (args, thunkAPI) => {
        try {
            return await planService.adminPlanStore(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminPlanShow = createAsyncThunk(
    "plans/index",
    async (args, thunkAPI) => {
        try {
            return await planService.adminPlanShow(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminPlanUpdate = createAsyncThunk(
    "plans/index",
    async (args, thunkAPI) => {
        try {
            return await planService.adminPlanUpdate(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)

export const adminPlanDelete = createAsyncThunk(
    "plans/index",
    async (args, thunkAPI) => {
        try {
            return await planService.adminPlanDelete(args);
        } catch (error) {
            return thunkAPI.rejectWithValue(support_response.getErrorBag(error))
        }
    }
)


const initialState = {
    data: {},
    plans: {
        data: []
    },
    plan: {},

    errors: {},

    loading: false,
    isSuccess: false,
    updating: false,
}

export const adminPlanSlice = createSlice({
    name: 'adminPlan',
    initialState,
    reducers: {
        reduxSetErrors(state, action) {

        }
    },
    extraReducers: {
        [adminPlanIndex.pending]: (state, { payload }) => {
            state.data = {};
            state.updating = true;
            state.loading = true;
            state.isSuccess = false;
        },
        [adminPlanIndex.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.plans = payload.data.plans;
            state.loading = false;
            state.isSuccess = true;
        },
        [adminPlanIndex.rejected]: (state, { payload }) => {
            state.data = payload;
            state.updating = false;
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

export const {
    //
} = adminPlanSlice.actions

export default adminPlanSlice;