import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {axios} from  "axios";
// import { AxiosResponse } from "axios";
import { AxiosProvider, Request, Get, Delete, Head, Post, Put, Patch, withAxios } from 'react-axios'
// import {greet} from "support/api";
import  logoutService from "./logoutService";

export const logout = createAsyncThunk(
    "users/logout",
    async (args, thunkAPI) => {
        try {
            return await logoutService.logout(args);
        } catch (error) {
            const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

            return thunkAPI.rejectWithValue(message);
        }
    }
)

const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
}

export const logoutSlice = createSlice({
    name: 'logout',
    initialState,
    reducers: {
        //
    },
    extraReducers : {
        [logout.pending]:(state, {payload}) => {
            state.data = {};
            state.loading=true;
            state.isSuccess = false;
        },
        [logout.fulfilled]:(state, {payload}) => {
            state.data = {};
            state.loading=false;
            state.isSuccess = true;
        },
        [logout.rejected]:(state, {payload}) => {
            state.data = {};
            state.loading = false;
            state.isSuccess = true;
        },
    }
})

// Action creators are generated for each case reducer function
// export const { registerDetail  } = registerSlice.actions

export default logoutSlice;